
@import '~@/assets/scss/palette';
@import '~@/assets/scss/icons';
@import '~@/assets/scss/mixins';
@import '~@/assets/scss/layout';
@import '~@/assets/scss/_typography.scss';

$icon-path: '~@/assets/icons/';

.background-color {
    background-color: $dirty-white;
}

.section-layout {
    margin: 0 1.5rem 1.5rem 1.5rem;
}

.wallet {
    width: 17.5rem;
    max-width: 100%;
    margin-right: 1.5rem;
    background: $blue;

    @include tablet {
        margin-bottom: 1rem;
    }
}

.balances {
    width: 100%;

    @include tablet {
        flex-direction: column;
    }
}

.balance-card {
    width: calc(33.3% - 1rem);
    max-width: 17.5rem;
    min-height: 9.5rem;
    margin-right: $spacing-xl;

    &:last-child {
        margin-right: 0;
    }

    @include tablet {
        width: 100%;
        margin-bottom: 1rem;
    }
}

.section-balances {
    display: flex;
    justify-content: space-between;

    @include tablet {
        display: block;
    }
}

.balances-wrap {
    display: flex;

    @include tablet {
        display: block;
    }
}

.status {
    height: auto;
}

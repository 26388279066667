
@import '~@/assets/scss/_palette.scss';
@import '~@/assets/scss/_icons';

$icon-path: '~@/assets/icons/';

.card-container {
    margin-bottom: 0.625rem;
    padding: 0.625rem 1rem;
    border-radius: 0.5rem;
    background-color: rgba($color: $blue, $alpha: 0.15);

    .quiet-hours-card-title {
        color: $blue;
        font-size: 0.75rem;
        font-weight: 600;
    }
    .card-list {
        .card-content {
            display: flex;
            flex-direction: row;
            font-size: 0.875rem;
            color: $gray90;
        }

        li {
            display: flex;
        }

        ul {
            margin-bottom: 0;
        }
    }
}

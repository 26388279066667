
@import '~@/assets/scss/animations';
@import '~@/assets/scss/consistency-spacing';

.file-uploader.disabled {
    pointer-events: none;
}

::v-deep {
    .upload-img {
        align-self: center;
        width: 3.5rem;
        margin-right: $spacing-xxl;
    }
    .upload-link {
        cursor: pointer;
    }

    .file-name {
        line-height: 1.75;
    }
    .file-icon {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .icon-close,
    .icon-success {
        position: static;
        transform: none;
    }
    .icon-success {
        width: 1rem;
        height: 1rem;
    }

    .preview-image-wrap:hover .preview-image-big {
        display: none !important;
    }
}

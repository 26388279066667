
@import '~@/assets/scss/typographyV2';
@import '~@/assets/scss/palette';
@import '~@/assets/scss/layout';

.inline-text {
    font-size: 0.75rem;
    text-transform: uppercase;
    font-weight: bold;
    color: $gray-blue;
    margin-top: 1.9rem;
    margin-left: 1rem;
}

.rollover-label {
    text-transform: uppercase;
    font-size: 0.625rem;
    font-weight: bold;
    line-height: 2;
    color: $steel;
    margin-right: 0.75rem;
}

.rollover-wrapper {
    margin-top: 0.5rem;
    margin-bottom: 1.3rem;
    border-top: solid 0.1rem $blue15;
    border-bottom: solid 0.1rem $blue15;
}

.remove-rollover {
    font-size: 0.75rem;
    font-weight: 600;
    line-height: 1.86;
    color: $blue;
    cursor: pointer;
}

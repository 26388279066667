
@import '~@/assets/scss/base.scss';
@import '~@/assets/scss/_palette.scss';

.inline-text {
    font-size: 0.75rem;
    text-transform: uppercase;
    font-weight: bold;
    color: $gray-blue;
    margin-right: 0.75rem;
}

.monetary-amount {
    margin-right: 0.75rem;
}

.service-options {
    margin: 0.25rem 0.75rem 0 0;
    flex: 0 1 9rem;
    min-width: 118px;
}

.per-period-option {
    margin: 0.4375rem 0.75rem 0 0;
    flex: 1 0 11rem;
}

.group-checkbox {
    font-weight: normal;
}

.section-content {
    font-size: 0.75rem;
    font-weight: 600;
    margin-right: 1rem;
    color: $gray90;
}

.invalid-input {
    position: relative;
    top: 0.7rem;
    margin-bottom: 0.625rem;
}

.up-mt {
    margin-top: 0.6rem;
}


@import '~@/assets/scss/mixins';
@import '~@/assets/scss/palette';
@import '~@/assets/scss/layout';
@import '~@/assets/scss/sidebar';
@import '~@/assets/scss/z-indexes';

.page-container {
    display: grid;
    grid-template-rows: $header-height; // equal to AppHeader height
    grid-template-columns: 15rem 1fr;
    height: 100vh;
    padding: 0;

    .header {
        grid-row: 1;
        grid-column: 1 / 3;
    }

    .sidebar {
        grid-column: 1 / 2;
    }

    .content {
        grid-row: 2 / 3;
        grid-column: 2 / 3;
        overflow: scroll;
    }
}


@import '~@/assets/scss/_consistency.scss';
@import '~@/assets/scss/_layout.scss';

.small-input {
    max-width: 5rem;
}

.editor {
    background-color: $white;
    display: flex;
    flex-flow: column;
    height: 100%;
}

.editor-container {
    padding: $spacing-l;
}

.editor-controls {
    display: flex;
    justify-content: flex-end;
}

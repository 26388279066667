
@import '~@/assets/scss/mixins';
@import '~@/assets/scss/filter-table';
@import '~@/assets/scss/palette';
@import '~@/assets/scss/z-indexes';

.input-file-dragdrop {
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: $hide-smth-z-index;
}

.upload-container {
    border-radius: 0.5rem;
    border: dashed 0.0625rem rgba($gray5, 0.5);
    padding: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 11rem;

    &.drag-on {
        background: $blue5;
        opacity: unset;
        border-color: $blue;
    }
}

.upload-img {
    align-self: center;
    width: 3.5rem;
    margin-left: 2rem;
}

.upload-text-wrapper {
    width: calc(100% - 9.5rem);
}

.upload-text {
    font-size: 0.875rem;
    font-weight: 600;
    color: rgba($gray90, 0.5);
    margin-bottom: 0.25rem;

    &.drag-on {
        color: $gray90;
    }
}

.upload-link {
    color: rgba($blue, 0.5);
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }

    &.drag-on {
        color: $blue;
    }
}

.upload-subtext {
    color: rgba($gray60, 0.5);
    font-size: 0.75rem;

    &.drag-on {
        color: $gray60;
    }
}

.files-wrap {
    display: flex;
    flex-wrap: wrap;
}

.file-wrapper {
    width: 100%;
}

.file-wrapper:last-child {
    margin-bottom: 0;
}


@import '~@/assets/scss/consistency';

.state-history-content {
    border: 1px solid $gray-200;
    padding: $spacing-m !important;
}

.party-role-multiselect {
    width: 50% !important;
}

.version-item {
    font-size: 0.875rem;
    padding: 1rem 1.5rem;
    cursor: pointer;

    &:hover {
        background-color: $blue-200;
    }

    &.active {
        color: $blue;
        background-color: $blue-300;
    }

    .icon {
        position: relative;
        top: 3px;
    }
}

.state-label {
    height: 1.5rem;
}

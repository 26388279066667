
@import '~@/assets/scss/mixins';
@import '~@/assets/scss/palette.scss';
@import '~@/assets/scss/sidebar';
@import '~@/assets/scss/layout';
@import '~@/assets/scss/typography';

.sub-sidebar-container {
    height: 100%;
    width: 100%;
    background: $dirty-white;
    padding-top: $spacing-xl;

    .section {
        display: flex;
        width: calc(15rem - #{$spacing-l});
        color: $gray90;
        padding: $spacing-xs $spacing-l;
        cursor: pointer;

        &.active {
            border-radius: 0 3.125rem 3.125rem 0;
            background-color: $blue10;
            color: $blue;
            font-weight: 600;
        }
    }
}

.options {
    position: fixed;
}

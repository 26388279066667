
@import '~@/assets/scss/layout';
@import '~@/assets/scss/palette';
@import '~@/assets/scss/typographyV2';

.lf-table-title {
    color: $blue50;
}

.sidebar-data {
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: baseline;
    gap: 0.875rem $spacing-m;
}

dt {
    font-weight: 600;
    white-space: nowrap;
}

dd {
    margin-bottom: 0;
    font-size: $text-sm;
}

pre {
    margin-bottom: 0;
    padding: $spacing-xxs $spacing-xs;
    background-color: $dirty-white;
}


@import '~@/assets/scss/palette';

.user-editor {
    background-color: $white;
}

.heading {
    margin: 0;
    padding-top: 2.25rem;
    padding-bottom: 1.25rem;
}

.password-requirement {
    width: 200px;
}

.password-tooltip {
    padding-left: 6px;
    padding-bottom: 4px;
}


@import '~@/assets/scss/_palette';

textarea {
    padding: 0 12px;
    line-height: 32px;

    border: 1px solid $gray5;
    color: $navy;

    resize: none;

    &.invalid {
        border-color: $red;
    }

    &:focus {
        border-color: $blue;
    }

    &.resizable {
        resize: vertical;
    }

    &:disabled {
        color: $gray30;
        background-color: transparent;
    }

    &::placeholder {
        color: $gray30;
    }
}

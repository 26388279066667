
@import '~@/assets/scss/consistency-colors';

.link:hover {
    color: $blue;
    text-decoration: underline;
}
.hot-bill {
    margin-right: auto;
}

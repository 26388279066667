
@import '~@/assets/scss/palette';
@import '~@/assets/scss/icons';

$icon-path: '~@/assets/icons/';

.search-box-wrapper {
    position: relative;
    width: 15rem;
    height: 2.5rem;
}

.table-search-box {
    width: 15rem;
}

.all-caps-text {
    margin-bottom: 1.125rem;
}

.modal-title {
    font-size: 1.375rem;
    font-weight: 600;
    color: $gray90;
    margin-bottom: 2.1875rem;
}

.modal-row {
    margin-bottom: 0.875rem;
}

.row-title {
    font-size: 0.75rem;
    font-weight: 600;
    width: 14rem;
}

.row-value {
    word-break: break-all;
    font-size: 0.875rem;
    width: 100%;
}

.download-picker-content {
    width: 11.25rem;
    margin: -0.25rem -1rem;

    .picker-option {
        font-size: 0.875rem;
        padding: 0.25rem 0.5rem;
        cursor: pointer;

        &:hover {
            background-color: $blue15;
        }
    }
}

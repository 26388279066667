@import 'palette';
@import 'layout';
@import 'palette';
@import 'typographyV2';

* {
    margin: 0;
    padding: 0;
}

ul {
    list-style-type: none;
}

img {
    user-select: none;
}

html {
    overflow-y: scroll;
}

html, body {
    height: 100%;
}

label {
    margin-bottom: 0 !important; // override bootstrap
}

// custom styles

input:focus, textarea:focus {
    outline: none;
}

// custom classes

.panel {
    margin-top: 4px;

    background-color: $white;

    box-shadow: 0 4px 8px 0 rgba(51, 81, 149, 0.3);

    &:first-child {
        margin-top: 0;
    }

    h4 {
        font-size: 23px;
        padding-top: 2px;
        padding-bottom: 2px;
    }

    .panel-header {
        font-weight: 600;
    }
}

.hint-wrapper {
    display: flex;
    height: auto;
    overflow: hidden;
    padding: 16px 24px;
    background-color: $white;
    border-radius: 4px;
    box-shadow: 0 1px 2px 0 $gray30;
}

.flex-grow-1 {
    flex-grow: 1;
}

.flex-basis-50 {
    flex-basis: 50%;
}

.flex-basis-100 {
    flex-basis: 100%;
}

.inner-content-spacing {
    padding-left: 24px;
}

.table {
    &-header {
        font-size: 14px;
        border-bottom: solid 1px #f1f1f1;
        border-top: solid 1px #f1f1f1;
        background-color: transparent;

        & > * {
            line-height: 40px;
        }
    }

    .highlighted {
        color: inherit !important;
        background: $orange;
    }
}

.entity-row-header-fullwidth-hover:hover {
    background-color: $dirty-white;
}

.dropshadow {
    box-shadow: 0 1px 2px 0 $gray30;
}

.disabled-area {
    opacity: 50%;
    pointer-events: none;
    cursor: not-allowed;
}

.white-card-bg {
    @extend .dropshadow;
    background-color: $white;
    height: 100%;
}

.card-content-spacing {
    padding: 15px;
}

.card-title {
    font-size: 16px;
    font-weight: bold;
}

.blue-caps-label {
    font-size: 0.75rem;
    text-transform: uppercase;
    font-weight: bold;
    color: $gray-blue;
    margin-right: 0.75rem;
}

.editor-section-title {
    font-size: 1.125rem;
    font-weight: 600;
    margin: 2.125rem 0 1.25rem;
    color: $gray90;
}

.editor-section-message {
    font-size: 0.75rem;
    margin-bottom: 0.75rem;
    color: $gray60;
}

.alert-message {
    border-radius: 0.25rem;
    background-color: $blue;
    width: fit-content;
    padding: 1rem 1.5rem;
    font-size: 0.875rem;
    font-weight: 600;
    color: $white;
}

.white-bg {
    background-color: $white !important;
}

.overflow-hidden {
    overflow: hidden;
}

.height-auto {
    height: auto !important;
}

.line {
    width: calc(100% - 7px);
    height: 2px;
    border: solid 1px $dirty-white;
    margin-right: 7px;
    margin-bottom: 1rem;

    &.error {
        border-color: $red;
    }
}

.no-outline {
    outline: none;

    &:focus {
        outline: none;
    }

    .btn.focus, .btn:active.focus, .btn.active.focus {
        outline: none;
    }
}

.truncate-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.truncate-child-text {
    div,
    span {
        display: block;
        @extend .truncate-text;
    }
}

.truncate-text-col {
    div[class*='col'] {
        @extend .truncate-text;
    }
}

.overflow-visible-all {
    div,
    span {
        overflow: visible !important;
    }
}

.tag {
    padding: 4px 12px;
    color: $navy;
    font-size: 12px;
    font-weight: normal;
    line-height: initial;

    border-radius: 16px;
    background-color: $gray5;
    border: solid 1px $dirty-white;
}

.display-flex {
    display: flex; // does not have !important as bootstrap does
}

.overflow-y-auto {
    overflow-y: auto;

    // styles for the scroll bar
    &::-webkit-scrollbar {
        width: 10px;
        background-color: $dirty-white;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 5px;
        background-color: $gray30;
    }
}

hr {
    &.small-margin {
        margin-top: 12px;
        margin-bottom: 12px;
    }
}

.h-cursor-pointer {
    &:hover {
        cursor: pointer;
    }
}

a.link {
    color: $orange;
}

// Adding these classes from Bootstrap as they get overridden by first rule in this file.
//* {
//    margin: 0;
//    padding: 0;
//}
.offset-1 {
    margin-left: 8.333333%;
}

.offset-2 {
    margin-left: 16.666667%;
}

.offset-3 {
    margin-left: 25%;
}

.offset-4 {
    margin-left: 33.333333%;
}

.offset-5 {
    margin-left: 41.666667%;
}

.offset-6 {
    margin-left: 50%;
}

.offset-7 {
    margin-left: 58.333333%;
}

.offset-8 {
    margin-left: 66.666667%;
}

.offset-9 {
    margin-left: 75%;
}

.offset-10 {
    margin-left: 83.333333%;
}

.offset-11 {
    margin-left: 91.666667%;

}

.card-component-white {
    margin: 1rem;
    padding: 1rem;
    border-radius: 0.5rem;
    background-color: $white;
}

.card-component-blue {
    margin: 1rem;
    padding: 1rem;
    border-radius: 0.5rem;
    background-color: rgba($color: $blue, $alpha: .15);
}


// Strapi content specific
blockquote {
    border-left: 5px solid $gray10;
    font-style: italic;
    margin-left: 0;
    margin-right: 0;
    overflow: hidden;
    padding-left: 1.5rem;
    padding-right: 1.5rem;

    p {
        font-size: 0.875rem;
        line-height: 1.6rem;
        margin: 1rem 0;
    }
}

figure {
    margin: 0 auto;

    &.image-style-side {
        float: right;
        padding-left: 1rem;
    }

    img {
        width: 100%;
        height: auto;
    }
}

// in order to rewrite label margin:0 in this file since mb-3 is defined in the bootstrap scss file
// mostly fixes mb-3 applied to the AppInputV3 since its highest lvl tag is label
.mb-3 {
    margin-bottom: 1rem !important;
}

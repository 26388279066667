
@import '~@/assets/scss/base';
@import '~@/assets/scss/forms';
@import '~@/assets/scss/palette';
@import '~@/assets/scss/inputs';
@import '~@/assets/scss/animations';
@import '~@/assets/scss/editor-layout-v2';
@import '~@/assets/scss/typographyV2';

.summary-table {
    height: 40%;
}


@import '~@/assets/scss/palette';
@import '~@/assets/scss/typographyV2';

.json-wrap {
    border: 0.063rem solid $gray5;
    border-radius: 0.5rem;
    overflow: hidden;
}

.invalid {
    border-color: $red;
}

.label {
    font-size: $text-xs;
    color: $gray90;
    line-height: $label-line-height;
    font-weight: $medium-font-weight;
    margin-bottom: 0.25rem;
}

.error-message {
    margin-top: $spacing-xxs;
    font-size: $text-xs;
    line-height: $heading-line-height;
    height: 1.25rem;
    color: $red;
}

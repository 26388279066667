
@import '~@/assets/scss/consistency-colors';
@import '~@/assets/scss/consistency-typography';
@import '~@/assets/scss/consistency-spacing';
@import '~@/assets/scss/consistency-colors';

.tile-abstract-wrapper {
    height: 100%;
    width: 100%;
    background: white;

    .layout-tile {
        padding: $spacing-s;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        border-radius: 0.25rem;
        background-color: white;
        padding: $spacing-m;
        height: 100%;

        .layout-tile-title-wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 2rem;
        }

        .layout-tile-content {
            width: 100%;
            height: 100%;

            &.overflow {
                padding-right: 0.5rem;
                overflow-y: auto;
            }
        }
    }

    .tile-unavailable-stub {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        background-color: rgba($gray-300, 0.3);
        text-align: center;
    }
}


@import '~@/assets/scss/palette';
@import '~@/assets/scss/_typographyV2.scss';
@import '~@/assets/scss/_layout';

h1 {
    margin: 0;
}

.lf-welcome {
    font-family: $font-primary;
    font-size: $text-xxl;
    font-weight: $bold-font-weight;
    line-height: $normal-line-height;
    letter-spacing: normal;
    color: $blue;
    display: flex;

    @media screen and (prefers-color-scheme: dark) {
        color: #fff;
    }

    @media screen and (max-width: 1024px) {
        font-size: 2rem;
    }
}

.lf-subtitle-blue {
    display: flex;
    margin-left: 0.5rem;

    @media screen and (prefers-color-scheme: dark) {
        color: #fff;
    }
}

.form-inputs {
    padding-bottom: $spacing-m;
    max-width: 400px;
    width: 100%;

    @media screen and (max-width: 1024px) {
        max-width: 100%;
    }
}

.heading {
    margin-bottom: 5rem;
    @media screen and (max-width: 680px) {
        text-align: center;
    }
}

.login-form {
    display: flex;
    flex-direction: column;
    width: 100%;

    @media screen and (max-width: 680px) {
        justify-content: center;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

.login-button {
    margin-top: 10px;
    width: 100%;
    margin-bottom: 57px;
}

.button-wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 400px;

    @media screen and (max-width: 1024px) {
        width: 100%;
        max-width: 100%;
    }
}

.text-button {
    display: flex;
    color: $white;
    text-decoration: underline;
    cursor: pointer;
}

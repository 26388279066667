
@import '~@/assets/scss/_palette.scss';
@import '~@/assets/scss/_layout.scss';
@import '~@/assets/scss/_typographyV2';

.inline-button {
    position: absolute;
    right: 1rem;
    border: none;
    background: transparent;
    color: $blue;
}

.input-wrapper {
    display: block;
    align-items: center;
    color: $gray90;

    .label-wrapper {
        display: flex;
        justify-content: space-between;
        padding-bottom: $spacing-xxs;
    }

    .label {
        font-size: $text-xs;
        color: $gray90;
        font-weight: 600;
        line-height: 1.625rem;
    }

    .label-text {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .content {
        position: relative;
        display: flex;
        align-items: center;
        flex: 0 1 80%;

        input {
            position: relative;
            width: 100%;
            height: 2rem;
            border-radius: 0.5rem;
            padding: 0.25rem 0.5rem 0.375rem;
            outline: none;
            border: 1px solid $gray5;
            line-height: 20px;
            font-size: 0.875rem;
            color: $gray90;

            &:focus {
                border-color: $blue;
            }

            &:disabled {
                background-color: $input-background-disabled;
                border: 1px solid $gray5;
                color: $gray30;
                cursor: not-allowed;
            }

            &.error {
                border-color: $red;
            }
        }

        &.verified {
            &:after {
                content: '';
                position: absolute;
                top: $spacing-xs;
                right: $spacing-xs;
                width: 1rem;
                height: 1rem;
                background: url('../../../assets/icons/input-verified.svg');
            }

            input {
                color: $gray90;
                padding-right: $spacing-xl;
                background-color: $green10;
                border-color: $green15;
            }
        }
    }

    .optional {
        font-weight: 600;
        font-size: 0.75rem;
        line-height: 1.625rem;
        font-style: italic;
        color: $gray60;
    }

    .optional-no-label {
        justify-content: flex-end;
        align-self: flex-end;
    }

    .error-message {
        padding-top: $spacing-xxs;
        font-size: 0.75rem;
        line-height: 1.25rem;
        color: $red;
    }
}

.optional-no-label-wrapper {
    display: flex;
    flex-direction: column;
    top: -0.75rem;
}

.optional-no-label-content {
    align-self: flex-end;
    width: inherit;
}

::placeholder {
    font-style: italic;
    color: $gray30;
}

::v-deep .multiselect__placeholder {
    margin-bottom: 0;
}

.tooltip-info {
    white-space: pre;
    font-size: 0.75rem;
    font-weight: normal;
    text-transform: none;
    line-height: normal;
    color: $gray90;
    min-width: 10rem;
}

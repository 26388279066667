
@import '~@/assets/scss/consistency';
@import '~@/assets/scss/layout';

.section-tab {
    background-color: $white;
    border: 0.063rem solid $gray-25;
    border-right: none;
    /* Adding transparent border to make both columns equal height */
    border-top: 3px solid transparent;

    height: $spacing-xxxl;

    font-size: 0.8125rem;
    font-weight: 600;
    text-transform: uppercase;
    color: $gray-300;
    padding-left: $spacing-m;

    &.active {
        background-color: $white;
        border-top: 0.188rem solid $blue-400;
        border-bottom: 0.063rem solid transparent;
        color: $blue-400;
    }

    &:first-child {
        border-left: none;
    }

    &:last-child {
        border-right: none;
    }

    &:hover:not(.active) {
        background-color: $gray-25;
        color: $blue-400;
        cursor: pointer;
    }
}

.state-history-content {
    border: 1px solid $gray-200;
    padding: $spacing-m !important;
}

.w-15 {
    width: 15%;
}


@import '~@/assets/scss/consistency-spacing';

.gap {
    gap: $spacing-xs;
}
.title {
    flex: 3;
}
.description {
    flex: 4;
}
.details {
    flex: 2;
}

.description-content {
    margin-bottom: $spacing-xxs;
    word-break: break-all;
}

.description-elipsis {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    line-clamp: 3;
}

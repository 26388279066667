
@import '~@/assets/scss/palette';

.no-charging-spec {
    display: flex;
    color: $gray30;
    flex-direction: column;
    text-align: center;

    .tag-line {
        font-size: 1.4em;
        font-weight: 600;
        .entity-name {
            text-transform: capitalize;
        }
    }

    .message {
        font-size: 1em;
    }
}

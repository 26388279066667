
@import '~@/assets/scss/palette';
@import '~@/assets/scss/animations';
@import '~@/assets/scss/typography';
@import '~@/assets/scss/z-indexes';

.underline {
    border-bottom: solid 1px rgba(51, 81, 149, 0.16);
    position: relative;
    top: -2.8rem;

    &.fixed {
        top: 0;
    }
}

.left-margin-date-box {
    margin-left: 5rem;
}

.width-fit-content {
    min-width: fit-content;
}

.draggable {
    position: relative;
    cursor: -webkit-grab;
    cursor: -moz-grab;
    cursor: grab;

    scroll-behavior: smooth;
    &:active {
        cursor: -webkit-grabbing;
        cursor: -moz-grabbing;
        cursor: grabbing;

        scroll-behavior: auto;
    }
}

#fade {
    position: absolute;
    z-index: $overlap-smth-z-index;
    height: 100%;
    width: 100%;
    /* so the text is still selectable */
    pointer-events: none;
}

.pointer {
    background: $dirty-white;
    position: absolute;
    border-top: solid 1px rgba(51, 81, 149, 0.15);
    border-left: solid 1px rgba(51, 81, 149, 0.15);
    height: 10px;
    width: 10px;
    transform: translate(-50%, -50%) rotate(45deg);
}

.arrow-wrapper {
    height: 2rem;
    width: 2rem;
    display: flex;
    align-items: center;

    img {
        height: 1rem;
    }

    &.left-arrow-enabled:hover {
        transition: 0.25s ease-in-out;
        cursor: pointer;
        border-radius: 1rem;
        padding: 1rem;
        background-color: $blue15;
    }
    &.right-arrow-enabled:hover {
        transition: 0.25s ease-in-out;
        cursor: pointer;
        border-radius: 1rem;
        background-color: $blue15;
    }

    &.right-arrow {
        position: absolute;
        top: 30%;
        right: 0;
        z-index: $two-z-index;
    }

    &.left-arrow {
        position: absolute;
        top: 30%;
        left: 0;
        z-index: $two-z-index;
    }
}

.fit-content {
    width: fit-content;
}
.leftArrowEnabledGradient {
    background: linear-gradient(to right, rgba(246, 246, 246) 0.5%, rgba(246, 246, 246, 0) 5%);
}

.rightArrowEnabledGradient {
    background: linear-gradient(to left, rgba(246, 246, 246) 0.5%, rgba(246, 246, 246, 0) 5%);
}
.bothArrowsEnabledGradient {
    background: linear-gradient(to right, rgba(246, 246, 246) 0.5%, rgba(246, 246, 246, 0) 5%),
        linear-gradient(to left, rgba(246, 246, 246) 0.5%, rgba(246, 246, 246, 0) 5%);
}
.content-item:last-of-type {
    padding-right: 1rem;
}

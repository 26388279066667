
@import '~@/assets/scss/mixins';
@import '~@/assets/scss/animations';
@import '~@/assets/scss/consistency';

.tabs {
    width: 100%;
    border-bottom: 1px solid $gray-150;
}

.tab-btn {
    position: relative;
    border: 0;
    background: none;
    padding: $spacing-xs $spacing-m;

    &::after {
        content: '';
        position: absolute;
        inset: auto 0 -1px;
        opacity: 0;
        border: 1px solid $blue-400;
        transition: opacity $fast-animation-time ease-in-out;
    }

    &.is-active::after {
        opacity: 1;
    }

    &:focus-visible {
        outline: none;
    }
}

.tab-btn-wrap {
    display: block;
    padding: $spacing-xs $spacing-m;
    border-radius: $spacing-xs;
    background-color: $white;
    transition: background-color $fast-animation-time ease-in-out;

    .tab-btn:not(.is-active):hover & {
        background-color: $gray-50;
    }

    .tab-btn:focus-visible & {
        outline: 1px auto;
    }
}

.tab-btn-text {
    position: relative;
    text-align: left;
    color: transparent; // fontWeight transition hack
    font-weight: 600; // fontWeight transition hack

    &,
    &::before {
        @include lineClamp();
    }

    &::before {
        content: attr(title); // fontWeight transition hack
        position: absolute;
        inset: 0;
        font-weight: 400;
        color: $gray-600;
        transition: color $slow-animation-time ease-in-out;

        .tab-btn.is-active & {
            color: $gray-800;
            font-weight: 600;
        }
    }
}

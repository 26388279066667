
@import '~@/assets/scss/palette';
@import '~@/assets/scss/consistency-colors';
@import '~@/assets/scss/consistency-spacing';
@import '~@/assets/scss/consistency-typography';

.description-highlight {
    padding: $spacing-xl 0;
    margin: 0 auto;

    .description-info {
        padding: $spacing-s $spacing-l;
        background-color: $dirty-white;
    }
}


@import '~@/assets/scss/layout';

.porting-table.overflow-visible::v-deep :is(.table-scroll-wrapper, tbody, td) {
    overflow: visible !important;
}

.porting-select-wrap {
    line-height: 1;
}

.portin-datepicker::v-deep .mx-datepicker {
    width: 100%;
}

.portout-contol-bar {
    right: 0;
    transform: translateY(calc(-100% - #{$spacing-xxs}));
}


@import '~@/assets/scss/palette';

.card-container {
    margin: 24px;
    padding: 10px 16px;
    border-radius: 8px;
    background-color: rgba($color: $blue, $alpha: 0.15);

    .card-title {
        color: $blue;
        font-size: 0.75rem;
        font-weight: 600;
    }

    .card-list {
        .card-content {
            font-size: 0.875rem;
            color: $gray90;
        }

        li::before {
            content: '•';
            width: 4px;
            height: 4px;
            margin-right: 6px;
            color: rgba($color: $blue, $alpha: 0.5);
        }
    }
}


@import '~@/assets/scss/editor-layout-v2';
@import '~@/assets/scss/typographyV2';
@import '~@/assets/scss/palette';
@import '~@/assets/scss/icons';
@import '~@/assets/scss/z-indexes';
@import '~@/assets/scss/animations';

$icon-path: '~@/assets/icons/';

.section-message {
    font-size: 0.75rem;
    margin-bottom: 0.75rem;
    color: $gray60;
}

.form-content {
    width: 85%;
    height: 100%;
}

.flex-container {
    display: flex;
    flex-direction: row;

    &:hover {
        .delete-btn {
            opacity: 1;
        }
    }
}

.input-block {
    position: relative;
    margin-top: 0.313rem;
    margin-right: 1rem;

    .input-title {
        width: 12.5rem;
        font-weight: bold;
        margin-bottom: 0.625rem;
    }
    .input-content {
        width: 12.5rem;
    }
}

.resetPeriodDurationLabel {
    color: $gray-blue;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 0.8rem;
    margin: 1rem;
    padding-top: 1rem;
}

.cs-form-wrapper {
    width: 80%;
}

.version-item {
    font-size: 0.875rem;
    padding: 1rem 1.5rem;
    cursor: pointer;
    position: relative;
    &:hover {
        background-color: $blue5;
        .version-details {
            display: block;
        }
    }
    &.active {
        color: $blue;
        background-color: $blue15;
    }
    .icon {
        position: relative;
        top: 3px;
    }
}
.version-details {
    position: absolute;
    top: 2rem;
    transform: translateY(-10%);
    display: none;
    width: 21.25rem;
    height: auto;
    color: $gray60;
    transition: $fast-animation-time;
    z-index: $overlap-smth-z-index;
    .info {
        background-color: $gray5;
        padding: 0.75rem 1.25rem;
        font-size: $text-sm;
        .property-name {
            font-weight: $medium-font-weight;
        }
    }
}

.tags-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}


@import '~@/assets/scss/_palette';
@import '~@/assets/scss/_animations';

.clickable {
    cursor: pointer;
}

.tabs-style {
    min-width: 100%;
    width: max-content;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 0.125rem;
    border-bottom: solid 1px rgba(51, 81, 149, 0.15);

    .tab-style {
        flex-shrink: 0;
        color: $blue;
        line-height: 26px;
        font-size: 0.875rem;
        font-weight: 600;
        margin-right: 1.5rem;
        position: relative;
        cursor: pointer;

        &::after {
            content: '';
            width: 0;
            height: 0.125rem;
            border-radius: 0.188rem;
            background: $blue;
            position: absolute;
            top: calc(100% + 0.063rem);
            left: 50%;
            transform: translate(-50%, -100%);
            transition: $fast-animation-time;
        }

        &.clicked::after {
            width: 100%;
        }
    }

    &.validation {
        border-bottom: none;

        .tab-style {
            &::after {
                transition: 0s;
            }

            &.clicked::after {
                width: 65%;
            }

            &.success {
                color: $green;
            }

            &.success:not(.clicked)::after {
                content: url('~@/assets/icons/check-mark.svg');
                top: calc(100% - 0.125rem);
                width: 0.625rem;
                height: 0.625rem;
                background: none;
            }

            &.fail {
                color: $red;
            }

            &.fail:not(.clicked)::after {
                content: url('~@/assets/icons/error-mark.svg');
                top: calc(100% - 0.125rem);
                width: 0.625rem;
                height: 0.625rem;
                background: none;
            }
        }
    }
}

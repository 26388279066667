
@import '~@/assets/scss/consistency-spacing';
@import '~@/assets/scss/consistency-typography';

.status-checker {
    gap: $spacing-xs;
}

button {
    background: none;
    border: 0;
    text-decoration: none !important;

    &[disabled] {
        pointer-events: none;
        color: $gray-300;
    }
}

::v-deep .loading-wrapper {
    & {
        font-size: 160%;
    }

    &,
    .loading {
        position: static;
        width: 1em;
        height: 1em;
    }

    .loading {
        display: flex;

        &::before {
            content: none;
        }

        &::after {
            position: static;
            width: 100%;
            height: 100%;
            max-width: 2.5rem;
            margin-top: 0;
        }
    }
}

// Color palette according to styleguide:
// https://www.figma.com/file/guLrapQ5tJniFiI55RlRAs/Portal?node-id=305%3A2514

// White

$white: #ffffff;
$white1: #f1f1f1;
$dirty-white: #f6f6f6;

// Black

$black: #000000;

// Gray

$gray4: #E4E4E4;
$gray5: #DDDDDD;
$gray10: #bbbbbb;
$gray30: #999999;
$gray60: #666666;
$gray90: #333333;
$gray90-5: rgba($gray90, 0.05);

$gray-blue: #8597bf;
$steel: #707d9a;

$input-background-disabled: rgba($gray5, 0.4);

// Blue

$blue: #335195;
$blue5: rgba($blue, 0.05);
$blue10: rgba($blue, 0.1);
$blue15: rgba($blue, 0.15);
$blue30: rgba($blue, 0.3);
$blue40: #2a509a;
$blue50: rgba($blue, 0.5);
$blueDark20: #284177;
$blueDark40: #1e3059;
$blue60: rgba($blue, 0.6);
$navy: #14203B;

// Secondary colors

$yellow: #FFAD20;
$yellow5: rgba($yellow, 0.05);
$yellow15: rgba($yellow, 0.15);
$yellow30: rgba($yellow, 0.15);

$violet: #7044D9;
$violet5: rgba($violet, 0.05);
$violet15: rgba($violet, 0.15);

$green: #37c9aa;
$green5: rgba($green, 0.05);
$green10: rgba($green, 0.1);
$green15: rgba($green, 0.15);
$green30: rgba($green, 0.3);
$green40: #00d1a7;

$red: #e8504c;
$red5: rgba($red, 0.05);
$red10: rgba($red, 0.1);
$red15: rgba($red, 0.15);
$red30: #fb3f44;

$cyan: #00cec9;
$cyan5: rgba($cyan, 0.05);

$orange: #ffa600;

// box-shadow

$box-shadow-color: rgba($blue, 0.3);
$box-shadow-default: 0 4px 8px 0 $box-shadow-color;

// gradient

$sidebar-gradient: linear-gradient(186deg, #f08642, #e44b3f 51%, #e24440);

// alert buttons

$yellow-hover-background: #fffbf4;
$green-hover-background: #f5fdfb;
$blue-hover-background: #f5f7fa;
$red-hover-background: #fef7f7;


@import 'src/assets/scss/consistency-colors';

.table-link {
    color: $text-color;

    &:hover {
        color: $blue-400;
    }
}

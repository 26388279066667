
@import '~@/assets/scss/_consistency.scss';
@import '~@/assets/scss/layout';

.toggle-wrapper {
    display: flex;
    align-items: center;
    font-size: 1rem;
    line-height: 1.5rem;
}

.toggle-wrapper .toggle-label.with-margin {
    margin-right: $spacing-l;
}

.toggle-wrapper.fit-content {
    width: fit-content;
}

.toggle-wrapper.small .toggle-label {
    cursor: pointer;
    color: $gray-800;
    font-size: 12px;
    font-weight: 600;
}

.toggle-wrapper.small .toggle-label.with-margin {
    margin-right: $spacing-m;
}

.toggle-wrapper .tooltip-info {
    white-space: break-spaces;
    font-size: 0.75rem;
    font-weight: normal;
    text-transform: none;
    line-height: normal;
    color: $gray-800;
    min-width: 10rem;
}

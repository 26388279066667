
@import '~@/assets/scss/consistency-colors';
@import '~@/assets/scss/consistency-spacing';

.reshipment-page {
    max-width: 50rem;
    margin: 0 auto;

    .delivery-info {
        width: 100%;
        border: 1px solid $gray-100;
        border-radius: $spacing-xs;
        .tablerow {
            border-bottom: 1px solid $gray-100;
            display: flex;
            min-height: $spacing-xxxl;
            align-items: center;
            padding: $spacing-xs $spacing-xxl;
            gap: $spacing-xxl;

            &:last-child {
                border-bottom: none;
            }

            .value {
                width: 50%;
                word-break: break-all;
                line-height: 1.2;
            }
        }
    }
}

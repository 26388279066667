
@import '~@/assets/scss/typographyV2';
@import '~@/assets/scss/consistency';
@import '~@/assets/scss/app-table-new-design';

.app-table ::v-deep {
    @include appTableNewDesign;

    .table-content {
        overflow: auto;
    }
}

.msisdn-search-index {
    margin-top: 2px;
}
/**
    Explictly specify display for tr/td elements since otherwise they inherit CSS
    definitions from AppRow.
 */
.entity-details tr {
    display: table-row;
}
.entity-details td {
    display: table-cell;
    padding-right: 1rem;
}
.search-box {
    width: 15rem;
}

.search-bar {
    padding-block: $spacing-l;
}

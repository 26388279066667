
@import '~@/assets/scss/_palette.scss';
@import '~@/assets/scss/_typographyV2';

.tenant-section {
    padding: 2rem 4rem;
    height: 100%;
    position: relative;
    overflow: scroll;
}
.use-tenant-name {
    padding: 0;
}


@import '../~@/assets/scss/palette';

.zone {
    border-top: 1px solid $dirty-white;
    padding: 4px 0;

    &:first-child {
        border: none;
    }
}


@import '~@/assets/scss/consistency-colors';
@import '~@/assets/scss/consistency-typography';
@import '~@/assets/scss/z-indexes';
@import '~@/assets/scss/sidebar';

.alert-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    position: fixed;
    left: 0;
    top: 0;
    padding: 0.5rem;
    width: 100%;
    height: 100vh;
    pointer-events: none;
    z-index: $alert-z-index;

    &.bottom-center {
        align-items: center;
        justify-content: flex-end;
    }

    &.right-bottom-corner {
        align-items: flex-end;
        justify-content: flex-end;
    }

    .alert-toast {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 1rem;
        padding: 1rem 1.5rem;
        margin-left: $sidebarFullWidth;
        width: calc(70% - #{$sidebarFullWidth});
        min-width: 40rem;
        border-radius: 0.25rem;
        z-index: $alert-z-index;
        border: none;
        pointer-events: auto;
        border-radius: 0.5rem;
        gap: 1rem;

        &.compact {
            margin-left: $sidebarCompactWidth;
            width: calc(70% - #{$sidebarCompactWidth});
            min-width: 40rem;
        }

        &.alert-danger {
            background-color: $red-100;
            border: 0.063rem solid $red-400;

            i {
                transform: rotate(180deg);
            }

            .alert-text-wrapper {
                color: $red-800;
            }

            .alert-description-wrapper {
                color: $red-800;
            }

            .close-button {
                &:hover {
                    background-color: $red-200;
                }
            }
        }

        &.alert-success {
            background-color: $green-100;
            border: 0.063rem solid $green-400;

            .alert-text-wrapper {
                color: $green-800;
            }

            .alert-description-wrapper {
                color: $green-800;
            }

            .close-button {
                &:hover {
                    background-color: $green-200;
                }
            }
        }

        &.alert-info {
            background-color: $gray-100;
            border: 0.063rem solid $blue-400;

            .alert-text-wrapper {
                color: $blue-800;
            }

            .alert-description-wrapper {
                color: $blue-800;
            }

            .close-button {
                &:hover {
                    background-color: $gray-200;
                }
            }
        }

        &.alert-warning {
            background-color: $yellow-100;
            border: 0.063rem solid $yellow-400;

            .alert-text-wrapper {
                color: $yellow-800;
            }

            .alert-description-wrapper {
                color: $yellow-800;
            }

            .close-button {
                &:hover {
                    background-color: $yellow-200;
                }
            }
        }

        .info-part {
            display: flex;
            align-items: center;
            gap: 1rem;

            .alert-icon-wrapper {
                height: 1rem;
                width: 1rem;
            }

            .alert-message-wrapper {
                display: flex;
                flex-direction: column;
                flex-wrap: wrap;
                width: 100%;

                .alert-text-wrapper {
                    height: auto;
                    hyphens: auto;
                    word-break: break-word;
                    line-height: 1.5rem;
                }
            }
        }

        .actions-part {
            display: flex;
            align-items: center;
            gap: 1.5rem;

            .alert-action-button-wrapper {
                display: flex;
                gap: 1rem;

                .alert-action-button {
                    border-radius: 0.5rem;
                    padding: 0.5rem 1.5rem;
                    width: 2rem;
                    height: 2rem;
                }
            }

            .close-button {
                cursor: pointer;
                padding: 0.5rem;
                margin: 0 -0.5rem;

                &:hover {
                    transition: 0.2s;
                    border-radius: 3.125rem;
                }
            }
        }
    }
}


@import '~@/assets/scss/palette';
@import '~@/assets/scss/layout';
@import '~@/assets/scss/typographyV2';

.page-background {
    background-color: $white;
    height: 100%;
}

.content-container {
    width: 70%;
    margin: 0 15%;
}

.lf-all-caps {
    color: $gray-blue;
}

.search-options-dropdown,
.search-operator-dropdown {
    width: min-content;
    min-width: 4.75rem;
    white-space: nowrap;

    ::v-deep .multiselect__content-wrapper {
        min-width: 9rem;
    }
}

.value-input-container {
    width: 100%;
    max-width: 600px;
}

.search-button-wrapper {
    margin-left: auto;
}


@import '~@/assets/scss/consistency';
@import '~@/assets/scss/mixins';
@import '~@/assets/scss/palette';

.search-box {
    width: 15rem;
}

.section-layout {
    padding: 0 $spacing-xl $spacing-xl;
}

.container-page {
    background-color: $dirty-white;
    min-height: 100vh;
    justify-content: space-between;
}

.pagination-amount {
    overflow: visible;
    width: 8rem;

    ::v-deep .multiselect__tags {
        padding-left: $spacing-xs !important;
    }
}

::v-deep .app-table .table-wrapper {
    padding-bottom: 0;
}


@import '~@/assets/scss/consistency.scss';
@import '~@/assets/scss/animations';

.section-title {
    ::v-deep .icon-left-button .icon {
        margin: 0;
    }

    background-color: $white;
    font-size: 0.75rem;
    font-weight: bold;
    line-height: 2.17;
    color: $blue-300;
    padding: 1rem 1rem 0.5rem 0;
    justify-content: space-between;
    text-transform: uppercase;
    cursor: pointer;

    &.section-title-basic {
        margin: 0;
        border-bottom: solid 1px #f1f1f1;
    }

    span {
        width: 90%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.arrow {
    transition: $fast-animation-time ease-in-out;

    &.collapsed {
        transform: rotate(180deg);
    }
}


@import '~@/assets/scss/palette';
@import '~@/assets/scss/typographyV2';
@import '~@/assets/scss/layout';
@import '~@/assets/scss/z-indexes';

.card-container {
    position: relative;
    width: 20rem;
    min-height: 6.25rem;
    border-radius: 0.5rem;
    box-shadow: 0 0.125rem 0.375rem 0 rgb(51 81 149 / 30%);
    background-color: $white;

    display: flex;
}

.more-section {
    position: absolute;
    top: 0;
    right: 0;

    .more-dropdown {
        position: absolute;
        z-index: $overlap-smth-z-index;
        width: 8rem;
        padding: 0.125rem 0;
        background-color: $white;
        border-radius: 0.25rem;
        box-shadow: 0 0.25rem 0.5rem 0 rgba(51, 81, 149, 0.3);

        .dropdown-row {
            display: flex;
            align-items: center;
            padding: 0.375rem 0.5rem;
            cursor: pointer;

            span {
                padding-left: 0.5rem;
            }

            &:hover {
                background-color: $blue15;
            }
        }
    }
}

.date-section {
    width: 5.3125rem;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
}

.date-number {
    font-size: 2rem;
    color: $blue;
    font-weight: $medium-font-weight;
}

.date-month {
    color: $gray-blue;
    margin-top: -0.25rem;
}

.main-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-width: 0;
    padding: 0.625rem 0;
}

.date-day-of-week {
    margin-bottom: -0.25rem;
}

.read-note-text,
.appointment-history-text {
    cursor: pointer;
    font-size: $text-xs;
    color: $blue;

    &:hover {
        text-decoration: underline;
    }
}

.note {
    font-size: $text-xs;
    width: 10.375rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.card-hovered-container {
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.date-and-time-tooltip {
    color: $gray-blue;

    margin-bottom: $spacing-s;
}

.note-tooltip-wrapper {
    width: 5rem;
}

.note-tooltip {
    word-break: break-word;
    line-break: auto;
}
.appointment-state {
    font-weight: bold;
}
.appointment-state-gray {
    color: $gray30;
}
.appointment-state-orange {
    color: $orange;
}
.appointment-state-blue {
    color: $blue40;
}
.appointment-state-green {
    color: $green40;
}
.appointment-state-red {
    color: $red30;
}
.appointment-state-black {
    color: $black;
}

.overflow-ellipsis {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

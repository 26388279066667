
@import '~@/assets/scss/consistency-colors';
@import '~@/assets/scss/consistency-typography';

$icon-path: '~@/assets/icons/';

.row {
    display: flex;
    align-items: center;
    height: 3rem;
    position: relative;

    &:hover {
        background: $blue-200;
    }
}

.content {
    display: flex;
    align-items: center;
    margin-left: 0.25rem;
    max-height: 3rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.vertical-svg {
    position: relative;
    top: 0;
    height: 3rem;
    width: 1.625rem;
    margin-left: 2rem;
}

.dropdown-arrow {
    cursor: pointer;
}

.rotate180 {
    transform: rotate(180deg);
}

.arrow-margin {
    margin-left: 1.125rem;
}

.label {
    padding-left: 0.5rem;
}

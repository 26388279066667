
@import '~@/assets/scss/palette';
@import '~@/assets/scss/base';
@import '~@/assets/scss/icons';
@import '~@/assets/scss/animations';
@import '~@/assets/scss/typographyV2';
@import '~@/assets/scss/z-indexes';

$icon-path: '~@/assets/icons/';

.table-content {
    width: 60%;

    tr .table-cell {
        width: 70px;

        &:last-of-type {
            width: calc(100% - 250px);
        }
    }

    .entity-row {
        position: relative;
        border-bottom: 1px solid $dirty-white;

        color: $gray90;
        background-color: $white;
        overflow: visible;

        transition-property: background-color;
        transition-duration: 0.25s;

        & > * {
            line-height: 40px;
        }

        input {
            border: none;
            background-color: $white;
        }

        .last-td-flex {
            display: flex;
        }

        .amount-error {
            border: 1px solid red;
            border-radius: 0.5rem;
        }
    }
}

.delete-button {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(-50%, -50%);
}

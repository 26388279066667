
@import '~@/assets/scss/_palette.scss';
@import '~@/assets/scss/_layout.scss';
@import '~@/assets/scss/_typographyV2.scss';
@import '~@/assets/scss/_consistency.scss';

.vr-card {
    max-width: 50rem;
    margin: $spacing-xxxl auto;
}

.execution-type {
    margin-left: $spacing-s;
    width: 15rem;
}

.hierarchy-scope {
    width: calc(60% - 15.75rem);
}

.inputs-row {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: $spacing-s;

    .inputs-row-item {
        flex-grow: 1;
    }

    .editor-input-large ::v-deep .mx-datepicker {
        width: 100%;
    }
}

.text-center ::v-deep input {
    text-align: center;
}

.per-entity-limits {
    width: 100%;
    border: 1px solid $gray-100;
    border-radius: $spacing-xs;
    .tablerow {
        border-bottom: 1px solid $gray-100;
        display: flex;
        min-height: $spacing-xxxl;
        align-items: center;
        padding: $spacing-xs $spacing-xxl;
        gap: $spacing-xxl;

        &:last-child {
            border-bottom: none;
        }

        .value {
            width: 80%;
            flex-grow: 1;
            word-break: break-all;
            line-height: 1.2;

            &.xs {
                width: 10%;
            }
        }
    }
}


@import '~@/assets/scss/animations';
@import '~@/assets/scss/consistency';

$yamlHeight: 27rem;

.yaml {
    height: $yamlHeight;
}

.yaml-editor {
    width: 100%;
    height: $yamlHeight;
    border: 1px solid $gray-150;
    border-radius: 0.5rem;
    overflow: hidden;
}

.loader {
    margin: $spacing-xxl 4rem;
}

.user-guide {
    transition: color $medium-animation-time;
}


@import '~@/assets/scss/palette';
@import '~@/assets/scss/base';
@import '~@/assets/scss/icons';
@import '~@/assets/scss/animations';
@import '~@/assets/scss/typographyV2';
@import '~@/assets/scss/z-indexes';
@import '~@/assets/scss/layout';

$icon-path: '~@/assets/icons/';

.table-wrapper {
    margin-top: 1rem;
    border-radius: 0.5rem;
    padding: 0.3125rem 0.5rem;
    border: 1px solid $gray5;
    outline: none;
}

table {
    width: 100%;
    table-layout: fixed;
}

th,
td {
    padding-right: 1.5rem;
}

tr {
    // Lines between rows
    border-bottom: 1px solid $dirty-white;
    color: $gray90;
    background-color: $white;
    overflow: visible;
    height: 40px;

    input {
        width: 100%;
        height: 2rem;
        padding: 0.25rem 0.5rem 0.375rem;
        border-radius: 0.5rem;
        border: 1px solid $gray5;
        line-height: 20px;
        font-size: $text-sm;

        &.error {
            border: 1px solid $red;
        }
    }
}

.amount {
    width: 5.5rem;
}
.rounding-indicator {
    width: 4rem;
}
.row-actions {
    width: 2.5rem;
}

/** Disable the increment/decrement shown for input elements of type number */
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}


@import '~@/assets/scss/base';
@import '~@/assets/scss/forms';
@import '~@/assets/scss/palette';
@import '~@/assets/scss/inputs';
@import '~@/assets/scss/animations';
@import '~@/assets/scss/editor-layout-v2';
@import '~@/assets/scss/typographyV2';

.summary-table {
    max-height: 40rem;
    overflow-y: scroll;
}

.change-table {
    background-color: $blue5;
    border-radius: 1rem;
    padding: 1.5rem;
    margin-right: 1rem;
    margin-bottom: 2rem;
}

.title-margin {
    margin-bottom: 0.5rem;
}

.modal-content-wrapper {
    margin: $spacing-l;

    display: flex;
    flex-direction: column;
}

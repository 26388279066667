
@import '~@/assets/scss/animations';
@import '~@/assets/scss/palette';
@import '~@/assets/scss/layout';
@import '~@/assets/scss/typographyV2';

.form {
    padding: 2.5rem;
}

h2 {
    margin-bottom: 2.5rem;
}

h3 {
    font-weight: $bold-font-weight;
    line-height: $label-line-height;
}

.error-message {
    color: $red;
}

.terminate-notice {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    min-height: 3rem;
    padding: 0.625rem 1rem;
    border-radius: 0.25rem;
    font-size: $text-sm;
    font-weight: $medium-font-weight;
    color: $white;
    background-color: $yellow;
    transition: $fast-animation-time;

    &.slide-fade-in-enter-active,
    &.slide-fade-in-leave-active {
        opacity: 0;
        transform: translateX(-1.5rem);
    }
}

.radios ::v-deep label {
    color: $black;
}

#app .reason ::v-deep :is(.multiselect, .multiselect__input, .multiselect__single) {
    font-size: $text-sm;
}

#app .reason ::v-deep .multiselect__tags {
    padding-left: $spacing-xs;
}

.form ::v-deep textarea {
    width: 100%;
    padding-inline: $spacing-xs;
    border-radius: 0.5rem;
    font-size: $text-sm;

    &::placeholder {
        font-style: italic;
        opacity: 1;
        font-size: inherit;
    }
}

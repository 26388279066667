
@import '~@/assets/scss/palette';
@import '~@/assets/scss/icons';
@import '~@/assets/scss/mixins';
@import '~@/assets/scss/layout';

$icon-path: '~@/assets/icons/';

.orchestration-page-container {
    height: initial !important;
    min-height: 100%;
}

.page-content-wrapper {
    padding: 0 $spacing-l $spacing-l $spacing-l;

    .lf-table-title {
        padding-top: 1.5rem;
    }
    .executions-table-headers {
        display: flex;
        justify-content: flex-end;
    }
}

.background-color {
    background-color: $dirty-white;
}

.content-block-wrapper {
    border-radius: 0.5rem;
    padding: $spacing-s $spacing-l $spacing-l;
    background-color: $white;

    .title {
        margin-bottom: $spacing-m;
        font-size: 0.75rem;
        font-weight: bold;
        line-height: 1.75;
        text-transform: uppercase;
        color: $gray-blue;
    }

    .content {
        display: flex;
    }

    .content-item-wrapper {
        display: flex;
        width: 50%;
        margin-bottom: $spacing-l;

        .content-key {
            font-size: 0.75rem;
            font-weight: 600;
            color: $gray90;
            line-height: 1.5;
            min-width: 30%;
            max-width: 50%;
        }

        .content-value {
            font-size: 0.875rem;
            line-height: 18px;
            color: $gray90;
            min-width: 50%;
            max-width: 70%;
        }
    }
}

.page-content-block-title {
    padding: 13px 0;
    font-size: 1rem;
    line-height: 22px;
    font-weight: 600;
    color: $gray90;
}

.execution-search-container {
    max-width: 35%;
    display: flex;
    align-items: center;
    padding-bottom: 1.5rem;

    .value-input-container {
        width: 20rem;
    }

    .search-button-wrapper {
        margin-left: auto;
    }
}

.pagination-amount {
    overflow: visible;
    width: 8rem;

    ::v-deep .multiselect__tags {
        padding-left: $spacing-xs !important;
    }
}

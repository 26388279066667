
@import '../../assets/scss/base';
@import '../../assets/scss/icons';
@import '../../assets/scss/palette';

$icons-path: '../../assets/icons/';

.tabs-header {
    .tab-header {
        height: 2.625rem;
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
        font-size: 0.75rem;
        font-weight: 600;
        line-height: 2.17;
        color: $gray30;
        cursor: pointer;

        &:hover {
            color: $gray-blue;
        }

        &.disabled {
            color: $gray5;

            span {
                opacity: 0.4;
            }
        }

        &.selected {
            position: relative;
            background-color: $white;
            color: $gray-blue;
            border-radius: 0.5rem 0.5rem 0 0;
        }

        span {
            height: 22px;
            width: 22px;
            margin-right: 5px;
        }
    }
}

.tab-content {
    .tab {
        display: flex;
        flex-grow: 1;

        .chart-placeholder:before {
            content: url($icons-path + $chart);
        }

        .placeholder {
            width: 30%;
            min-width: 125px;
            color: $gray5;
            font-size: 14px;
            text-align: center;
        }
    }
}

.overview-container {
    .section-header {
        color: $gray5;
        font-size: 13px;
    }
}

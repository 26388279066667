
@import '~@/assets/scss/palette';
@import '~@/assets/scss/layout';

.page-background {
    background-color: $dirty-white;
    height: 100%;
}

.page-content-wrapper {
    padding: $spacing-l;
}

.search-wrapper {
    width: 60%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding-top: 3px;
}

.search-with-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 50%;
}

.search-options-dropdown {
    width: 50%;
}

.search-query-input-wrapper {
    width: 50%;
    min-width: 200px;
}

.inline-text {
    font-size: 0.75rem;
    text-transform: uppercase;
    font-weight: bold;
    color: $gray-blue;
    margin-right: 0.75rem;
}


@import '~@/assets/scss/palette';
@import '~@/assets/scss/icons';

$icon-path: '~@/assets/icons/';

.custom-content {
    padding: 2rem 9.5rem 3.75rem;
    border-bottom: solid 0.0625rem rgba($color: $blue, $alpha: 0.15);
}

.custom-footer {
    padding: 1.5rem 9.5rem;
}

.title {
    font-size: 1.375rem;
    font-weight: 600;
    color: $gray90;
}

.custom-header {
    margin-bottom: 3.375rem;
}

.additional-btn {
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 1.86;
    color: $red;
    cursor: pointer;

    &.additional-btn-disabled {
        color: $gray60;
        cursor: auto;
    }
}

.img-wrap {
    width: 5rem;
    height: 5rem;
    margin-right: 1.25rem;
    position: relative;

    img {
        max-width: 100%;
        max-height: 100%;
    }
}

.add-photo-btn {
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    box-shadow: 0 0.25rem 0.5rem 0 rgba($color: $green, $alpha: 0.3);
    background-color: $white;
    position: absolute;
    bottom: 0;
    right: 0;
    cursor: pointer;

    &::after {
        content: url($icon-path + $plus-light);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    &:hover {
        &::after {
            content: url($icon-path + $plus-light-hover);
        }
    }
}

.id-title {
    font-size: 0.75rem;
    font-weight: 600;
    line-height: 2.17;
    margin-bottom: 0.25rem;
    color: $gray90;
}

.id {
    font-size: 0.875rem;
    line-height: 1.5;
    color: $gray90;
    padding: 0.3125rem 1.3125rem 0.3125rem 0.5rem;
    border-radius: 0.5rem;
    border: solid 0.0625rem rgba(55, 201, 170, 0.15);
    background-color: rgba(55, 201, 170, 0.1);
}

.custom-info-wrap {
    margin-bottom: 2.3125rem;
}

.custom-info {
    margin-bottom: 2rem;
}

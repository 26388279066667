@use "sass:string";
@import "./palette";
@import "./icons";

// custom-height dropdown
@mixin custom-height-dropdown($height) {
  ::v-deep {
    & > div {
      height: $height;
    }

    .dropdown-arrow {
      width: $height;
      margin-left: -$height;
    }
  }
}

// fixme
// custom-width dropdown
@mixin custom-width-dropdown($width) {
  min-width: $width;
  width: $width;

  ::v-deep .btn-group .dropdown-menu {
    min-width: $width;
  }

  ::v-deep .dropdown-input {
    width: $width;
  }
}

// custom-height datepicker
@mixin custom-height-datepicker($height) {
  height: $height;

  ::v-deep .dropdown-arrow {
    min-width: $height;
    width: $height;
    margin-left: -$height;
  }
}

// custom-width datepicker
@mixin custom-width-datepicker($width) {
  width: $width;
}

/** Segment statistics component icons */
@mixin statsIcons($icon-path) {
  span.icon {
    &:after {
      line-height: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &.card-icon:after { content: url($icon-path + $card-icon); }
    &.mail-icon:after { content: url($icon-path + $mail-icon); }
    &.push-icon:after { content: url($icon-path + $push-icon); }
    &.sms-icon:after { content: url($icon-path + $sms-icon); }
    &.profile-icon:after { content: url($icon-path + $profile-icon); }
    &.wallet:after { content: url($icon-path + $purchase-icon); }
  }
}

/** message type icons */
@mixin messageIcons($icon-path, $size: 32px) {
  span.icon {
    &:before {
      display: flex;
      content: '';
      width: $size;
      height: $size;
      background-position: center center;
      background-size: $size $size;
      background-repeat: no-repeat;
    }

    &.push:before { background-image: url($icon-path + $push-icon); }
    &.sms:before { background-image: url($icon-path + $sms-icon); }
    &.popUp:before { background-image: url($icon-path + $popup-icon); }
    &.email:before { background-image: url($icon-path + $mail-icon); }
    &.ott:before { background-image: url($icon-path + $ott-icon); }
    &.unknown:before { background-image: url($icon-path + $question-icon); }

    // for gray icons
    &.push-gray:before { background-image: url($icon-path + $push-icon-gray); }
    &.sms-gray:before { background-image: url($icon-path + $sms-icon-gray); }
    &.popUp-gray:before { background-image: url($icon-path + $popup-icon-gray); }
    &.email-gray:before { background-image: url($icon-path + $mail-icon-gray); }
    &.ott-gray:before { background-image: url($icon-path + $ott-icon-gray); }
    &.unknown-gray:before { background-image: url($icon-path + $question-icon-gray); }
  }

  &.selected span.icon {
    &.push:before { background-image: url($icon-path + $push-icon-selected); }
    &.sms:before { background-image: url($icon-path + $sms-icon-selected); }
    &.popUp:before { background-image: url($icon-path + $popup-icon-selected); }
    &.email:before { background-image: url($icon-path + $mail-icon-selected); }
    &.ott:before { background-image: url($icon-path + $ott-selected-icon); }
    &.unknown:before { background-image: url($icon-path + $question-icon); }
  }
}

/** campaign status circles */
@mixin campaignStatus($icon-path) {
  .status {
    &:before { margin-right: 8px; }

    &.blue:before { content: url($icon-path + $oval-blue); }
    &.green:before { content: url($icon-path + $oval-green); }
    &.red:before { content: url($icon-path + $oval-red); }
    &.gray:before { content: url($icon-path + $oval-gray); }
    &.yellow:before { content: url($icon-path + $oval-yellow); }
  }
}

/** links */
@mixin linkStyle($text-decoration) {
    text-decoration: $text-decoration;
    color: $blue;
    cursor: pointer;
    transition: color .2s ease;

    &:hover {
        color: $navy;
    }
}

/** sidebar element hover indicator */
@mixin sidebarHoverIndicator($widthSize, $background, $backgroundImage) {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: $widthSize;
    height: 100%;
    border-bottom-right-radius: 100px;
    border-top-right-radius: 100px;
    background-color: $background;
    background-image: $backgroundImage;
    transition: width .2s ease;
    opacity: .8;
}

// Breakpoints const
$large-breakpoints: 992px;
$medium-breakpoints: 768px;


// media queries
@mixin tablet {
  @media (max-width: #{$large-breakpoints - 1px}) {
      @content;
  }
}

@mixin mobile {
  @media (max-width: #{$medium-breakpoints - 1px}) {
      @content;
  }
}

@mixin backgroundNet($color) {
  position: relative;
  overflow-y: hidden;
  background-color: rgba($color, 0.3);

  &::after {
      content: '';
      position: absolute;
      display: block;
      width: 100%;
      height: calc(100% + 0.8rem);
      top: -0.8rem;
      left: 0;
      background: repeating-linear-gradient(-60deg, transparent, transparent 0.3125rem, $color 0, $color 0.375rem), repeating-linear-gradient(60deg, transparent, transparent 0.3125rem, $color 0, $color 0.375rem);
  }
}

@mixin visuallyHidden {
    position: absolute;
    height: 1px;
    width: 1px;
    overflow: hidden;
    white-space: nowrap;
    clip-path: inset(50%);
}

@mixin lineClamp($noOfLines: 1) {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: $noOfLines;
    line-clamp: $noOfLines;
    overflow: hidden;
    word-break: break-all;
}



@mixin dashedBorder($color: currentColor, $width: 1, $dashWidth: 10, $radius: 12) {
    $width: #{$width + 1};
    $dashOffset: #{$dashWidth * 0.5};

    @if string.index("#{$color}", '#')==1 {
        $color: '%23' + string.slice("#{$color}", 2);
    }

    background: no-repeat url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='#{$radius}' ry='#{$radius}' stroke='#{$color}' stroke-width='#{$width}' stroke-dasharray='#{$dashWidth}%2c #{$dashWidth}' stroke-dashoffset='#{$dashOffset}' stroke-linecap='square'/%3e%3c/svg%3e");
    border-radius: #{$radius}px;
}


@import '~@/assets/scss/palette';
@import '~@/assets/scss/base';
@import '~@/assets/scss/icons';
@import '~@/assets/scss/animations';
@import '~@/assets/scss/typographyV2';
@import '~@/assets/scss/z-indexes';

$icon-path: '~@/assets/icons/';

.info-icon {
    width: 1rem;
    height: 1rem;
    content: url($icon-path + $info-grey);

    &:hover {
        content: url($icon-path + $info-blue);
    }
}

.no-box-shadow {
    box-shadow: none;
}

.table-wrapper {
    padding-bottom: 16px;
    min-height: 100px;
    height: var(--table-wrapper-height);

    &.bordered {
        border: 1px solid $dirty-white;
    }
}

.table-header {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: $overlap-smth-z-index;
    white-space: nowrap;
    // JUST HEIGHT DON'T WORK IN SAFARI
    min-height: 42px;

    border-bottom: solid 1px #f1f1f1;
    border-top: solid 1px #f1f1f1;
    background-color: transparent;
}

.table-header-cell {
    transition: width 0.1s ease 0s;
    padding-right: 10px;
}

.table-header-cell:hover {
    border-right: solid 1px #f1f1f1;
    border-left: solid 1px #f1f1f1;
}

.table-upper-content-wrapper {
    background: $white;
    padding: 6px 1rem;
}
.table-second-upper-content-wrapper {
    background: $white;
    padding: 6px 1rem;
}
.table-title {
    color: $gray60;
}

.table-search-box {
    width: 15rem;
}

.table-footer {
    padding-left: 24px;

    .footer-button {
        margin-right: 12px;

        &:last-child {
            margin-right: 0;
        }
    }
}

.table-content {
    .empty-table-row {
        text-align: center;
        background-color: $white;
        border-bottom: 1px solid $dirty-white;
        padding-top: 3rem;
        padding-bottom: 3rem;
    }
}

th {
    font-weight: inherit;
}

thead {
    position: relative;

    th:last-child .col-12 {
        padding-right: 40px;
    }
}

.select-filters {
    position: absolute;
    top: 30%;
    right: 0.75rem;
    width: 1.75rem;
    height: 1.75rem;
}

.select-column-dropdown {
    position: absolute;
    top: 2.625rem;
    right: 0;
    border-radius: 0.25rem;
    border: none;
    box-shadow: 0 0.25rem 0.5rem 0 rgba(51, 81, 149, 0.3);
    background-color: $white;
    z-index: $icon-button-z-index;
    max-height: 25rem;
    overflow: auto;

    .filter-checkbox {
        padding: 0.625rem 0.875rem 0.625rem 1.5rem;

        &:hover {
            background-color: $dirty-white;
        }
    }

    .empty-list-label {
        padding: 0.625rem 1.5rem;
        font-size: $text-sm;
    }
}

.cursor-pointer {
    cursor: pointer;
}

.column-title {
    max-width: 100%;

    &.draggable {
        cursor: move;
    }
}

.resize-handle {
    background-color: transparent;
    width: 4px;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
}

.resize-handle:hover {
    cursor: col-resize;
    border-right: 2px solid $blue;
}

.resize-handle:active {
    opacity: 0;
}

.vertical-line {
    position: absolute;
    top: 0px;
    bottom: 0px;
    width: 2px;
    background-color: #f1f1f1;
    z-index: $overlap-smth-z-index;
}

.vertical-line:hover {
    cursor: col-resize;
}

.margin-right {
    margin-right: 18px;
}

.row-hovered:hover {
    background-color: $dirty-white;
}

.draggable-wrap {
    display: flex;
    width: 100%;
}

.multiselect-enabled {
    .draggable-wrap {
        width: calc(100% - 2rem);
    }
}

.app-table {
    display: flex;
    flex-direction: column;

    .table-header {
        background: $white;
    }

    .table-content:last-of-type ::v-deep .entity-row {
        border-radius: 0 0 0.5rem 0.5rem;
    }

    .round-border {
        .table-header {
            border-radius: 0.5rem 0.5rem 0 0;
        }
    }

    .empty-table-row-text {
        opacity: 0.5;
        font-size: 1rem;
        color: $blue;
        width: fit-content;
        font-weight: 600;
    }

    .empty-table-row-icon {
        margin-right: 2rem;
        width: 100px;
        height: 100px;
    }
}
.select-all-check {
    padding: 0.4rem 0.313rem 0.313rem;
}
.select-all-title {
    padding-bottom: 10px;
    padding-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.table-scroll-wrapper {
    position: relative;
    overflow: auto;

    &.show-border-fade::after {
        content: '';
        position: sticky;
        top: 0;
        right: 0;
        width: 50px;
        height: calc(100% - 1.875rem);
        background: linear-gradient(to left, $white 75%, transparent);
    }

    &.has-entities {
        &.show-border-fade::after {
            height: calc(100% - 3.125rem);
        }

        .table-content-container {
            height: 100%;
            overflow: auto;
            padding-bottom: 1.25rem;
        }
    }

    .scroll-content-wrapper {
        height: 100%;

        .scroll-content {
            display: flex;
            flex-direction: column;
        }

        &::-webkit-scrollbar {
            height: 30px;
            width: 30px;
        }

        &::-webkit-scrollbar-track {
            border-radius: 100px;
            box-shadow: inset 0 0 4px 4px #eaedf0;
            border: solid 13px transparent;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 100px;
            box-shadow: inset 0 0 4px 4px #cdd0d7;
            border: solid 13px transparent;
        }

        &::-webkit-scrollbar-corner {
            background-color: transparent;
        }
    }
}

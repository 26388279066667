
@import '~@/assets/scss/consistency-spacing';
@import '~@/assets/scss/consistency-typography';

.label {
    padding: $spacing-xxs $spacing-s;
    border-radius: 0.25rem;
    font-weight: 600 !important;
    font-size: 0.75rem;
    line-height: 1rem;
    text-align: center;
}

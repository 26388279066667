
@import '~@/assets/scss/consistency-spacing';

.sidebar-title {
    margin-bottom: $spacing-xxl;
}

dd {
    white-space: pre-line;
}


@import '~@/assets/scss/z-indexes';
@import '~@/assets/scss/animations';
@import '~@/assets/scss/consistency-colors';
@import '~@/assets/scss/consistency-spacing';
@import '~@/assets/scss/consistency-typography';

.icon-button-container {
    cursor: pointer;
    position: relative;
    overflow: visible !important;

    width: 40px;
    height: 40px;
    padding: 4px;

    &.disabled {
        cursor: not-allowed;
    }
}

.icon-button {
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: all;

    &:not(:active):not(.active-icon) {
        color: $gray-300;
    }

    &:not(.disabled):hover {
        background-color: $gray-200;
    }

    &:not(.disabled):active {
        background-color: $blue-400;
        color: $white;
    }
}

.icon-tooltip {
    min-width: 3rem;
    height: 1.3125rem;
    border-radius: 0.625rem;
    white-space: nowrap;

    background-color: $gray-200;
    position: absolute;
    // precisely in px to have 2px space between icon and tooltip
    bottom: -18px;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: $icon-button-z-index;

    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 $spacing-xs;

    color: $blue-400;
}


@import '~@/assets/scss/consistency';

.modal-wrapper {
    width: max-content;
    min-width: 50vw;
    max-width: 75vw;
    padding: $spacing-xxl;
}

.price-list {
    display: grid;
    grid-template-columns: max-content 10rem;
    align-items: center;
    gap: $spacing-xs;

    &.edit-form {
        grid-template-columns: repeat(2, max-content) 10rem;
    }
}


@import '~@/assets/scss/palette';
@import '~@/assets/scss/icons';
@import '~@/assets/scss/typographyV2';

$icon-path: '~@/assets/icons/';

.info-icon {
    width: 1rem;
    height: 1rem;
    content: url($icon-path + $info-grey);
}

.tooltip-container {
    height: 1rem;
}

.tooltip-inner-content {
    width: 26.25rem;
}

.input-max-width {
    max-width: 567px;
}

.input-label {
    font-weight: bold;

    font-size: 0.75rem;

    color: $gray90;
}

.amount-label {
    display: flex;
    align-items: center;
    height: 2.125rem;
}

.dialog-wrapper {
    ::v-deep .content {
        overflow: initial;
    }
}

.editor-wrapper {
    max-height: 550px;
    max-width: 880px;
    margin: 0 3rem;
}

.section-title {
    font-size: 1rem;
    font-weight: bold;

    color: $gray90;

    margin-top: 2rem;
    margin-bottom: 1rem;
}

.input-width {
    min-width: 17.6875rem;
    width: 30%;
}

.editor-controls-wrapper {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    height: 5.56rem;
    padding: 1.5rem 6rem;
    border-top: 1px solid $blue15;
    border-radius: 0 0 0.5rem 0.5rem;
    background-color: $white;

    .editor-controls-content {
        display: flex;
        justify-content: flex-end;
    }
}

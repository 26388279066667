
@import '~@/assets/scss/consistency';
@import '~@/assets/scss/z-indexes';

.content-wrapper {
    padding: $spacing-xxl;
}

.list-grid {
    display: grid;
    grid-template-columns: minmax(16rem, 1fr) 1fr minmax(12rem, 32rem);
}

.list {
    height: calc(100vh - 17rem);

    &:not(.list--jobs) {
        border-right: 1px solid $gray-200;
    }
}

.toggle-position {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: $spacing-m;
}

.submit-btn {
    margin: $spacing-xs $spacing-m $spacing-m auto;
}

.list ::v-deep {
    .table-wrapper {
        padding-bottom: 0;
    }

    // Fix row actions being cutoff on last item
    .table-scroll-wrapper.has-entities {
        flex-grow: 1;
    }

    // Fix row actions overlapping table header
    .table-header {
        z-index: $two-z-index;
    }

    .empty-table-row {
        flex-direction: column !important;
        padding-inline: $spacing-l;
        border-bottom: 0 !important;
    }

    .empty-table-row-icon {
        margin-right: 0;
    }

    tr.selected:not(:hover) {
        background-color: rgba($blue, 0.05);
    }

    .button-wrapper {
        height: 2.5rem;
        background-color: inherit;
        transition: background-color 0.25s ease;
    }
}

.list--jobs ::v-deep {
    .table-cell {
        padding-right: 8rem !important;
    }
}


@import '~@/assets/scss/animations';
@import '~@/assets/scss/mixins';
@import '~@/assets/scss/consistency-colors';
@import '~@/assets/scss/sidebar';
@import '~@/assets/scss/consistency-spacing';
@import '~@/assets/scss/z-indexes';

.hover-indicator {
    @include sidebarHoverIndicator(0, $white, none);
    height: 37px;
}

.no-dropdown-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;

    &.compact {
        display: inline-flex;
    }

    .img-wrapper {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 41px;
        width: 41px;
        cursor: pointer;
        color: $white;

        &.compact {
            position: static;
        }

        &:hover {
            color: $white;
        }

        .menu-item-icon {
            width: 16px;
            height: 16px;
            margin-left: 16px;
            color: $white;
        }
    }

    .no-dropdown {
        display: flex;
        align-items: center;
        min-height: 41px;
        margin-left: 52px;
        color: $white;

        .header-without-dropdown {
            padding: 7px 0;
            color: rgba($white, 0.8);

            &.active {
                font-weight: bold;
                color: $white;
            }

            &.compact {
                display: none;
            }
        }

        .hover-indicator {
            width: 0;

            &.active {
                width: 4px;
            }
        }
    }

    &:hover {
        .hover-indicator {
            width: 4px;
        }
    }
}

.dropdown-wrapper {
    position: relative;
    display: flex;
    align-items: flex-start;

    .hover-indicator {
        left: 0;

        &.active-compact {
            width: 4px;
            left: 0;
        }
    }

    &:hover {
        .hover-indicator {
            width: 4px;
        }
    }

    .img-wrapper {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 0 0 35px;
        height: 41px; // 41px needed because of the browser's height miscalculation
        width: 41px;
        z-index: $sidebar-stuff-z-index;
        cursor: pointer;

        &.compact {
            position: static;
            .menu-icon {
                margin-left: 21px;
            }
        }

        .menu-icon {
            width: 16px;
            height: 16px;
            margin-left: 16px;

            .compact & {
                position: static;
            }
        }
    }

    .dropdown {
        position: relative;
        min-height: 41px;
        width: 100%;

        &.compact {
            position: absolute;
            background: $navy;
            left: 64px;
            min-width: 250px;
            padding: 5px 0 15px 0;
            border-bottom-right-radius: 13px;
            border-top-right-radius: 13px;
            visibility: hidden;

            .dropdown-header.compact {
                display: block;
                margin-left: 0;
            }

            &.visible {
                visibility: visible;
            }
        }

        .dropdown-header {
            display: flex;
            justify-content: space-between;
            padding: $spacing-xs 0 $spacing-xs 3.25rem;
            width: 100%;
            color: rgba($white, 0.8);
            cursor: pointer;

            &.compact {
                padding-left: $spacing-xl;
                visibility: hidden;
            }

            .menu-dropdown-toggle {
                padding-right: $spacing-xs;
            }

            &.active {
                font-weight: bold;
                color: $white;

                &.compact {
                    visibility: visible;
                }
            }

            &.active-parent {
                font-weight: bold;
                color: $white;
            }

            .hover-indicator {
                &.active-header {
                    width: 4px;
                }
            }

            &:hover {
                .hover-indicator {
                    width: 4px;
                }
            }
        }

        .sub-item {
            display: block;
            position: relative;
            padding: 0.4375rem 0 0.4375rem 3.25rem;
            max-width: 95%;
            color: rgba($white, 0.6);
            cursor: pointer;
            text-decoration: none;

            &.compact {
                padding-left: 1.5rem;
            }

            &.active {
                border-bottom-right-radius: 100px;
                border-top-right-radius: 100px;
                background-color: #21395b;
                font-weight: bold;
                color: $white;
            }

            .hover-sub-indicator {
                display: block;
                position: absolute;
                left: 0;
                top: 0;
                width: 0;
                height: 100%;
                border-bottom-right-radius: 100px;
                border-top-right-radius: 100px;
                background-color: $white;
                transition: width 0.2s ease;
                z-index: $sidebar-stuff-z-index;
                opacity: 1;

                &.active-sub-indicator {
                    @extend .hover-sub-indicator;
                    background-image: $sidebar-gradient;
                    width: 4px;
                }
            }

            &:hover {
                .hover-sub-indicator {
                    width: 4px;
                    border-bottom-right-radius: 100px;
                    border-top-right-radius: 100px;
                    background-color: $white;
                    font-weight: bold;
                }
            }
        }
    }
}

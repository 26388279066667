
@import '~@/assets/scss/consistency';
@import '~@/assets/scss/base';
@import '~@/assets/scss/z-indexes';

.wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: $modal-z-index;
    background: rgba($color: $navy, $alpha: 0.9);
}

.content {
    display: flex;
    flex-direction: column;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 8px;
    background-color: $white;

    width: 70%;
    max-height: 80%;
}

.header-modal {
    padding: $spacing-xl $spacing-xl $spacing-xl $spacing-xxxl;
    position: relative;

    &.border-bottom {
        border-bottom: 1px solid $gray-200;
    }

    .close-btn {
        position: absolute;
        top: $spacing-s;
        right: $spacing-xl;
    }

    .heading-gray {
        color: $gray-800;
    }
}

.content-modal {
    padding: $spacing-xl $spacing-xxxl;
    overflow-y: scroll;
}

.footer-modal {
    padding: $spacing-xxl $spacing-xxxl;
    border-top: 1px solid $gray-200;
    gap: $spacing-xl;
}

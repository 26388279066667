
@import '~@/assets/scss/palette';
@import '~@/assets/scss/icons';
@import '~@/assets/scss/animations';
@import '~@/assets/scss/z-indexes';
@import '~@/assets/scss/base';
@import '~@/assets/scss/typographyV2';

$icon-path: '~@/assets/icons/';

.row {
    height: 48px;
    padding-left: 50px;
    position: relative;

    &.root {
        &::before {
            content: url($icon-path + $arrow-up-gray);
            position: absolute;
            left: 17px;
            top: 50%;
            transform: translateY(-50%) rotate(180deg);
            transition: transform $fast-animation-time;
        }

        &.opened {
            .label {
                padding-left: 24px !important;
            }
            &:before {
                transform: translateY(-50%);
            }
        }
    }

    &.tree-item {
        border-bottom: 1px solid #f6f6f6;
        color: #333333;
        background-color: #ffffff;
        overflow: visible;
        transition-property: background-color;
        transition-duration: 0.25s;

        .table-cell {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;

            .empty-root {
                svg {
                    transform: none;
                }
            }
        }
    }
}

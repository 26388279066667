
@import '~@/assets/scss/palette';
@import '~@/assets/scss/typographyV2';
@import '~@/assets/scss/consistency-colors';
@import '~@/assets/scss/consistency-spacing';

.anchor-wrapper {
    padding: $spacing-xl;
    background-color: $gray-100;
}


@import '~@/assets/scss/animations';
@import '~@/assets/scss/consistency';
@import '~@/assets/scss/z-indexes';

summary::-webkit-details-marker,
summary::marker {
    content: none;
    display: none;
}

:global(.hover-row-buttons:has(details[open])) {
    z-index: $icon-button-z-index; // Fixes overlap with other table row actions when opened.
}

.pointer-none {
    pointer-events: none;
}

.menu-btn ::v-deep .icon {
    margin: 0 !important;
}

.actions-menu {
    position: absolute;
    top: calc(100% - #{$spacing-xs});
    right: 0;
    overflow: visible;
    border: 1px solid $gray-150;
    border-radius: 0.25rem;
    background-color: $white;

    [open] & {
        animation: $fast-animation-time slideFromTop ease, $fast-animation-time fadeIn linear;
    }
}

.action-btn.tertiary-button {
    justify-content: start !important;
    padding: 1rem 0.5rem;
    border-radius: 0;
    transition: $fast-animation-time;
    transition-property: color, background-color;

    &:hover {
        color: $white;
        background-color: $blue-400;
    }
}

menu {
    list-style: none;
}

li:last-child {
    margin-bottom: 0 !important;
}


@import '~@/assets/scss/_layout.scss';
@import '~@/assets/scss/_consistency-spacing.scss';

#general,
#condition,
#action {
    margin-bottom: $spacing-xxl;
}

#action {
    padding-bottom: $spacing-xxl;
}

.charge-input {
    width: 7rem;
}

.left-margin {
    margin-left: $spacing-s;
}

.right-margin {
    margin-right: $spacing-s;
}


@import '~@/assets/scss/typographyV2';
@import '~@/assets/scss/consistency-typography';

.divider {
    border-top-width: 1px;
    border-top-color: $gray4;
    border-top-style: solid;
    padding-top: 1rem;
}

.redirect-uris {
    list-style-type: none;
}

.details {
    border: 1px solid #dbe0ea;
    border-radius: 0.5rem;
    border-collapse: separate;
    border-spacing: 0px;
    width: 100%;
}

.details > thead > td,
.subscription-title {
    padding: 1rem;
}

.details > tbody > tr > td {
    padding: 0.5rem 1.5rem;
}
.details > tbody > tr > td:first-child {
    width: 20%;
    min-width: 10rem;
}
.details > tbody > tr:first-child td,
.subscriptions {
    border-top: 1px solid #dbe0ea;
    padding-top: 1rem;
}

.details > tbody > tr:last-child td {
    padding-bottom: 1rem;
}

.subscriptions {
    padding: 1rem 1rem 0 1rem;
}

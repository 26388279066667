
@import '~@/assets/scss/palette';
@import '~@/assets/scss/icons';

$icon-path: '~@/assets/icons/';

.row-state-controls {
    height: 40px;
    display: flex !important;
    padding-right: 0.5rem;
}


.image-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 2rem 2.5rem;

    .id-image {
        max-width: 100%;
        max-height: 100%;
    }
}

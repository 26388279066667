
.width {
    width: 100%;
}

.input-margin {
    margin-left: 4px;
}

.input-width {
    width: 90%;
}


@import '~@/assets/scss/mixins';
@import '~@/assets/scss/consistency';

.banner {
    padding: $spacing-m;
    border-radius: $spacing-xs;
    color: $yellow-800;
    background-color: $yellow-100;
}

.banner-icon {
    flex: 0 0 max-content;
    color: $yellow-400 !important;
}

.grid {
    display: grid;
    grid-template-columns: 45% repeat(2, minmax(auto, 4.25rem));
    align-items: center;
    gap: $spacing-xxs;
}

.elipsis {
    @include lineClamp;
}

.status--from ::v-deep .label {
    opacity: 0.5;
    text-decoration-line: line-through;
    text-decoration-style: double;
}


@import '~@/assets/scss/_palette.scss';

.special-numbers {
    max-width: 283px;
}

.section-content {
    font-size: 0.75rem;
    font-weight: 600;
    margin-bottom: 1rem;
    margin-right: 1rem;
    color: $gray90;
}

.notice {
    font-size: 0.75rem;
    font-style: italic;
}


@import '~@/assets/scss/_palette.scss';
@import '~@/assets/scss/_layout.scss';

.duplicate-slot-wrapper {
    .duplicate-slot-header {
        display: flex;
        justify-content: space-between;
        padding: 0 0 $spacing-xxs;

        .duplicate-slot-label {
            font-size: 0.75rem;
            color: $gray90;
            font-weight: 600;
            line-height: 1.625rem;
        }

        .duplicate-slot-optional {
            font-size: 0.75rem;
            font-style: italic;
            color: $gray60;
        }
    }

    .delete-btn {
        cursor: pointer;
        top: 1rem;
        height: 1rem;
        transform: translateY(calc(50% + 0.5rem));
        opacity: 0;
    }

    .one-input-delete {
        top: 0rem !important;
    }

    .slot-fields {
        &:hover {
            .delete-btn {
                opacity: 1;
            }
        }
    }
}

.explanation {
    line-height: 20px;
    color: $gray60;
    font-size: 12px;
    font-weight: 500;
}

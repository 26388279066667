
@import '~@/assets/scss/base.scss';
@import '~@/assets/scss/palette';
@import '~@/assets/scss/layout.scss';
@import '~@/assets/scss/icons.scss';
@import '~@/assets/scss/animations';

$icon-path: '~@/assets/icons/';

.conditions {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;

    .expression-row-container {
        width: 100%;
        align-items: center;
        justify-content: flex-start;
        border-radius: 0.4rem;
        background-color: $blue15;
        padding: 1rem;
        margin-bottom: 1rem;

        ::v-deep .icon-left-button .icon {
            margin: 0;
        }

        .arrow {
            transition: $fast-animation-time ease-in-out;

            &.collapsed {
                transform: rotate(180deg);
            }
        }

        .condition-row {
            display: flex;
            flex-direction: row;

            .condition-block {
                width: 100%;
            }

            .close-icon-container {
                height: 0.9rem;
                width: 0.9rem;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-left: 0.8rem;
                margin-top: 0.5rem;
                z-index: 1;
                background-clip: padding-box;
                cursor: pointer;
                height: 2rem;
            }
        }

        .expression-actions {
            display: flex;
            justify-content: space-between;
            padding-top: 1rem;

            .add-icon-container {
                display: flex;
                align-items: center;
                cursor: pointer;
                width: 100%;

                p {
                    color: $blue;
                }
            }

            .plus-blue-icon-button {
                width: 0.8rem;
                content: url($icon-path + $icon-plus-blue);
            }

            .remove-all {
                display: flex;
                align-items: flex-end;
                justify-content: right;
                width: 100%;
                cursor: pointer;

                p {
                    color: $blue;
                }
            }
        }
    }
}


@import '~@/assets/scss/_palette';
@import '~@/assets/scss/icons';

$icon-path: '~@/assets/icons/';

.sensitive-container {
    padding: 32px 40px;
}

.sensitive-title-wrapper {
    margin-bottom: 4%;

    .sensitive-title {
        position: relative;
        font-size: 1.375rem;
        font-weight: 600;
        color: $gray90;

        &.sensitive {
            &::after {
                content: url($icon-path + $alert);
                position: absolute;
                right: -30px;
            }
        }
    }
}

.sensitive-text {
    font-size: 0.875rem;
    color: $gray90;
}

.sensitive-buttons {
    display: flex;
    justify-content: flex-end;
}

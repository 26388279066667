
@import '~@/assets/scss/_palette';

.balance-container {
    padding: 6% 4% 4% 4%;
}

.balance-title {
    font-size: 1.375rem;
    font-weight: 600;
    color: $gray90;
    margin-bottom: 4%;
}

.balance-text {
    font-size: 0.875rem;
    color: $gray90;
}

.balance-buttons {
    display: flex;
    justify-content: flex-end;
}

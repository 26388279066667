
@import '~@/assets/scss/icons';
@import '~@/assets/scss/palette';

$icon-path: '~@/assets/icons/';

.header-bar {
    font-weight: 600;
    background-color: $gray5;
    color: $gray60;

    & > * {
        line-height: 40px;
    }
}


@import '~@/assets/scss/base';
@import '~@/assets/scss/forms';
@import '~@/assets/scss/palette';
@import '~@/assets/scss/inputs';
@import '~@/assets/scss/animations';
@import '~@/assets/scss/editor-layout-v2';
@import '~@/assets/scss/typographyV2';
@import '~@/assets/scss/z-indexes';

.gray-bar {
    background-color: $white;
    font-weight: bold;
    margin-bottom: 18px;

    .select {
        font-weight: initial;
    }
}

.product-type-label {
    font-size: 14px;
    font-weight: 600;
    line-height: 1.86;
    text-align: right;
    color: $gray60;
}

.key-value-label {
    font-size: 14px;
    font-weight: 600;
    line-height: 1.86;
    text-align: right;
    color: $gray60;
    align-self: end;
}

.buttons-row {
    margin: 8px;
}

.form-content {
    min-height: calc(100vh - 130px);
}

.label-danger {
    margin-left: 19px;
}
.labels-and-buttons-bar > * {
    margin-left: 1rem;
}
.state-label {
    height: 1.5rem;
}

.warning {
    color: $red;
}

.section-title {
    font-size: 1.125rem;
    font-weight: 600;
    margin: 2.125rem 0 1.25rem;
    color: $gray90;
}

.section-content {
    font-size: 0.75rem;
    font-weight: 600;
    margin-bottom: 1rem;
    margin-right: 1rem;
    color: $gray90;
}

.modal-content-wrapper {
    margin: $spacing-l;

    height: 100%;

    display: flex;
    flex-direction: column;
}

.content-controls {
    position: sticky;
    z-index: $overlap-smth-z-index;
    bottom: 0;
    left: 0;
    width: 100%;
    margin-top: 2rem;
    display: flex;
    justify-content: flex-end;
    height: 5.56rem;
    padding: 1.5rem 2.5rem;
    border-top: 1px solid $blue15;
    background-color: $white;
}

.version-item {
    font-size: 0.875rem;
    padding: 1rem 1.5rem;
    cursor: pointer;

    &:hover {
        background-color: $blue5;
    }

    &.active {
        color: $blue;
        background-color: $blue15;
    }

    .icon {
        position: relative;
        top: 3px;
    }
}


@import '~@/assets/scss/_palette.scss';
@import '~@/assets/scss/typographyV2';

.card-layout {
    padding: 1rem 1.5rem 1.5rem;
    border-radius: 0.5rem;
    width: 17.125rem;
    box-shadow: 0 0.25rem 0.5rem 0 rgba(51, 81, 149, 0.4);
}

.title {
    color: $white;
}

.content {
    color: $white;
}

.footer-label {
    color: $white;
}

.additional-info {
    color: $dirty-white;
    height: 2.125rem;
}

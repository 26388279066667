
@import '~@/assets/scss/mixins';
@import '~@/assets/scss/animations';
@import '~@/assets/scss/consistency';

$sidebar-width: 40rem; // AppAditionalSidebar.vue

.page {
    padding-top: 5rem; // AppHeaderV2.vue
}

.content {
    max-width: 100%;
    transition: max-width $medium-animation-time ease;
}

.collapsed {
    max-width: calc(100% - #{$sidebar-width});
}

.elipsis {
    @include lineClamp();
}

::v-deep {
    // Filter Tags
    .tag-element {
        padding: 0.125rem $spacing-xxs 0.125rem $spacing-xs;
    }
    .tag-element ~ .close-icon-wrap {
        padding: $spacing-xxs;
    }
    // Sidebar
    .aditional-sidebar {
        position: absolute;
        top: 0;
        overflow-y: auto;

        .mainContent,
        .content-c {
            height: max-content;
        }
    }
}


@import '~@/assets/scss/_palette.scss';
@import '~@/assets/scss/_layout.scss';
@import '~@/assets/scss/_typographyV2.scss';
@import '~@/assets/scss/editor-layout-v2';
@import '~@/assets/scss/typographyV2';
@import '~@/assets/scss/palette';
@import '~@/assets/scss/icons';
@import '~@/assets/scss/z-indexes';

.execution-type {
    margin-left: $spacing-s;
    width: 15rem;
}

.hierarchy-scope {
    width: calc(60% - 15.75rem);
}

.condition-wrapper {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
}

.border-bottom {
    border-bottom: solid 0.063rem rgba(51, 81, 149, 0.15);
}

.condition-label {
    text-transform: uppercase;
    font-size: 0.625rem;
    font-weight: bold;
    line-height: 2;
    color: $steel;
    margin-right: 0.75rem;
    border-bottom: solid 0.063rem rgba(51, 81, 149, 0.15);
    margin-bottom: 0.5rem;
}

.condition-options {
    min-width: 12rem;
    width: max-content;
}

.offer-options {
    min-width: 24rem;
    width: max-content;
}

.input-content-row {
    display: flex;
    border: 1px solid rgba(51, 81, 149, 0.15);
    padding: 0.75rem;
    margin-bottom: 0.5rem;
    position: relative;
    flex-direction: column;
    width: 60%;
}

.operator-multiselect {
    width: 30%;
}

.delete-btn {
    cursor: pointer;
    top: 1rem;
    height: 1rem;
    transform: translateY(calc(50% + 0.5rem));
    position: absolute;
    top: -0.5rem;
    right: -0.75rem;
}

.amount-container {
    display: flex;
    align-items: flex-end;
}

.amount-input {
    width: 5rem;
}

.symbol-p {
    color: #335195;
    font-size: 14px;
    font-weight: 500;
}

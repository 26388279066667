
@import '~@/assets/scss/palette';
@import '~@/assets/scss/icons';
@import '~@/assets/scss/mixins';
@import '~@/assets/scss/layout';
@import '~@/assets/scss/z-indexes';
@import '~@/assets/scss/typographyV2';

$icon-path: '~@/assets/icons/';

.new-template-editor {
    ::v-deep .form-content-wrapper {
        width: 90%;
        padding-top: 0;
    }
}

.page-content-wrapper {
    display: flex;
    flex-direction: row;
    padding: $spacing-l;
}

.template-editor {
    position: relative;

    .zoom-in-button,
    .zoom-out-button {
        position: absolute;
        z-index: $overlap-smth-z-index;
        left: 50%;
        cursor: pointer;
    }

    .zoom-out-button {
        top: 2.5rem;
    }

    .invalid-json-input {
        border-color: red !important;
    }

    .error-message {
        margin-top: $spacing-xxs;
        font-size: $text-xs;
        line-height: $heading-line-height;
        height: 1.25rem;
        color: $red;
    }
}

.template-editor-textarea {
    width: 100%;
    height: 55vh;

    border: 1px solid $gray5;
    border-radius: 0.5rem 0 0 0.5rem;

    ::v-deep .monaco-editor {
        background-color: transparent;

        .margin {
            background-color: transparent;
        }
    }
}


@import '~@/assets/scss/_consistency-spacing.scss';
@import '~@/assets/scss/typographyV2';

.small-gray-text {
    margin-bottom: 0.938rem;
    font-size: 0.813rem;
    color: $gray5;
}

.dnd {
    &-item {
        position: relative;
        display: flex;
        align-items: center;
        width: 100%;
        min-height: 2.5rem;
        padding: 0 3.5rem 0 1rem;
        border-radius: 0.125rem;
        border: solid 0.063rem $dirty-white;
        margin-bottom: $spacing-xxs;
        color: $gray90;

        &:hover {
            box-shadow: 0 0.125rem 0.25rem 0 $blue30;

            .name-wrapper {
                color: $gray60;
                font-weight: bold;
            }

            .handle {
                display: flex;
            }
        }
        .name-wrapper {
            cursor: pointer;
        }

        .number-wrapper {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 0;
            height: 0;
            border: 0.688 solid $gray5;
            border-radius: 1.375rem;
            margin-right: $spacing-m;

            .number {
                position: absolute;
                height: 1.125rem;
                background-color: transparent;
                color: $gray60;
            }

            &.empty {
                width: 1.375rem;
                height: 1.375rem;
                border: none;

                .number {
                    height: 0.5rem;
                    width: 0.5rem;
                    border: 0.25rem solid $gray5;
                    border-radius: 50%;
                }
            }
        }
    }
}


@import '~@/assets/scss/palette';
@import '~@/assets/scss/layout';
@import '~@/assets/scss/typographyV2';

.wrapper-container {
    background-color: $dirty-white;
    min-height: 100vh;
    height: initial !important;

    .banner {
        position: relative;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        min-height: 6.25rem;
        width: calc(100% - 3rem);
        margin: $spacing-m $spacing-xl;
        padding-left: $spacing-xxl;
        border-radius: 0.5rem;
        background-color: #d6dcea;

        &:after {
            content: '';
            position: absolute;
            bottom: 0;
            right: -2.5rem;
            margin-right: $spacing-xxl;
            /* Sizes in pixels to match svg picture*/
            width: 517px;
            height: 110px;
            background-image: url('../../../assets/images/home-illustration-1.svg');
            background-size: contain;
            background-repeat: no-repeat;
        }
    }

    .iframe-test {
        display: block;
        margin: 0 auto;
    }
}

/**
 * Colors defitions from styleguide
 * link: https://www.figma.com/file/guLrapQ5tJniFiI55RlRAs/Portal?type=design&node-id=12237%3A505715&t=sHE5jN4GOFpekZcv-1
 *
 * NOTICE: When creating new color definitions please
 *   add them to :export statement at the bottom of file.
 */

// generic colors
$black: #000;
$white: #FFF;

// Pallete
$gray-800: #1D1E23;
$gray-600: #4F525F;
$gray-300: #9BA3B4;
$gray-200: #DBE0EA;
$gray-150: #E5E9F1;
$gray-100: #F3F4F8;
$gray-50: #F7F8FB;
$gray-25: #FAFBFD;

$blue-800: #0A101E;
$blue-600: #202B54;
$blue-500: #294177;
$blue-400: #335195;
$blue-300: #8D9EC4;
$blue-200: #DEE3EE;

$yellow-800: #473009;
$yellow-600: #855A11;
$yellow-400: #FFAD20;
$yellow-200: #FFEAC3;
$yellow-100: #FFF2DB;
$yellow-50: #FFF8EC;

$red-800: #461719;
$red-600: #812B2E;
$red-400: #F85359;
$red-200: #FDCBCD;
$red-100: #FEE3E4;
$red-50: #FFF4F5;

$green-800: #0F3830;
$green-600: #1D6958;
$green-400: #37C9AA;
$green-200: #C6EDE5;
$green-100: #DFF6F1;
$green-50: #EDFBF8;

// Primary colors
$blue: $blue-400;
$yellow: $yellow-400;
$red: $red-400;
$green: $green-400;
$text-color: $gray-800;

// Exchange with utility classes or CSS custom properties
// once old color implementations have been removed from codebase.
:export {
    black: $black;
    white: $white;

    gray: {
        #{800}: $gray-800;
        #{600}: $gray-600;
        #{300}: $gray-300;
        #{200}: $gray-200;
        #{100}: $gray-100;
        #{50}: $gray-50;
        #{25}: $gray-25;
    }

    blue: {
        #{800}: $blue-800;
        #{600}: $blue-600;
        #{500}: $blue-500;
        #{400}: $blue-400;
        #{300}: $blue-300;
        #{200}: $blue-200;
    }

    yellow: {
        #{800}: $yellow-800;
        #{600}: $yellow-600;
        #{400}: $yellow-400;
        #{200}: $yellow-200;
        #{100}: $yellow-100;
        #{50}: $yellow-50;
    }

    red: {
        #{800}: $red-800;
        #{600}: $red-600;
        #{400}: $red-400;
        #{100}: $red-100;
        #{50}: $red-50;
    }

    green: {
        #{800}: $green-800;
        #{600}: $green-600;
        #{400}: $green-400;
        #{200}: $green-200;
        #{100}: $green-100;
        #{50}: $green-50;
    }
}


@import '~@/assets/scss/palette';
@import '~@/assets/scss/layout';
@import '~@/assets/scss/typographyV2';

.form {
    padding: 2.5rem;
}

h2 {
    margin-bottom: 2.5rem;
}

h3 {
    font-weight: $bold-font-weight;
    line-height: $label-line-height;
}

.error-message {
    color: $red;
}

.radios ::v-deep label {
    color: $black;
}

#app .reason ::v-deep :is(.multiselect, .multiselect__input, .multiselect__single) {
    font-size: $text-sm;
}

#app .reason ::v-deep .multiselect__tags {
    padding-left: $spacing-xs;
}

.form ::v-deep textarea {
    width: 100%;
    padding-inline: $spacing-xs;
    border-radius: 0.5rem;
    font-size: $text-sm;

    &::placeholder {
        font-style: italic;
        opacity: 1;
        font-size: inherit;
    }
}

@import 'consistency-colors';

// Typography defitions from styleguide (add link once designs finalized)

// Heading styles

// Heading XL
h1,
.heading-xl,
.heading-1 {
    font-size: 1.5rem;
    line-height: 2rem;
    font-weight: 600;
    color: $gray-600;
    margin-bottom: 0;
}

// Heading LG
h2,
.heading-lg,
.heading-2 {
    font-size: 1.25rem;
    line-height: 2rem;
    font-weight: 600;
    color: $gray-600;
    margin-bottom: 0;
}

// Heading MD
h3,
.heading-md,
.heading-3 {
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 600;
    color: $gray-600;
    margin-bottom: 0;
}

// Heading SM
h4,
.heading-sm,
.heading-4 {
    font-size: 0.875rem;
    line-height: 1rem;
    font-weight: 600;
    margin-bottom: 0;

    &.gray-600 {
        color: $gray-600;
    }

    &.blue-400 {
        color: $blue-400;
    }

    &.blue-800 {
        color: $blue-800;
    }

    &.yellow {
        color: $yellow-800;
    }

    &.red {
        color: $red-800;
    }

    &.green {
        color: $green-800;
    }

    &.gray-300 {
        color: $gray-300;
    }

    &.white {
        color: $white;
    }
}

// Heading XS
h5,
.heading-xs {
    font-size: 0.75rem;
    line-height: 1rem;
    font-weight: 600;
    margin-bottom: 0;

    &.blue {
        color: $blue-800;
    }

    &.blue-300 {
        color: $blue-300;
    }

    &.blue-400 {
        color: $blue-400;
    }

    &.yellow {
        color: $yellow-800;
    }

    &.red {
        color: $red-800;
    }

    &.green {
        color: $green-800;
    }

    &.gray {
        color: $gray-800;
    }

    &.uppercase {
        text-transform: uppercase;
    }
}

h6,
.heading-xs-uppercase {
    font-size: 0.75;
    line-height: 1rem;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 0;
}

// Body styles

.body-sm {
    font-size: 0.875rem;
    line-height: 1.5rem;
    font-weight: 400;

    &.italic {
        font-style: italic;
    }

    &.bold {
        font-weight: bold;
    }
}

.body-xs {
    font-size: 0.75rem;
    font-weight: normal;
    line-height: 1rem;

    &.gray {
        color: $gray-600;
    }

    &.red {
        color: $red-600;
    }

    &.bold {
        font-weight: bold;
    }
}

// Link styles

.link-sm {
    font-size: 0.875rem;
    line-height: 1.5rem;
    color: $blue-400;

    &:hover {
        color: $blue-800;
    }
}

.link-xs {
    font-size: 0.75rem;
    line-height: 1rem;
    font-weight: 600;
    color: $blue-400;
    text-decoration: underline;

    &:hover {
        opacity: 0.75;
    }
}


@import '~@/assets/scss/palette';
@import '~@/assets/scss/animations';
@import '~@/assets/scss/icons';
@import '~@/assets/scss/z-indexes';

$icon-path: '~@/assets/icons/';

.tooltip-content {
    font-size: 0.875rem;
    line-height: 1.5;
    color: $gray90;
    position: absolute;
    z-index: $hide-smth-z-index;
    opacity: 0;
    padding: 0.75rem 1.5rem 1rem;
    border-radius: 0.25rem;
    box-shadow: 0 0.25rem 0.5rem 0 $box-shadow-color;
    background-color: $white;
    transition: all $fast-animation-time ease-in-out;
    min-width: fit-content;

    &.top {
        bottom: calc(100% + 0.625rem);
        left: 50%;
    }

    &.bottom {
        top: calc(100% + 0.625rem);
        left: 50%;
    }

    &.left {
        top: 50%;
        right: calc(100% + 0.625rem);
    }

    &.right {
        top: 50%;
        left: calc(100% + 0.625rem);
    }
}

.tooltip-icon.hoverable:hover,
.tooltip-icon.show {
    cursor: help;

    .tooltip-content {
        opacity: 1;
        z-index: $tooltip-z-index;
    }
}

.questionmark {
    background-image: url($icon-path + $question-gray);
    height: 1.5rem;
    width: 1.5rem;

    &:hover {
        background-image: url($icon-path + $question-icon);
    }
}

.info-icon {
    width: 1rem;
    height: 1rem;
    content: url($icon-path + $info-grey);

    &:hover {
        content: url($icon-path + $info-blue);
    }
}

.lf-icon {
    background-image: url($icon-path + $logo-sign-small);
    filter: grayscale(100%);
    transition: filter 0.2s ease;
    height: 1rem;
    width: 1rem;

    &:hover {
        filter: none;
    }
}

.show-content {
    opacity: 1;
}

.width-fit-content {
    width: fit-content;
}


@import '~@/assets/scss/palette';
@import '~@/assets/scss/layout';
@import '~@/assets/scss/typography';

#localization-save-button {
    position: fixed;
    right: 4%;
    bottom: 4%;
}

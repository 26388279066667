
@import '~@/assets/scss/consistency-colors';

.action {
    margin-bottom: 1rem;
    border-radius: 0.5rem;
    padding: 0.3125rem 0.5rem;
    border: 1px solid $gray-200;
    outline: none;
}
.selector {
    border-top: 1px solid $gray-200;
    border-bottom: 1px solid $gray-200;
}
.lf-subtitle {
    // Override bottom margin on subtitle.
    // Doing this since our subtitle is inside a div, and we want the parent to have the margin instead.
    margin-bottom: 0; //todo comment
}

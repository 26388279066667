
@import '~@/assets/scss/palette';
@import '~@/assets/scss/layout';

.section-layout {
    margin: 0 $spacing-xl $spacing-xl $spacing-xl;
}

.table-title {
    margin-bottom: 0.25rem;
}
.modal-content {
    padding: $spacing-xxl;
    .body {
        margin-top: $spacing-xxl;
    }
}

.customer-operate-wrapper {
    margin-right: 2.25rem;
}

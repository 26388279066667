
@import '~@/assets/scss/consistency';

.sidebar-title {
    margin-bottom: $spacing-xxl;
}

.search-box {
    width: 15rem;
}

dl {
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: baseline;
    gap: 0.875rem $spacing-m;
    margin-top: $spacing-xl;
}


@import '~@/assets/scss/palette';

.footer-button {
    margin-right: 12px;
}

.entity-status {
    img {
        margin-right: 4px;
    }
}

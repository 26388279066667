
@import '~@/assets/scss/palette';

.card-container {
    margin: 0 1.5rem 0.625rem 1.5rem;
    padding: 0.625rem 1rem;
    border-radius: 0.5rem;
    background-color: rgba($color: $blue, $alpha: 0.15);

    .card-title {
        color: $blue;
        font-size: 0.75rem;
        font-weight: 600;
    }
    .card-list {
        .card-content {
            display: flex;
            flex-direction: row;
            font-size: 0.875rem;
            color: $gray90;
        }
    }
}

.footer-button {
    margin-right: 0.75rem;
}

.data-name {
    font-weight: bold;
}


@import '~@/assets/scss/consistency-typography';
@import '~@/assets/scss/palette';
@import '~@/assets/scss/z-indexes';

.search-box {
    width: 15rem;
    z-index: $two-z-index;
}

.heading-md {
    // remove after all component titles have consistency-colors
    color: $gray90;
}

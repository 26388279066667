
@import '~@/assets/scss/layout';
@import '~@/assets/scss/consistency-typography';

.message-history-title-wrapper {
    padding: $spacing-m $spacing-xl;

    .message-history-title-content {
        * {
            margin-right: $spacing-xs;
        }

        .search-inputs {
            width: 15rem;
        }
    }
}
.message-history-wrapper {
    padding: $spacing-m $spacing-xl;
}


@import '~@/assets/scss/palette';
@import '~@/assets/scss/typographyV2';
@import '~@/assets/scss/layout';
@import '~@/assets/scss/z-indexes';

.card-container {
    position: relative;
    width: 100%;
    height: 100%;
}

.button-section {
    display: flex;
    align-items: center;
}

.date-section {
    width: 5.3125rem;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.date-number {
    font-size: 2rem;
    color: $blue;
    font-weight: $medium-font-weight;
}

.date-month {
    color: $gray-blue;
    margin-top: -0.25rem;
}

.main-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.date-day-of-week {
    margin-bottom: -0.25rem;
}

.read-note-text {
    cursor: pointer;
    font-size: $text-xs;
    color: $blue;

    &:hover {
        text-decoration: underline;
    }
}

.note {
    font-size: $text-xs;
    width: 10.375rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.card-hovered-container {
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.date-and-time-tooltip {
    color: $gray-blue;

    margin-bottom: $spacing-s;
}

.note-tooltip-wrapper {
    width: 5rem;
}

.note-tooltip {
    width: 30rem;
    word-break: break-word;
}
.appointment-state {
    font-weight: bold;
}
.appointment-state-gray {
    color: $gray30;
}
.appointment-state-orange {
    color: $orange;
}
.appointment-state-blue {
    color: $blue40;
}
.appointment-state-green {
    color: $green40;
}
.appointment-state-red {
    color: $red30;
}
.appointment-state-black {
    color: $black;
}

.appointments-change-appointment-section {
    margin-left: $spacing-xxxl;
}
.appointment-label {
    margin: $spacing-s $spacing-xxxl $spacing-xxs;
}

.appointment-date-picker {
    width: 18rem;
    margin-left: $spacing-xxxl;
}

.appointment-dropdown {
    width: 18rem;
    margin: $spacing-s $spacing-xxxl;
}

.appointment-note-textarea {
    width: 36rem;
    margin-left: $spacing-xxxl;

    .additional-label {
        font-style: italic;
        color: $gray60;
    }
}

.appointment-notify-toggle {
    margin: $spacing-s $spacing-xxxl 0;
}


@import '~@/assets/scss/editor-layout-v2';
@import '~@/assets/scss/typographyV2';
@import '~@/assets/scss/palette';
@import '~@/assets/scss/animations';
@import '~@/assets/scss/icons';
@import '~@/assets/scss/z-indexes';

.version-item {
    font-size: 0.875rem;
    padding: 1rem 1.5rem;
    cursor: pointer;
    position: relative;

    &:hover {
        background-color: $blue5;

        .version-details {
            display: block;
        }
    }

    &.active {
        color: $blue;
        background-color: $blue15;
    }

    .icon {
        position: relative;
        top: 3px;
    }
}

.version-details {
    position: absolute;
    top: 2rem;
    transform: translateY(-10%);
    display: none;
    width: 21.25rem;
    height: auto;
    color: $gray60;
    transition: $fast-animation-time;
    z-index: $overlap-smth-z-index;

    .info {
        background-color: $gray5;
        padding: 0.75rem 1.25rem;
        font-size: $text-sm;

        .property-name {
            font-weight: $medium-font-weight;
        }
    }
}

.tags-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}


@import '~@/assets/scss/consistency-colors';
@import '~@/assets/scss/consistency-spacing';
@import '~@/assets/scss/consistency-typography';

.banner {
    padding: $spacing-m $spacing-xl;
    color: $yellow-800;
    background-color: $yellow-100;
}

.icon {
    flex: 0 0 max-content;
    color: $yellow-400 !important;
}

.link-xs {
    cursor: pointer;
    color: inherit;
}

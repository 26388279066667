
@import '~@/assets/scss/palette';
@import '~@/assets/scss/_typographyV2.scss';
@import '~@/assets/scss/_layout';

.login-container {
    display: flex;
    box-sizing: border-box;
    width: 100%;
    overflow: auto;
    // we have attached margin: 8px into body
    height: 100vh;
    justify-content: center;
    align-items: center;
    background: #fbfbfc;
    padding: 0 7.5rem;

    @media screen and (prefers-color-scheme: dark) {
        background: #293458;
    }

    @media screen and (max-width: 1024px) {
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        padding: 5rem 1rem 1rem 1rem;
    }
}

.form-container {
    height: 100%;
    width: 50%;
    display: flex;
    justify-content: center;
    flex-direction: column;

    @media screen and (max-width: 680px) {
        width: 100%;
    }
}

.image-container {
    height: 100vh;
    box-sizing: border-box;
    width: 50%;
    max-width: 704px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    @media screen and (max-width: 1024px) {
        max-width: 100%;
        width: 100%;
        margin-top: 3rem;
    }
}

.lf-image {
    height: 100%;
    width: 100%;
    min-height: 150px;
    background: url('../../../assets/images/login/illustration-login.svg') no-repeat center;
    background-size: contain;

    @media screen and (prefers-color-scheme: light) {
        background: url('../../../assets/images/login/illustration-login.svg') no-repeat center;
        background-size: contain;
    }
}

.lf-logo {
    width: 252.3px;
    height: 44.2px;
    margin-bottom: 172px;

    @media screen and (max-width: 680px) {
        width: 182.3px;
        height: 44.2px;
        margin-bottom: 69px;
    }
}

@media screen and (prefers-color-scheme: dark) {
    .lf-logo {
        background: url('../../../assets/images/login/logo-color-1.svg') no-repeat center;
        background-size: contain;
    }
}

@media screen and (prefers-color-scheme: light) {
    .lf-logo {
        background: url('../../../assets/images/login/logo-color-2.svg') no-repeat center;
        background-size: contain;
    }
}

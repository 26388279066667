
@import '~@/assets/scss/palette';
@import '~@/assets/scss/icons';

$icon-path: '~@/assets/icons/';

.sort-icon-wrap {
    height: 32px;
    width: 32px;
    border-radius: 50%;
    cursor: pointer;

    &:hover {
        background: rgba($color: $blue, $alpha: 0.15);

        .sort-icon {
            content: url($icon-path + $icon-sort-no-sorting-hover);
        }

        .asc {
            content: url($icon-path + $icon-sort-up-hover);
        }

        .desc {
            content: url($icon-path + $icon-sort-down-hover);
        }
    }
}

.sort-icon {
    max-height: 16px;
    max-width: 16px;

    content: url($icon-path + $icon-sort-no-sorting-standard);
}

.asc {
    content: url($icon-path + $icon-sort-up-standard);
}

.desc {
    content: url($icon-path + $icon-sort-down-standard);
}

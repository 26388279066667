
@import '~@/assets/scss/consistency-spacing';

.edit-status {
    min-height: 24rem;
    padding: $spacing-xxl $spacing-xxxl 1.75rem;
}

.edit-status .lf-title {
    margin-bottom: 2.25rem;
}


@import '~@/assets/scss/_consistency';

.form-content {
    padding: $spacing-m $spacing-xxxl;
}

.error-message {
    color: $red;
}

#app .reason ::v-deep .multiselect__tags {
    padding-left: $spacing-xs;
}

.form-content ::v-deep textarea {
    width: 100%;
    padding-inline: $spacing-xs;
    border-radius: 0.5rem;

    &::placeholder {
        font-style: italic;
        opacity: 1;
        font-size: inherit;
    }
}

@import '~@/assets/scss/animations';
@import '~@/assets/scss/consistency';
@import '~@/assets/scss/z-indexes';

$row-height: 3rem;

@keyframes rowHover {
    to {
        opacity: 1;
        background-color: $gray-100;
    }
}

/**
 * Incorporates new table desgin
 * figma: https: //www.figma.com/design/C5c2AVf2nG121u6p8oafJq/Bulk-Upload?node-id=0-1&node-type=canvas&t=vcHQsMtEY3KHaeTp-0
 */
@mixin appTableNewDesign {
    .empty-table-row {
        border-bottom: 0;
    }

    table> :not(.table-content) {
        flex: 0 0 auto;
    }

    .table-content:last-of-type .entity-row {
        border-radius: 0;
    }

    .select-filters {
        top: 50%;
        transform: translateY(-50%);
    }

    .table-header,
    .entity-row {
        border-bottom-color: $gray-150;

        >* {
            line-height: $row-height;
        }
    }

    .table-header-cell,
    .table-cell {
        padding-left: $spacing-s !important;

        &:first-child {
            padding-left: $spacing-xxl !important;
        }
    }

    // Table header
    .table-header {
        position: sticky;
        top: 0;
        border-top: 0;
        z-index: $two-z-index;
    }

    .draggable-wrap {
        align-items: center;
    }

    .table-header-cell {
        position: relative;
        color: $gray-800;
        text-transform: none;
        border: 0 !important;
    }

    .table-header-cell .column-title {
        width: 100%;
    }

    // Show sort icon only on hover or sorting is applied
    .table-header-cell .sort-icon-wrap {
        padding-block: 0;
        background-color: $blue-200;
        opacity: 0;
        transform: opacity $fast-animation-time;
    }

    .sort-icon-wrap:has(.asc, .desc),
    .table-header-cell:hover .sort-icon-wrap {
        opacity: 1;
    }

    // Row actions
    .row-state-controls {
        height: $row-height;
        min-width: 1.5rem;
    }

    .entity-row.default-hover:hover {
        background-color: $gray-100;
    }

    .icon-button-container {
        flex-shrink: 0;
    }

    .hover-row-buttons .transitioning-area {
        display: none !important; // sync with <tr> transition
    }

    .hover-row-buttons .button-wrapper {
        align-items: center;
        padding-right: $spacing-l;
        border-bottom-color: $gray-150;
        opacity: 0;
        background-color: transparent;
        animation: 0.25s rowHover forwards; // sync with <tr> transition
    }
}

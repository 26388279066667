
@import '~@/assets/scss/consistency';

.row-header {
    display: flex;
    align-items: center;
    height: 3rem;
    position: relative;
    transition: background 0.2s ease-in-out;
    border-radius: 0.5rem;

    &:hover {
        background: $gray-50;
    }

    .vertical-svg {
        position: relative;
        top: 0;
        height: 3rem;
        width: 1.625rem;
        margin-left: 2rem;

        line {
            stroke: $blue-300;
            stroke-width: 1px;
            shape-rendering: crispEdges;
        }
    }

    .dropdown-arrow {
        margin: 0 0.25rem;
        cursor: pointer;
        transition: transform 0.2s ease-in-out;
        transform: translateY(0.1rem);

        &.rotate90 {
            transform: rotate(90deg) translateX(0.1rem);
        }
    }

    .content {
        display: flex;
        align-items: baseline;
        margin-left: 0.25rem;
        max-height: 3rem;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        .arrow-margin {
            margin-left: 1.5rem;
        }
    }

    &.row-checkbox {
        height: 2rem;

        .vertical-svg {
            height: 2rem;
        }

        .content {
            margin-left: 1.75rem;
            margin-top: -0.25rem;
            align-items: center;

            .label {
                padding-top: 0.25rem;
                opacity: 0;
                transition: opacity 0.2s ease-in-out;
            }
        }

        &:hover .content .label {
            opacity: 1;
        }
    }
}

.toggle-container {
    margin-right: 0.5rem;

    &.depth-1 ::v-deep span.icon[data-v-1ffcaf0a]:before {
        margin-top: -0.5rem;
    }

    &.depth-2 ::v-deep span.icon[data-v-1ffcaf0a]:before {
        margin-top: -0.45rem;
    }

    &.depth-3 ::v-deep span.icon[data-v-1ffcaf0a]:before {
        margin-top: -0.4rem;
    }
}

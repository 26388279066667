
@import 'assets/scss/bootstrap/custom-bootstrap';
@import 'assets/scss/base';
@import 'assets/scss/external-components';
@import 'vue-json-pretty/lib/styles';

#app {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: $white;

    height: 100%;
    width: 100%;

    .unauthorized-container {
        height: 100%;
        width: 100%;
    }

    .authorized-container {
        height: 100%;
        width: 100%;
    }
}


@import '../../../assets/scss/editor-layout-v2';

.position-optional-row {
    margin-top: -10px;
}

.default-prize-height {
    height: 16px;
}

.redeeming-prize-toggle-position {
    top: 3px;
    position: relative;
}

.content-height {
    height: 36px;
}

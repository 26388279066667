
@import '~@/assets/scss/sidebar.scss';
@import '~@/assets/scss/z-indexes';

.auth-container {
    height: 100%;
    display: flex;
    flex-direction: row;
}

.sidebar-container {
    z-index: $page-layout-item-z-index;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
}

.page-content {
    position: relative;
    width: 100%;
    overflow-x: hidden;
    margin-left: $sidebarFullWidth;
    transition: margin-left 0.5s;

    &.compact-sidebar {
        margin-left: $sidebarCompactWidth;
    }

    &.disable-sidebar {
        margin-left: 0;
    }
}

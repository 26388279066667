
@import '~@/assets/scss/palette';

.tags-input::v-deep {
    &.vue-tags-input {
        max-width: 100%;
        border-radius: 0.5rem;

        .ti-input {
            border-color: $gray5;
            border-radius: 0.5rem;
            padding: 0.1875rem 0.25rem;
        }

        .ti-new-tag-input {
            font-size: 0.75rem;
            line-height: 0.875rem;

            &.ti-duplicate {
                text-decoration: line-through;
            }

            &::placeholder {
                font-style: italic;
                color: $gray30;
                font-size: 0.875rem;
            }
        }

        .ti-tag {
            font-size: 0.75rem;
            line-height: 0.875rem;
            border-radius: 1rem;
            background-color: $blue15;
            color: $blue;
            font-weight: 700;

            &:focus {
                background: $blue;
                color: $white;
            }
        }

        .ti-deletion-mark {
            color: $white;
        }

        &.ti-focus {
            .ti-input {
                border-color: $blue;
            }
        }

        &.invalid {
            .ti-input {
                border-color: $red;
            }
        }
    }
}

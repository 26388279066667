
@import '~@/assets/scss/palette';
@import '~@/assets/scss/base';
@import '~@/assets/scss/z-indexes';

.wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: $modal-z-index;
    background: rgba($color: $navy, $alpha: 0.9);
}

.content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 8px;
    background-color: $white;
    width: 100%;
    overflow-y: scroll;
}


@import '~@/assets/scss/palette';

.app-card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    border-radius: 0.5rem;
    background-color: $white;
    padding: 1.5rem 2rem;
}

.chart {
    width: 70px;
    height: 70px;
    margin-left: 1.5rem;
}

.icon {
    margin-bottom: 0.25rem;
}

.count {
    font-size: 1.375rem;
    font-weight: 600;
    color: $navy;
    margin-bottom: 0.25rem;
}

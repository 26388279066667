
@import '~@/assets/scss/consistency-colors';
@import '~@/assets/scss/consistency-typography';

.sidebar-header {
    color: rgba($blue, 0.5);
}

.entity-view {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.entity-row {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.entity-content {
    flex-grow: 1;
}

.entity-actions {
    flex-shrink: 0;
    margin-top: 1rem;
}

.modal-row {
    margin-bottom: 0.875rem;
}

.execution-link {
    font-weight: bold;
}

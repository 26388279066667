
@import '~@/assets/scss/consistency';

.list-box {
    border: 1px solid $gray-200;
    border-radius: $spacing-xs;
    background: $white;
}
.item {
    border: 1px solid $gray-200;
    border-radius: $spacing-xs;
    position: relative;
}
.combinator-line {
    height: 100%;
    border-left: 1px solid $gray-200;
    position: relative;
    &:before,
    &:after {
        content: '';
        position: absolute;
        width: 1rem;
        left: 0;
        height: 1px;
        background: $gray-200;
    }
    &:after {
        bottom: 0;
    }
}
.combinator {
    color: $blue-400;
    position: absolute;
    bottom: -1rem;
    background: $white;
    height: fit-content;
    &-and {
        left: -2.3rem;
    }
    &-or {
        left: -2.1rem;
    }
}


@import '~@/assets/scss/consistency';

.form-element {
    width: 100%;
    max-width: 46rem;
}

.dataflows {
    display: flex;
    flex-direction: column;
}

::v-deep .label-wrapper {
    padding-bottom: $spacing-xs !important;
}

::v-deep {
    .label {
        margin-bottom: 0 !important;
    }

    .label,
    .label-text {
        color: $gray-600;
    }
    .editor-content-wrapper {
        flex-grow: 1;
        overflow: hidden;
    }
    .editor-content-wrapper .form-content-wrapper {
        display: block !important;
        padding-inline: $spacing-xxl !important;
        overflow-y: auto !important;
    }
    .editor-controls-wrapper {
        justify-content: flex-end !important;
    }

    input:not(.error),
    .multiselect__tags:not(.multiselect.error .multiselect__tags),
    .multiselect__single {
        border-color: $gray-150 !important;
    }

    input::placeholder,
    .multiselect__placeholder {
        display: block !important;
        font-style: normal !important;
        font-size: 0.75rem !important;
        line-height: 1rem !important;
    }

    input:not(.multiselect__input),
    .multiselect__option,
    .multiselect__tags {
        padding: 0.5rem !important;
    }

    .multiselect__input,
    .multiselect__single {
        display: block !important;
        min-height: auto !important;
        height: 1rem !important;
    }

    input,
    .label,
    .multiselect__element,
    .multiselect__single,
    .multiselect__option {
        font-size: 0.75rem !important;
        line-height: 1rem !important;
    }

    .multiselect__option {
        padding-left: $spacing-xs !important;
        min-height: auto !important;
    }
}


@import '~@/assets/scss/consistency-colors';

.additional-info {
    color: $blue;
    height: 2.125rem;
}

.title {
    color: $blue;
}

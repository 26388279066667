
@import '~@/assets/scss/palette';
@import '~@/assets/scss/layout';

.search-box {
    width: 15rem;
}

.background-color {
    background-color: $dirty-white;
}

.section-layout {
    margin: 0 1.5rem 1.5rem 1.5rem;
}

.section-subtitle {
    font-size: 0.75rem;
    font-weight: 600;
    color: $gray90;
}

.section-title {
    font-size: 1rem;
    font-weight: 600;
    color: $gray90;
}

.table-title {
    font-size: 1rem;
    font-weight: 600;
}

.link:hover {
    color: $blue;
    text-decoration: underline;
}


@import '~@/assets/scss/_consistency';

.address-inputs {
    display: flex;
    flex-direction: column;
    gap: $spacing-s;

    .address-row {
        gap: $spacing-s;
        display: flex;
        .col {
            flex: 1;
        }
    }
}

.coverage-info {
    margin-left: $spacing-xl;
    padding: $spacing-m $spacing-m $spacing-m $spacing-xl;
    border-left: 1px solid $gray-200;

    .coverage-card {
        margin-bottom: $spacing-m;

        .heading-xs {
            margin-bottom: $spacing-xxs;
        }
    }
}

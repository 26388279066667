
@import '~@/assets/scss/palette';
@import '~@/assets/scss/base';
@import '~@/assets/scss/icons';
@import '~@/assets/scss/animations';
@import '~@/assets/scss/typographyV2';
@import '~@/assets/scss/z-indexes';
@import '~@/assets/scss/layout';

$icon-path: '~@/assets/icons/';

.table-wrapper {
    margin-top: 1rem;
    border-radius: 0.5rem;
    padding: 0.3125rem 0.5rem;
    border: 1px solid $gray5;
    outline: none;
}

table {
    width: 100%;
    table-layout: fixed;
}

th,
td {
    padding-right: 1.5rem;
    vertical-align: top;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

tr {
    // Lines between rows
    border-bottom: 1px solid $dirty-white;
    color: $gray90;
    background-color: $white;
    overflow: visible;
    height: 40px;
}

.row-actions {
    width: 2.5rem;
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
}

.tooltip-info {
    white-space: pre;
    font-size: 0.75rem;
    font-weight: normal;
    text-transform: none;
    line-height: normal;
    color: $gray90;
    min-width: 10rem;
}

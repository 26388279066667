
@import '~@/assets/scss/consistency';

.row-title {
    flex: 0 0 7.875rem;
}

.row-value {
    flex: 1 1 auto;
}

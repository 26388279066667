
@import '~@/assets/scss/editor-layout-v2';
@import '~@/assets/scss/typographyV2';
@import '~@/assets/scss/palette';
@import '~@/assets/scss/icons';
@import '~@/assets/scss/z-indexes';

.action-wrapper {
    margin-top: 0.75rem;
    padding-top: 0.75rem;
    border-top: solid 0.1rem $blue15;
    border-bottom: solid 0.1rem $blue15;
}

.action-label {
    text-transform: uppercase;
    font-size: 0.625rem;
    font-weight: bold;
    line-height: 2;
    color: $steel;
    margin-right: 0.75rem;
}


@import '~@/assets/scss/_palette.scss';
@import '~@/assets/scss/_typographyV2';
.proxy-section {
    padding: 2rem 4rem;
    height: 100%;
    position: relative;
    overflow: scroll;
}

.button-wrapper {
    padding-top: 2rem;
    display: flex;
    bottom: 0;
    right: 0;
}


@import '~@/assets/scss/palette';
@import '~@/assets/scss/icons';

.section-title {
    font-size: 1rem;
    font-weight: 600;
    color: $gray90;
}

.section-margin {
    margin-bottom: 2.8125rem;
}

.status-label {
    font-size: 0.875rem;
    font-style: italic;
    color: $gray30;
    margin-left: 1.5rem;
}

.is-fraud-label,
.is-block-port-out-label,
.is-block-sim-swap-label {
    width: 30%;
}

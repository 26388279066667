
@import '~@/assets/scss/palette';
@import '~@/assets/scss/icons';

.section-content {
    padding: 12px 0 12px 48px;
}

.inner-layout {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 8px 0;

    .label {
        flex: 0 1 200px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 0.875rem;
        color: $gray60;

        &.disabled {
            color: $gray30;
        }
    }
}

.threshold-group,
.threshold {
    position: relative;

    &:hover {
        > .inner-layout > .delete-btn {
            opacity: 1;
        }
    }
}

.threshold {
    background-color: $dirty-white;
}

.text-label {
    font-size: 0.875rem;
    line-height: 26px;
    color: $gray60;
}

.delete-btn {
    cursor: pointer;
    position: absolute;
    top: 16px;
    right: 26px;
    transform: translateY(-50%);
    opacity: 0;
}

.notifications-add-wrapper {
    display: flex;
    align-items: flex-end;
}

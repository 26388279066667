
@import '~@/assets/scss/z-indexes';
@import '~@/assets/scss/layout';

.table-column {
    padding-right: 2px;
    height: calc(100vh - #{$header-height});
}

.overview-column {
    padding-left: 0;
}

.wrapper-container {
    padding: 0;
}

.user-guide-icon-wrapper {
    position: absolute;
    bottom: 1.5rem;
    right: 1.5rem;
    z-index: $tooltip-z-index;
}

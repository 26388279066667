
@import '~@/assets/scss/_palette.scss';

.section-content {
    font-size: 0.75rem;
    font-weight: 600;
    margin-bottom: 1rem;
    margin-right: 1rem;
    color: $gray90;
}


@import '~@/assets/scss/palette';

.otp-wrapper {
    margin-top: 3rem;
    text-align: center;
}

.digit-box {
    height: 4rem;
    width: 2rem;
    border: none;
    border-bottom: 1px solid $gray30;
    margin: 0.5rem;
    padding: 0.5rem;
    font-weight: 700;
    font-size: 1.375rem;
}

.digit-box:focus {
    border-bottom: 2px solid $gray30;
}

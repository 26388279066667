
@import '~@/assets/scss/palette';
@import '~@/assets/scss/z-indexes';

.filter-component {
    height: 2.5rem;
    width: fit-content;
    position: absolute;
    right: 2rem;
    top: 3.75rem;
    box-shadow: 0 2px 6px 0 rgba(51, 81, 149, 0.3);
    border-radius: 0.5rem;
    background-color: $white;
    z-index: $header-dropdowns-z-index;
}

.add-button {
    color: $blue;
    cursor: pointer;
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 1.625rem;

    &.disabled {
        cursor: default;
        color: $gray10;
    }
}

.date-min-width {
    min-width: 15.5rem;
}

.input-min-width {
    min-width: 10rem;
}

.filter-date-time-picker {
    ::v-deep.datepicker-wrapper {
        width: 12rem;
    }

    ::v-deep.date-time-picker {
        width: 7rem;
    }
}

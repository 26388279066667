@import '~@/assets/scss/consistency-spacing';
@import '~@/assets/scss/consistency-colors';

.filters-tags {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: $spacing-xs;
}

.close-filter {
    cursor: pointer;
}

.clear-all-filters {
    height: 1.625rem;
    color: $blue-400;
    line-height: 1.625rem;
    font-size: 0.75rem;
    font-weight: 600;
    cursor: pointer;
}


@import '~@/assets/scss/palette';
@import '~@/assets/scss/icons';
@import '~@/assets/scss/animations';
@import '~@/assets/scss/z-indexes';
@import '~@/assets/scss/base';

$icon-path: '~@/assets/icons/';

.table-scroll-wrapper {
    position: relative;
    overflow: auto;

    .table-wrapper {
        &.min-width-table {
            min-height: 8rem;
        }

        &.show-border-fade::after {
            content: '';
            position: sticky;
            top: 0;
            right: 0;
            width: 50px;
            height: calc(100% - 1.875rem);
            background: linear-gradient(to left, $white 75%, transparent);
        }

        &.has-entities {
            &.show-border-fade::after {
                height: calc(100% - 3.125rem);
            }

            .table-content-container {
                height: 100%;
                overflow: auto;
                padding-bottom: 1.25rem;
            }
        }

        .scroll-content-wrapper {
            height: 100%;

            .scroll-content {
                display: flex;
                flex-direction: column;

                .row {
                    height: 48px;
                    padding-left: 50px;
                    position: relative;
                    border-top: 1px solid #f1f1f1;
                    border-bottom: 1px solid #f1f1f1;

                    &.root {
                        &::before {
                            content: url($icon-path + $arrow-up-gray);
                            position: absolute;
                            left: 17px;
                            top: 50%;
                            transform: translateY(-50%) rotate(180deg);
                            transition: transform $fast-animation-time;
                        }

                        &.opened {
                            .label {
                                padding-left: 24px !important;
                            }
                            &:before {
                                transform: translateY(-50%);
                            }
                        }
                    }
                }

                .empty-table-row {
                    text-align: center;
                    background-color: $white;
                    border-bottom: 1px solid $dirty-white;
                    padding-top: 3rem;
                    padding-bottom: 3rem;

                    .empty-table-row-text {
                        opacity: 0.5;
                        font-size: 1rem;
                        color: $blue;
                        width: fit-content;
                        font-weight: 600;
                    }

                    .empty-table-row-icon {
                        margin-right: 2rem;
                        width: 100px;
                        height: 100px;
                    }
                }
            }

            &::-webkit-scrollbar {
                height: 30px;
                width: 30px;
            }

            &::-webkit-scrollbar-track {
                border-radius: 100px;
                box-shadow: inset 0 0 4px 4px #eaedf0;
                border: solid 13px transparent;
            }

            &::-webkit-scrollbar-thumb {
                border-radius: 100px;
                box-shadow: inset 0 0 4px 4px #cdd0d7;
                border: solid 13px transparent;
            }

            &::-webkit-scrollbar-corner {
                background-color: transparent;
            }
        }
    }
}

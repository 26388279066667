
.platform-label {
    margin-right: 1rem;
}
.radio-item {
    margin-bottom: 0.25rem;
}
.label {
    margin-left: 0.15rem;
}
.textSize {
    font-size: 0.875rem;
}


@import '~@/assets/scss/consistency';

.cards-container {
    display: flex;
    justify-content: flex-start;
}

.no-active-appointments-placeholder {
    width: 17.1875rem;
    height: 5rem;
    margin-bottom: 0;
    border-radius: 0.5rem;
    box-shadow: 0 0.125rem 0.375rem 0 rgb(51 81 149 / 30%);
    background-color: $white;

    display: flex;
    justify-content: center;
    align-items: center;

    color: $gray-300;
}

.appointment-section-content {
    padding-bottom: $spacing-xxxl;
    padding-left: $spacing-xs;
}
.appointment-modal-content {
    // for dropdown to not cause overlap
    min-height: 150px;
}

.appointments-change-appointment-section {
    margin-left: $spacing-xxxl;
}
.appointment-label {
    margin: $spacing-s $spacing-xxxl $spacing-xxs;
}

.appointment-date-picker {
    width: 18rem;
    margin-left: $spacing-xxxl;
}

.appointment-dropdown {
    width: 18rem;
    margin: $spacing-s $spacing-xxxl;
}

.appointment-note-textarea {
    width: 36rem;
    margin-left: $spacing-xxxl;

    .additional-label {
        font-style: italic;
        color: $gray-600;
    }
}

.appointment-notify-toggle {
    margin: $spacing-s $spacing-xxxl 0;
}

.installation-appointment-history {
    width: 24rem !important;
}

.empty-list {
    opacity: 0.5;
    font-size: 1rem;
    color: $blue;
    width: fit-content;
    font-weight: 600;
}


@import '~@/assets/scss/consistency-spacing';
@import '~@/assets/scss/consistency-typography';
@import '~@/assets/scss/_typographyV2';

.error-message {
    padding-top: $spacing-xxs;
    font-size: $text-base-size;
    line-height: $spacing-l;
    color: $red;
}

.grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;

    .left {
        display: flex;
        justify-content: flex-start;
    }

    .right {
        display: flex;
        justify-content: flex-end;
    }
}


@import '~@/assets/scss/mixins';
@import '~@/assets/scss/consistency-typography';
@import '~@/assets/scss/consistency-colors';
@import '~@/assets/scss/consistency-spacing';

.usage {
    margin-bottom: $spacing-m;
    padding: $spacing-m;
    border-radius: 0.5rem;
    background-color: $white;

    @media (min-width: $large-breakpoints) {
        max-width: 50%;
    }
}

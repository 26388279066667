
@import '~@/assets/scss/animations';
@import '~@/assets/scss/palette';

.table-title {
    font-size: 1rem;
    font-weight: 600;
}

.search-box {
    width: 15rem;
}

.link {
    cursor: pointer;
    transition: color $fast-animation-time;
}

.link:hover {
    color: $blue;
    text-decoration: underline;
}

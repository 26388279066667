
@import '~@/assets/scss/palette';
@import '~@/assets/scss/typographyV2.scss';
@import '~@/assets/scss/layout';

h1 {
    margin: 0;
}

.lf-welcome {
    font-family: $font-primary;
    font-size: $text-xxl;
    font-weight: $bold-font-weight;
    line-height: $normal-line-height;
    letter-spacing: normal;
    color: $blue;
    display: flex;

    @media screen and (prefers-color-scheme: dark) {
        color: #fff;
    }

    @media screen and (max-width: 1024px) {
        font-size: 2rem;
    }
}

.lf-subtitle-blue {
    display: flex;
    margin-left: 0.5rem;

    @media screen and (prefers-color-scheme: dark) {
        color: #fff;
    }
}

.form-inputs {
    padding-bottom: $spacing-m;
    max-width: 400px;
    width: 100%;

    @media screen and (max-width: 1024px) {
        max-width: 100%;
    }
}

.heading {
    margin-bottom: 2rem;
    @media screen and (max-width: 680px) {
        text-align: center;
    }
}

.login-form {
    display: flex;
    flex-direction: column;
    width: 100%;

    @media screen and (max-width: 680px) {
        justify-content: center;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

.strategy-list {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: $spacing-xl;
}

.login-button {
    margin-top: 10px;
    width: 100%;
    margin-bottom: 57px;
}

.auth-button {
    width: 100%;
}
.auth-button:not(:first-child) {
    margin-left: 1rem;
}

.separator {
    display: flex;
    align-items: center;
}

.separator h2 {
    padding: 0 2rem; /* creates the space */
}

.separator .line {
    flex: 1;
    height: 1px;
    background-color: $gray5;
}

.text-button {
    display: flex;
    color: $white;
    text-decoration: underline;
    cursor: pointer;

    &.blue {
        color: $blueDark20;
    }
}

.log-in-inline-text {
    width: 82px;
    height: 18px;
    margin: 0 25px 0 24px;
    font-family: 'Open Sans';
    font-size: 13px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #aeaeae;
}

.image-container {
    height: 100vh;
    box-sizing: border-box;
    width: 50%;
    max-width: 704px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    @media screen and (max-width: 1024px) {
        max-width: 100%;
        width: 100%;
        margin-top: 3rem;
    }
}

.lf-image {
    height: 100%;
    width: 100%;
    min-height: 150px;
    background: url('../../../assets/images/login/illustration-login.svg') no-repeat center;
    background-size: contain;

    @media screen and (prefers-color-scheme: light) {
        background: url('../../../assets/images/login/illustration-login.svg') no-repeat center;
        background-size: contain;
    }
}

.lf-logo {
    width: 252.3px;
    height: 44.2px;
    margin-bottom: 172px;

    @media screen and (max-width: 680px) {
        width: 182.3px;
        height: 44.2px;
        margin-bottom: 69px;
    }
}

@media screen and (prefers-color-scheme: dark) {
    .lf-logo {
        background: url('../../../assets/images/login/logo-color-1.svg') no-repeat center;
        background-size: contain;
    }
}

@media screen and (prefers-color-scheme: light) {
    .lf-logo {
        background: url('../../../assets/images/login/logo-color-2.svg') no-repeat center;
        background-size: contain;
    }
}

.two-factor-auth {
    max-width: 400px;
    width: 100%;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 1024px) {
        max-width: 100%;
        margin: 0 auto;
    }
}

.digit-input {
    display: flex;
    justify-content: space-between;
}

.single-digit-box {
    input {
        width: 3.5rem;
        height: 3.5rem;
        border-radius: 0.5rem;
        border: solid 1px $gray5;
        background-color: $white;
        text-align: center;
        font-size: 1.75rem;
        color: $blue;
    }
}

.auth-strategy {
    max-width: 400px;
    width: 100%;

    @media screen and (max-width: 1024px) {
        max-width: 100%;
        margin: 0 auto;
    }
}

.button-wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 400px;

    @media screen and (max-width: 1024px) {
        width: 100%;
        max-width: 100%;
    }
}

.input-separator {
    margin-right: 13px;
    margin-bottom: 44px;
}


@import '~@/assets/scss/consistency-typography';

.modal-title {
    font-size: 1.375rem;
    font-weight: 600;
    margin-bottom: 2.1875rem;
}

.modal-row {
    margin-bottom: 0.875rem;
    font-size: 0.875rem;
}

.row-title {
    font-weight: 600;
    flex: 0 0 7.875rem;
}

.row-value {
    flex: 1 1 auto;
}

.search-query-multiselect {
    min-width: 20rem;
}

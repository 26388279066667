
@import '~@/assets/scss/palette';
@import '~@/assets/scss/layout';
@import '~@/assets/scss/typographyV2';

.banner {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    min-height: 6.25rem;
    width: calc(100% - 3rem);
    margin: $spacing-m $spacing-xl;
    padding-left: $spacing-xxl;
    border-radius: 0.5rem;
    background-color: #d6dcea;

    &:after {
        content: '';
        position: absolute;
        bottom: 0;
        right: -2.5rem;
        margin-right: $spacing-xxl;
        /* Sizes in pixels to match svg picture*/
        width: 517px;
        height: 110px;
        background-image: url('../../../assets/images/home-illustration-1.svg');
        background-size: contain;
        background-repeat: no-repeat;
    }
}

.stats-section {
    display: flex;
    padding: $spacing-s;
    flex-wrap: wrap;

    .year-to-date-users-card {
        margin: $spacing-s;
        padding: $spacing-m $spacing-xl;
        width: 17.25rem;
        height: 10rem;
        border-radius: 0.5rem;
        background-color: $blue;
        box-shadow: 0 0.25rem 0.5rem 0 rgb(51 81 149 / 40%);

        .label {
            color: $white;
        }

        .users-number {
            color: $white;
            margin-bottom: $spacing-xs;
        }

        .description {
            color: $gray-blue;
        }
    }

    .active-users-block {
        display: flex;
        flex-direction: column;
        margin: $spacing-s 0;
        height: fit-content;
    }

    .active-users-block-heading {
        margin: 0 $spacing-s;
    }

    .active-users-cards-wrapper {
        display: flex;
        flex-wrap: wrap;
        height: 100%;
    }

    .active-users-card {
        width: 16.875rem;
        margin: $spacing-s;
        padding: $spacing-xl 0 0 $spacing-xl;
        height: calc(100% - 1.5rem);
        border-radius: 0.5rem;
        background-color: $white;

        .users-number {
            margin-bottom: $spacing-xs;
        }
    }
}

// card
.card {
    position: relative;
    width: 13.5rem;
    height: 5.5rem;
    margin: $spacing-s;
    padding: $spacing-xl;
    border: 0;
    cursor: grab;
    background-color: white;
    transition: 0.05s ease-in-out;

    * {
        pointer-events: none;
        user-select: none;
    }
}

.card-heading {
    font-weight: $medium-font-weight;
    margin-right: $spacing-xs;
}

.card-new-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    margin-left: auto;
    cursor: pointer;

    &:active .card-new-btn-icon {
        /* To make icon white on click */
        filter: brightness(1000%);
    }
}

// utility
.blue-text-color {
    color: $blue;
}

.yellow-text-color {
    color: $yellow;
}

.violet-text-color {
    color: $violet;
}

.green-text-color {
    color: $green;
}

.red-text-color {
    color: $red;
}

.blue-background-color {
    background-color: $blue15;

    &:hover {
        background-color: rgba($blue, 0.25);
    }

    &:active {
        background-color: $blue;
    }
}

.green-background-color {
    background-color: $green15;

    &:hover {
        background-color: rgba($green, 0.25);
    }

    &:active {
        background-color: $green;
    }
}

.yellow-background-color {
    background-color: $yellow15;

    &:hover {
        background-color: rgba($yellow, 0.25);
    }

    &:active {
        background-color: $yellow;
    }
}

.red-background-color {
    background-color: $red15;

    &:hover {
        background-color: rgba($red, 0.25);
    }

    &:active {
        background-color: $red;
    }
}

.violet-background-color {
    background-color: $violet15;

    &:hover {
        background-color: rgba($violet, 0.25);
    }

    &:active {
        background-color: $violet;
    }
}

// layout
.wrapper-container {
    display: grid;
    grid-auto-rows: min-content;
    grid-template-columns: 1fr min-content;
    background-color: $dirty-white;

    :not(section, aside) {
        grid-column: 1 / 3;
    }
}

.create-new-section {
    padding: 0 $spacing-s;

    h2 {
        margin: $spacing-s;
    }
}

.card-wrapper {
    min-height: 6rem;
}

aside {
    h2 {
        margin: 0.75rem !important;
    }

    .card {
        margin: 0;
        transform: scale(0.9);
    }
}

.widgets {
    max-height: 38rem;
    min-width: 14.5rem;
    margin-bottom: 2rem;
    padding-top: 0.5rem;
    overflow: hidden auto;
}

.btn-save {
    position: fixed;
    right: 2rem;
    bottom: 1rem;
}


.condition-args {
    &__label {
        margin-bottom: 0.625rem;
        font-weight: 700;
    }

    &__item {
        margin-top: 0.3125rem;
        display: flex;
        align-items: center;
    }

    &__key {
        width: 6.25rem;
        flex-shrink: 0;
    }

    &__val {
        width: 100%;
    }
}


@import '~@/assets/scss/_consistency';
@import '~@/assets/scss/icons';

$icon-path: '~@/assets/icons/';

.breadcrumb-navigation {
    margin: $spacing-xl 0;

    .clickable {
        color: $blue-400;
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }

        &::after {
            content: url($icon-path + $arrow-breadcrumbs);
            height: 24px;
            width: 24px;
        }
    }
}

.customer-care-suite-style {
    background-color: $gray-100;
    padding: $spacing-xl $spacing-xl 0;

    .breadcrumb-navigation {
        background-color: $gray-100;
        margin: 0;
    }
}

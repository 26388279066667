
@import '~@/assets/scss/filter-table';
@import '~@/assets/scss/typographyV2';
@import '~@/assets/scss/layout';
@import '~@/assets/scss/palette';

.control-bar {
    margin-left: auto;

    .otp-button {
        width: 8.313rem;
    }
}

.no-entities-wrapper {
    height: 5.625rem;
    background: $white;
    border-radius: 0.25rem;
    display: flex;
    align-items: center;

    .no-entities {
        font-weight: 700;
        font-size: 0.75rem;
        line-height: 1.625rem;
        text-transform: uppercase;
        color: $blue60;
    }
}

.modal-form {
    .otp-modal-header {
        margin: $spacing-xxl $spacing-xxxl 0;
    }

    .modal-footer {
        margin-top: 4rem;
        padding: $spacing-l $spacing-xxl;
        border-top: none;
    }
}


@import '~@/assets/scss/palette';

.card-container {
    margin: 0 1.5rem 0.625rem 1.5rem;
    padding: 0.625rem 1rem;
    border-radius: 0.5rem;
    background-color: rgba($color: $blue, $alpha: 0.15);

    .card-title {
        color: $blue;
        font-size: 0.75rem;
        font-weight: 600;
    }
    .card-list {
        .card-content {
            display: flex;
            flex-direction: row;
            font-size: 0.875rem;
            color: $gray90;

            .card-values {
                font-size: 0.875rem;
            }
        }
        li::before {
            content: '•';
            width: 4px;
            height: 4px;
            margin-right: 6px;
            color: rgba($color: $blue, $alpha: 0.5);
        }
        li {
            display: flex;
        }
        ul {
            font-size: 0.75rem;
            font-weight: 600;
            color: $gray90;
            margin-right: 61px;
        }
    }
}
.icon-wrap {
    display: flex;
    justify-content: center;
    margin: 24px;
}

.section-header {
    color: $gray90;
    font-weight: 600;
    font-size: 0.812rem;
    padding: 8px 16px;
}

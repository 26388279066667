// ANIMATION TIMES
$fast-animation-time: 0.2s;
$medium-animation-time: 0.5s;
$slow-animation-time: 0.7s;


// ANIMATION CLASSES
.hover-increase-opacity {
    opacity: 0.6;
    transition: opacity $fast-animation-time;

    &:hover {
        opacity: 1;
    }
}

// TRANSITIONS
.slide-fade-in-enter-active,
.slide-fade-in-leave-active {
    transition: opacity $fast-animation-time ease;
}

.slide-fade-in-enter,
.slide-fade-in-leave-to {
    opacity: 0;
}

.slide-fade-in-scale-enter-active,
.slide-fade-in-scale-leave-active {
    transition: transform $medium-animation-time ease;
}

.slide-fade-in-scale-enter,
.slide-fade-in-scale-leave-to {
    transform: scale(0);
}

.slide-fade-enter-active {
    transition: all $fast-animation-time ease;
    opacity: 1;
}

.slide-fade-leave-active {
    transition: all $fast-animation-time cubic-bezier(1.0, 0.5, 0.8, 1.0) .1;
    opacity: 0;
}

.slide-fade-enter,
.slide-fade-leave-to {
    transform: translateX(-100%);
    opacity: 0;
}

.list-enter-active,
.list-leave-active {
    transition: $medium-animation-time;
    transition-property: opacity, transform;
}

.list-enter-active {
    opacity: 0;
    transform: translateX(-1.5rem);
    transition-delay: $fast-animation-time;
}

.list-enter-to {
    opacity: 1;
    transform: translateX(0);
}

.list-leave-to {
    opacity: 0;
    transform: translateX(1.5rem);
}

// ANIMATIONS
@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(359deg);
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
}

@keyframes slideFromTop {
    from {
        transform: translateY(-.75rem);
    }
}

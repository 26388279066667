
@import '~@/assets/scss/animations';
@import '~@/assets/scss/layout';
@import '~@/assets/scss/typographyV2';
@import '~@/assets/scss/consistency-colors';
@import '~@/assets/scss/consistency-spacing';

$icon-size: 0.5rem;

.dropdown {
    margin-bottom: $spacing-m;
    border-radius: 0.5rem;
    background-color: $white;

    &.small {
        margin-bottom: $spacing-xxs;
    }

    &-header {
        gap: $spacing-s;
        cursor: pointer;
        border-radius: inherit;
        background-color: inherit;
        white-space: normal;

        .dropdown.small & {
            gap: $spacing-xs;
            padding: 0;
            color: inherit;
        }
    }

    &-summary {
        &.has-labels {
            gap: $spacing-xs;
        }
    }

    &-labels {
        gap: $spacing-xxs;
    }

    &-arrow {
        font-size: $icon-size;
        color: $blue-300;
        transform: rotate(0);
        transition: transform 0.25s ease-in-out;

        &.expanded {
            transform: rotate(-180deg);
        }
    }

    &-title {
        margin: 0;
        font-size: $text-md;
        font-weight: 600;
        line-height: $body-line-height;
        color: $text-color;

        .dropdown.small & {
            font-size: $text-sm;
            font-weight: 400;
        }
    }

    &-content {
        margin-top: -0.75rem;
        max-height: 0;
        opacity: 1;
        transition: max-height $fast-animation-time ease-in-out, opacity $fast-animation-time ease;

        // Hide overflow content during open/close transitions.
        // e.g. Used for <AppTooltip />
        &.v-enter-active,
        &.v-leave-active {
            overflow: hidden;
            opacity: 0;
        }

        .dropdown.small & {
            margin-top: 0;
            font-size: $text-xs;
        }

        &.start-expanded {
            max-height: none;
        }
    }
}
// viasat demo
.dropdown-highlighted {
    background-color: $blue-400;

    .dropdown-title,
    .dropdown-arrow {
        color: $white !important;
    }
}

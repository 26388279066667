
@import '~@/assets/scss/mixins';
@import '~@/assets/scss/animations';
@import '~@/assets/scss/consistency';
@import '~@/assets/scss/z-indexes';

.elipsis {
    @include lineClamp();
}

.file-uploader.disabled {
    pointer-events: none;
}

// Upload
.upload-container {
    @include dashedBorder($gray-150);
    position: relative;
    padding: $spacing-xxl;
    color: $gray-600;
    transition: background-color $medium-animation-time;

    &.disabled,
    .disabled & {
        pointer-events: none;
        color: $gray-300;
        background-color: $gray-100;
    }

    &.drag-on {
        background-color: $gray-25;
    }
}

input {
    position: absolute;
    inset: 0;
    opacity: 0;
    cursor: pointer;
    overflow: hidden;
}

.upload-text {
    display: block;
    font-weight: 600;
    color: $gray-800;
    transition: color $medium-animation-time;

    .disabled & {
        color: inherit;
    }
}

.upload-link {
    position: relative;
    z-index: $overlap-smth-z-index;
    color: $blue;
    cursor: pointer;
    transition: $medium-animation-time;

    &:hover {
        text-decoration: underline;
    }

    .disabled & {
        color: $gray-300;
    }

    .drag-on & {
        position: static;
    }
}

// File preview
.file-list li:not(:last-child) {
    margin-bottom: $spacing-m;
}


@import '~@/assets/scss/palette';

.footer-button {
    margin-right: 0.75rem;
}

.entity-status {
    img {
        margin-right: 0.25rem;
    }
}

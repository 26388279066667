
@import '~@/assets/scss/colors';
@import '~@/assets/scss/palette';
@import '~@/assets/scss/layout';
@import '~@/assets/scss/icons';

$icon-path: '~@/assets/icons/';
.double_input {
    display: flex;
    justify-content: flex-start;
    &:hover {
        .delete-btn {
            opacity: 1;
        }
    }
}
.input_item {
    margin-right: 3rem;
    min-width: 14rem;
    width: 24%;
    &.full-width {
        width: 100%;
    }
}
.blue-arrow-down {
    width: 1rem;
    height: 1rem;
    content: url($icon-path + $blue-arrow-down);
}
.blue-arrow-up {
    width: 1rem;
    height: 1rem;
    content: url($icon-path + $blue-arrow-up);
}
.arrow-position-fix {
    margin-right: 0.5rem;
}
.tags-container {
    position: relative;
}
.seeMoreButton {
    font-size: 0.75rem;
    text-align: center;
    color: $blue;
    cursor: pointer;
    font-weight: 600;
}
.collapsed-container {
    height: 0.1rem;
    overflow: hidden;
}

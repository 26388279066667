
@import '~@/assets/scss/_palette';

.footer-nav {
    padding-top: 1.25rem;
    border-top: solid 1px $blue15;
}

.clickable {
    cursor: pointer;
    color: $blue;
    line-height: 26px;
    font-size: 0.875rem;
    font-weight: 600;
}

.previous {
    &::before {
        content: url('~@/assets/icons/arrow-left-round-border.svg');
        height: 24px;
        margin-right: 0.5rem;
    }
}

.next {
    &::after {
        content: url('~@/assets/icons/arrow-left-round-border.svg');
        transform: rotate(180deg);
        height: 24px;
        margin-left: 0.5rem;
    }
}

<template>
    <i
        class="icon"
        :class="`icon-${type}`"
        :style="{
            color,
            width: size,
            height: size,
            'font-size': size,
        }"
        v-on="$listeners"
    >
        <component
            :is="iconComponent"
            :class="type"
            :inheritAttrs="false"
            role="img"
        />
    </i>
</template>

<script lang="ts">
/* eslint-disable import/no-webpack-loader-syntax */
/* eslint-disable import/no-dynamic-require */
/* eslint-disable global-require */

/**
 * How this component works:
 *  - you are able to access all of the icons directory(not it's subdirectories)
 *  /src/assets/icons/ just by specifying the file name(without the .svg) as the :type
 *  prop of this component. For example, the /src/assets/icons/wallet.svg would be
 *  <AppIcon :type="wallet" />
 *
 *  - component will inherit color, font-size and transition of parrent element.
 *  - component uses font-size for handling it's size(width/height) unless size prop is provided
 *  - component uses color to handle fill/stroke attrs that have value of 'currentColor'
 *  - component uses transition-delay to handle color animations. Please note that if
 *  parrent or .icon element have transition-property defined(not 'all'), color prop
 *  must be in that list in order to animate color of svg.
 */

import MockIcon from './MockIcon.vue';

const icons = {};

if (process.env.NODE_ENV !== 'test') {
    const requireComponents = require.context('../../../assets/icons/consistency/', false, /.svg$/);
    requireComponents.keys().forEach(fileName => {
        const iconName = fileName.replace(/^\.\/(.+)\.svg$/, '$1');
        icons[iconName] = require(`!vue-svg-loader!../../../assets/icons/consistency/${iconName}.svg?vue-svg-loader`);
    });
}

export default {
    props: {
        type: {
            type: String,
            required: true,
            validator(value) {
                if (process.env.NODE_ENV === 'test') {
                    return true;
                }
                return Object.prototype.hasOwnProperty.call(icons, value);
            },
        },
        color: {
            type: String,
            required: false,
            default: 'inherit',
        },
        size: {
            type: String,
            required: false,
            default: '1rem',
        },
    },
    computed: {
        iconComponent() {
            if (process.env.NODE_ENV === 'test') {
                return MockIcon;
            }
            return icons[this.type];
        },
    },
};
</script>

<style scoped>
i {
    display: inline-flex;
    color: inherit;
    font-size: 1rem;
}

svg {
    width: 100%;
    height: 100%;
    color: inherit;
    /* 'color' transition will be applied to children <path>'s elements
            fill/stroke attrs that have 'currentColor' set as value */
    transition-property: color;
}
</style>


@import '~@/assets/scss/consistency-spacing';
@import '~@/assets/scss/consistency-typography';

.additional-sidebar-row {
    margin-bottom: $spacing-s;
}

.row-title {
    flex: 0 0 7.875rem;
}

.row-value {
    flex: 1 1 auto;
}

.additional-sidebar-table {
    width: 30rem;
    max-width: 30rem;
    height: auto !important;
}

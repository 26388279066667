
@import '~@/assets/scss/palette';
@import '~@/assets/scss/base';
@import '~@/assets/scss/icons';
@import '~@/assets/scss/typographyV2';
@import '~@/assets/scss/z-indexes';

$icon-path: '~@/assets/icons/';

.entity-row {
    // position: relative;
    border-bottom: 1px solid $dirty-white;

    color: $gray90;
    background-color: $white;
    overflow: visible;

    transition-property: background-color;
    transition-duration: 0.25s;

    &.default-hover:hover {
        cursor: pointer;
        background-color: $blue5;
    }

    &.selected {
        background-color: $blue15;

        .edit-column {
            opacity: 1;
        }
    }

    & > * {
        line-height: 40px;
    }

    .hover-button {
        position: absolute;
        top: 8px;
        right: 24px;
        display: none !important;
    }

    &:hover {
        .hover-button {
            display: flex !important;
        }

        .close span {
            display: inline;
            position: absolute;
        }
        .edit span {
            display: inline;
            position: absolute;
        }
    }
}

.buttons-container {
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
    display: flex !important;
    align-items: center;

    button {
        margin-right: 8px;
        margin-left: 8px;
    }
}

tr {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

tr td {
    display: inline-block;
    flex-direction: row;
    flex-wrap: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    &.overflow-visible {
        overflow: visible;
    }
}

td table tr {
    border: solid $dirty-white;
    border-width: 0 0 1px 1px;

    &:last-of-type {
        border-width: 0 0 0 1px;
    }
}

.lf-table-title-blue {
    color: $blue;
}

.hover-row-buttons {
    position: absolute;
    width: 0;
    align-self: flex-end;
    z-index: $overlap-smth-z-index;
}

.transitioning-area {
    background-image: linear-gradient(to right, transparent, $dirty-white);
    width: 20px;
    height: 100%;
}

.button-wrapper {
    background-color: $dirty-white;
    display: flex;
    position: absolute;
    bottom: 0;
    right: 0;
}

.checkbox-pad {
    padding: 0.313rem 0.313rem 0.313rem;
    margin-top: -0.35rem;
}


@import '~@/assets/scss/palette';

.sidebar-header {
    color: rgba($blue, 0.5);
}

.entity-view {
    .section-tab {
        background-color: $white;
        border: 0.063rem solid $dirty-white;
        border-right: none;
        border-top: 0.188rem solid transparent;

        height: 2.5rem;

        font-size: 0.8125rem;
        font-weight: 600;
        text-transform: uppercase;
        color: $gray30;
        padding-left: 1rem;

        &.active {
            background-color: $white;
            border-top: 0.188rem solid $blue;
            border-bottom: 0.063rem solid transparent;
            color: $blue;
        }

        &:first-child {
            border-left: none;
        }

        &:last-child {
            border-right: none;
        }

        &:hover:not(.active) {
            background-color: $dirty-white;
            color: $blue;
            cursor: pointer;
        }
    }
}

.modal-row {
    margin-bottom: 0.875rem;

    .row-title {
        font-size: 0.75rem;
        font-weight: 600;
        width: 20rem;
    }

    .row-value {
        font-size: 0.875rem;
        width: 100%;
    }
}

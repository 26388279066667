// THIS FILE IS USED TO STORE APP WIDE STYLES OF EXTERNAL COMPONENTS
@import 'icons';
@import 'typography';
@import 'palette';
@import 'mixins';
@import 'layout';
@import 'consistency-spacing';
@import 'z-indexes';

$icon-path: '~@/assets/icons/';

// v-tooltip styles (necessary, because library itself has no styles at all)

.tooltip {
    display: block !important;
    z-index: $tooltip-z-index;

    .tooltip-inner {
        background: $white;
        color: $black;
        padding: 5px 10px 4px;
        border-radius: 5px;
        box-shadow: 0 1px 2px 0 $gray30;
    }

    .tooltip-arrow {
        width: 0;
        height: 0;
        border-style: solid;
        position: absolute;
        margin: 5px;
    }

    &[x-placement^="top"] {
        padding-bottom: 5px;

        .tooltip-arrow {
            border-width: 5px 5px 0 5px;
            border-left-color: transparent !important;
            border-right-color: transparent !important;
            border-bottom-color: transparent !important;
            bottom: 0;
            left: calc(50% - 5px);
            margin-top: 0;
            margin-bottom: 0;
        }
    }

    &[x-placement^="bottom"] {

        .tooltip-arrow {
            border-width: 0 5px 5px 5px;
            border-left-color: transparent !important;
            border-right-color: transparent !important;
            border-top-color: transparent !important;
            top: -5px;
            left: calc(50% - 5px);
            margin-top: 0;
            margin-bottom: 0;
        }
    }

    &[x-placement^="right"] {
        margin-left: 5px;

        .tooltip-arrow {
            border-width: 5px 5px 5px 0;
            border-left-color: transparent !important;
            border-top-color: transparent !important;
            border-bottom-color: transparent !important;
            left: -5px;
            top: calc(50% - 5px);
            margin-left: 0;
            margin-right: 0;
        }
    }

    &[x-placement^="left"] {
        margin-right: 5px;

        .tooltip-arrow {
            border-width: 5px 0 5px 5px;
            border-top-color: transparent !important;
            border-right-color: transparent !important;
            border-bottom-color: transparent !important;
            right: -5px;
            top: calc(50% - 5px);
            margin-left: 0;
            margin-right: 0;
        }
    }

    &.popover {
        .popover-inner {
            background: $dirty-white;
            color: $black;
            padding: 24px;
            border-radius: 5px;
            box-shadow: 0 5px 30px rgba($black, .1);
        }

        .popover-arrow {
            border-color: $dirty-white;
        }
    }

    &[aria-hidden='true'] {
        visibility: hidden;
        opacity: 0;
        transition: opacity .15s, visibility .15s;
    }

    &[aria-hidden='false'] {
        visibility: visible;
        opacity: 1;
        transition: opacity .15s;
    }

    &:hover {
        display: block !important;
    }

    &.dark > .tooltip-inner {
        display: inline-block;
        line-height: 16px;
        padding: 5px 10px;
        max-width: none;
        background-color: $navy;
        border-radius: 16px;
        font-size: 14px;
        color: $white;
    }

    &.navigation-tooltip {
        .tooltip-inner {
            background-color: $navy;
            color: white;
            font-size: .8125rem;
        }
    }

    &.tooltip-bold {
        .tooltip-inner {
            font-weight: bold;
        }
    }
}

// vue-multiselect
@import '~vue-multiselect/dist/vue-multiselect.min.css';

// rewriting its default styles by defining more specific styles using #app

#app {
    .multiselect {
        border-color: $blue !important;

        &.small {
            min-height: 21px;

            .multiselect {
                min-height: 32px;
            }

            .multiselect__tags {
                border-radius: 8px;
                border: solid 1px $gray5;
                min-height: 32px;
                padding-bottom: 0;
                color: $navy;

                input {
                    font-size: 12px;
                }
            }

            .multiselect__option {
                min-height: 32px;
                height: 32px;
                padding: 8px 12px;
            }

            .multiselect__single {
                font-size: 0.875rem;
            }

            .multiselect__option,
            .multiselect__option.multiselect__option--highlight,
            .multiselect__option.multiselect__option--highlight.multiselect__option--selected {
                font-size: 0.875rem;
            }

            .multiselect__placeholder {
                font-size: 0.875rem;
            }
        }

        &.narrow {
            min-width: 200px;
            width: 200px;
        }

        .multiselect__select:before {
            position: absolute;
            top: calc(50% - 0.125rem);
            right: calc(50% - 0.375rem);
            margin: 0;
        }

        .multiselect__tags-wrap {
            display: block;
        }

        .multiselect__tags {
            border-radius: 8px;
            display: block;

            .multiselect__single {
                padding-left: 0;
                margin-bottom: 0;
                color: $gray90;
            }

            .multiselect__placeholder {
                font-style: italic;
                line-height: 1.5;
                font-weight: normal;
                color: $gray30;
            }

            .multiselect__placeholder {
                padding-top: 0;
                margin-bottom: 0;
            }
        }

        .multiselect__tag {
            height: 24px;
            border-radius: 2px;

            padding: 0 16px 0 28px;
            margin-right: 8px;
            margin-bottom: 3px;
            display: flex;
            align-items: center;
            width: fit-content;
            background-color: $blue !important;
        }

        .multiselect__tag-icon {
            width: 28px;
            left: 0;
            margin-left: 0;

            &:after {
                color: white !important;
            }

            &:hover {
                background-color: $blue !important;
            }

            &:active {
                background-color: $blue !important;
            }
        }

        &__element {
            .multiselect__option {
                padding-right: 32px;
                padding-left: 16px;
            }
        }

        &__tags {
            padding: 5px 32px 0 6px;
            border-radius: 2px;
            border-color: $gray5;
        }

        &__select {
            top: 0;
            height: 100%;
            border-left-width: 0;
            pointer-events: none;
            transition: none;
            padding: 0;

            &:before {
                border-color: $gray10 transparent transparent;
            }
        }

        &.border-none {
            .multiselect__content-wrapper {
                width: 100%;
            }

            .multiselect__tags {
                padding-left: 0;

                .multiselect__single {
                    @include lineClamp;
                    text-transform: uppercase;
                    font-weight: bold;
                    font-size: 0.75rem;
                    color: $gray-blue;
                }
            }

            .multiselect__element {
                .multiselect__option {
                    color: $gray-blue;
                }
            }
        }

        &.error {

            .multiselect__tags {
                border-color: $red;
            }
        }

        &--active {
            .multiselect__input {
                width: 100% !important;
                padding-left: 0;
                margin-bottom: 0;
            }

            .multiselect__tags {
                border-color: $blue;
            }

            .multiselect__select {
                transform: rotate(0);
            }
        }

        &__option--highlight {
            background: $blue5;
            color: $gray90;
        }

        &__option--selected {
            font-weight: normal;
            background: $blue5 url($icon-path + $check-mark) right 6px center no-repeat;
        }

        &.multiselect-without-padding {
            .multiselect__option {
                padding: 0;
            }
        }

        &__single-label-plus {
            .multiselect__tags {
                width: 40px;
                height: 46px;
                background: url($icon-path + $plus-light) no-repeat center;
                opacity: 0.6;
                border: none;
                transform: all .3s ease;

                &:hover {
                    background: url($icon-path + $icon-plus-blue) no-repeat center;
                }
            }


            .multiselect__placeholder,
            .multiselect__select::before {
                display: none;
            }

            .multiselect__content-wrapper {
                width: auto;
                right: 0;
            }

            .multiselect__option {
                min-width: 200px;
                min-height: auto;
                padding: 0;
            }
        }

        &__label-arrow-down {
            .multiselect__tags {
                background: url($icon-path + $arrow-down-dark) no-repeat right;
                border: none;
            }

            .multiselect__placeholder,
            .multiselect__select::before {
                display: none;
            }

            .multiselect__content-wrapper {
                width: auto;
                right: 0;
            }

            .multiselect__option {
                min-width: 200px;
                min-height: auto;
                padding: 0;
            }
        }

        &.single-label {
            .multiselect__tags {
                border: none;
                background: transparent;
                padding: 0;
            }

            .multiselect__single {
                background: transparent;
            }

            .multiselect__select {
                display: none;
            }

            .multiselect__content-wrapper {
                width: auto;
            }
        }

        &__content-wrapper {
            // :not() selector part is a fix for the issue when $isDisabled prop is present:
            // > https: //github.com/shentao/vue-multiselect/issues/1081
            .multiselect__option--group:not(.multiselect__option.multiselect__option--disabled) {
                background: $gray5 !important;
                font-size: $text-sm;
                min-height: 1.7rem;
                font-weight: bold;
                padding: 5px 5px;
            }
        }
    }

    .multiselect--disabled,
    .multiselect--disabled.small {
        opacity: 0.5;
        border-radius: 8px;

        &.verified {
            background-color: $green10;

            .multiselect__tags, .multiselect__single {
                color: $gray90;
                background-color: $green10;
                border-color: $green15;
            }

            .multiselect__select {
                display: none;
            }

            &:after {
                content: '';
                position: absolute;
                top: $spacing-xs;
                right: $spacing-xs;
                width: 1rem;
                height: 1rem;
                background: url($icon-path + $input-verified);
            }
        }

        .multiselect__select {
            background: none;
        }
    }
}

// v-dropzone
@import '~vue2-dropzone/dist/vue2Dropzone.min.css';

.vue-dropzone {
    border-width: 1px;
}

// vue-datetime

.theme-orange .vdatetime-popup__header,
.theme-orange .vdatetime-calendar__month__day--selected > span > span,
.theme-orange .vdatetime-calendar__month__day--selected:hover > span > span {
    background: $orange;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: $font-family-base;
}

.theme-orange .vdatetime-year-picker__item--selected,
.theme-orange .vdatetime-time-picker__item--selected,
.theme-orange .vdatetime-popup__actions__button {
    color: $orange;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: $font-family-base;
}

.theme-orange {
    .vdatetime-input {
        width: 250px;
    }
}

.vdatetime {
    .vdatetime-input {
        border-width: 1px;
    }

    &.error {
        .vdatetime-input {
            border: 1px solid $red;
            border-image: none;
        }
    }
}

// c3 overrides
.c3 svg {
    font-family: 'Open Sans', sans-serif;
}

// vue2-datepicker
@import '~vue2-datepicker/index.css';

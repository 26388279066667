
@import '~@/assets/scss/icons';
@import '~@/assets/scss/palette';
@import '~@/assets/scss/forms';
@import '~@/assets/scss/typography';
@import '~@/assets/scss/consistency-spacing';
@import '~@/assets/scss/typographyV2';

$icon-path: '../../../../assets/icons/';

.authentication-details {
    padding: $spacing-xxl;
    padding-left: 64px;
}
.authentication-details > * {
    margin-bottom: $spacing-m;
}
.account-page-image {
    height: 240px;
    background: $dirty-white url($icon-path + $account-page-rays);
    background-size: cover;

    .account-page-lock {
        width: 178px;
        height: 178px;
        background: url($icon-path + $account-page-lock);
    }
}

.account-personal-details {
    .title {
        font-weight: 600;
        color: $navy;
    }

    .form {
        padding-left: 64px;
    }
}

.role-names {
    padding-left: $spacing-m;
}


@import '~@/assets/scss/consistency-colors';

.card-wrapper {
    width: 17.5rem;
    max-width: 100%;
    margin-right: 1.5rem;
    background: $blue-400;
}

.deduct:not(:hover) {
    color: $white;
}

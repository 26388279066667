
@import '~@/assets/scss/palette';

.datetimepicker-label {
    margin-bottom: 4px;
    color: $gray90;
    font-weight: 600;
}

.error {
    .mx-input {
        border-color: $red !important;
    }
}


@import '~@/assets/scss/consistency';
@import '~@/assets/scss/icons';
@import '~@/assets/scss/mixins';
@import '~@/assets/scss/layout';
@import '~@/assets/scss/z-indexes';
@import '~@/assets/scss/animations';

$icon-path: '~@/assets/icons/';

.resize-handle {
    width: 0.625rem;
    height: calc(100vh - 4.25rem);
    position: absolute;
    top: 0;
    cursor: ew-resize;
    z-index: $two-z-index;

    &:hover,
    &:active {
        background-color: $gray-200;
    }

    &.actions-resize-handle {
        right: 0;
    }

    &.definition-resize-handle {
        left: 0;
    }
}

.page-content-wrapper {
    display: flex;
    flex-direction: row;
    height: calc(100% - 5.56rem);

    .definition-sidebar,
    .actions-sidebar {
        position: relative;
        transition: width 0.2s;

        .collapse-button {
            position: absolute;
            background-color: $white;
            border: 0.063rem solid $gray-200;
            border-radius: 50%;
            top: 50%;

            &:hover {
                background-color: $gray-200;
            }

            &:active {
                background-color: $blue-400;
            }
        }
    }

    .actions-sidebar {
        border-right: 0.063rem solid $gray-200;
        padding: $spacing-l $spacing-xl;
        min-width: 20rem;
        overflow-x: hidden;
        position: relative;

        &.closed {
            min-width: 0;
            overflow: unset;
        }

        .collapse-button {
            right: -$spacing-l;
        }

        .w-80 {
            width: 80%;
        }

        .actions-sidebar-content {
            .section-tab {
                background-color: $white;
                border: 0.063rem solid $gray-100;
                border-right: none;

                /* Adding transparent border to make both columns equal height */
                border-top: 0.188rem solid transparent;

                height: $spacing-xxxl;

                font-size: 0.8125rem;
                font-weight: 600;
                text-transform: uppercase;
                color: $gray-300;
                padding-left: $spacing-m;

                &.active {
                    background-color: $white;
                    border-top: 0.188rem solid $blue-400;
                    border-bottom: 0.063rem solid transparent;
                    color: $blue-400;
                }

                &:first-child {
                    border-left: none;
                }

                &:last-child {
                    border-right: none;
                }

                &:hover:not(.active) {
                    background-color: $gray-100;
                    color: $blue-400;
                    cursor: pointer;
                }
            }

            .templates-content {
                flex-direction: column;

                .tab {
                    font-size: 0.875rem;
                    font-weight: 600;
                    color: $blue-400;
                    transition: opacity $fast-animation-time ease-in;
                    cursor: pointer;
                }

                .hold {
                    color: $blue-400;
                    font-weight: 600;
                }

                .invisible {
                    display: hidden;
                }
            }
        }
    }

    .plan-editor {
        padding: $spacing-xl;
        flex: 1;

        .zoom-in-button,
        .zoom-out-button {
            cursor: pointer;
        }

        .canvas-wrapper {
            width: 100%;
            height: 60vh;
            flex-shrink: 0;
            overflow: auto;
            border: 0.063rem solid $gray-200;
            border-radius: $spacing-xs;

            .new-plan-editor-canvas {
                position: relative;
                /* Using important to override values applied by draw2d library inline */
                width: fit-content !important;
                height: fit-content !important;
                min-width: 100%;
                min-height: 100%;
            }
        }

        .draggable-section {
            border: 0.125rem dashed;
            border-color: $blue-400;
            border-radius: $spacing-xs;
        }
    }

    .definition-sidebar {
        padding: $spacing-xl;
        user-select: none;
        border-left: 0.063rem solid $gray-200;
        min-width: 30rem;

        &.closed {
            min-width: 0%;
        }

        .collapse-button {
            left: -$spacing-l;
        }

        .plan-editor-textarea {
            height: 60vh;

            border: 0.063rem solid $gray-200;
            border-radius: $spacing-xs;

            ::v-deep .monaco-editor {
                background-color: transparent;

                .margin {
                    background-color: transparent;
                }
            }
        }

        .plan-editor-error {
            border: 0.063rem solid $red-400 !important;
        }
    }
}

.controls-wrapper {
    position: sticky;
    z-index: $page-layout-item-z-index;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    height: 5.56rem;
    padding: $spacing-xl 0;
    border-top: 0.063rem solid $blue15;
    background-color: $white;
    overflow: visible;

    transform: translateZ(0);

    .controls-content {
        display: flex;
        justify-content: flex-end;
        width: 70%;
    }
}


@import '~@/assets/scss/palette';
@import '~@/assets/scss/icons';
@import '~@/assets/scss/mixins';
@import '~@/assets/scss/layout';
@import '~@/assets/scss/z-indexes';
@import '~@/assets/scss/typographyV2';

$icon-path: '~@/assets/icons/';

.new-template-editor {
    ::v-deep .form-content-wrapper {
        width: 90%;
        padding-top: 0;
    }
}

.page-content-wrapper {
    display: flex;
    flex-direction: row;
    padding: $spacing-l;
}

.template-editor {
    position: relative;
}

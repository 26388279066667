
@import '~@/assets/scss/typographyV2';

.schedule-entry {
    border-radius: 0.5rem;
    padding: 1rem 1.5rem;
    outline: none;
    border: 1px solid #dddddd;
}
.action-summary {
    display: block;
}
ul {
    margin-bottom: 0;
}

// Show add action only on hover
.add-action {
    visibility: hidden;
    padding-left: 1rem;
}

.schedule-entry:hover .add-action {
    visibility: visible;
}

.add-action > *:not(:last-child) {
    padding-bottom: 1rem;
}

// Show delete entry only on hover
.delete-entry {
    display: none;
}

.schedule-entry:hover .delete-entry {
    display: block;
}

.action-list {
    border-left: 1px solid #dddddd;
    padding-left: 1rem;
}

.action-summary {
    display: list-item;
    list-style-type: disc;
}
.action-edit {
    margin-right: -0.8rem;
}
.action-actions {
    visibility: hidden;
    position: absolute;
}

.action-list-item:hover .action-actions {
    visibility: visible;
}

.action-actions-container {
    position: relative;
}

.input-small {
    width: 8rem;
}
.input-smallest {
    width: 4rem;
}
.consistency-margin-bottom {
    margin-bottom: 0.25rem;
}

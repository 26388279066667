
@import '~@/assets/scss/palette';
@import '~@/assets/scss/animations';
@import '~@/assets/scss/layout';

.toggle-wrapper {
    display: flex;
    align-items: center;
    font-size: 1rem;
    line-height: 1.5rem;

    .toggle-label {
        cursor: pointer;
        color: $gray90;
        font-weight: 600;

        &.with-margin {
            margin-right: $spacing-l;
        }
    }

    &.fit-content {
        width: fit-content;
    }

    .switch {
        position: relative;
        display: inline-block;
        width: 2.8125rem;
        height: 1.3rem;
        background-color: $gray10;
        opacity: 0.4;
        border-radius: 1.25rem;
        cursor: pointer;

        &:after {
            content: '';
            position: absolute;
            width: 1.1rem;
            height: 1.1rem;
            border-radius: 100%;
            background-color: $white;
            top: 50%;
            transform: translateY(-50%);
            left: 0.12rem;
            transition: $fast-animation-time ease;
        }
    }

    &.small {
        .toggle-label {
            font-size: 12px;

            &.with-margin {
                margin-right: $spacing-m;
            }
        }

        .checkbox:checked + .switch::after {
            left: 1rem;
        }

        .switch {
            width: 1.875rem;
            height: 1rem;
            border-radius: 0.5rem;

            &:after {
                width: 0.75rem;
                height: 0.75rem;
                left: 0.12rem;
            }
        }
    }

    &.disabled {
        .switch {
            background: $white;
            border: solid 0.0625rem $gray5;
            opacity: 0.5;
            cursor: not-allowed;

            &:after {
                background: $gray5;
            }
        }
    }

    .checkbox:checked + .switch::after {
        left: 1.58rem;
    }

    .checkbox:checked + .small.switch::after {
        left: 1rem;
    }

    .checkbox:checked + .switch {
        opacity: 1;
        background-color: $blue;
    }

    .checkbox {
        display: none;
    }

    .tooltip-info {
        white-space: break-spaces;
        font-size: 0.75rem;
        font-weight: normal;
        text-transform: none;
        line-height: normal;
        color: $gray90;
        min-width: 10rem;
    }
}

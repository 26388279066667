
@import '~@/assets/scss/consistency-colors';
@import '~@/assets/scss/consistency-typography';
@import '~@/assets/scss/consistency-spacing';

.rotate-180 {
    transform: rotate(-180deg);
}
.cursor-pointer {
    cursor: pointer;
}
.item {
    border: 1px solid $gray-200;
    border-radius: $spacing-xs;
    position: relative;
}
.list-box {
    border: 1px solid $gray-200;
    border-radius: $spacing-xs;
    background: $white;
    &.no-title {
        border: none;
    }
}
.has-border-bottom {
    border-bottom: 1px solid $gray-200;
}
.type-label {
    border: 1px solid $gray-200;
    border-radius: $spacing-xxs;
}
.combinator-line {
    height: 100%;
    border-left: 1px solid $gray-200;
    position: relative;
    &:before,
    &:after {
        content: '';
        position: absolute;
        width: 1rem;
        left: 0;
        height: 1px;
        background: $gray-200;
    }
    &:after {
        bottom: 0;
    }
}
.combinator {
    color: $blue-400;
    position: absolute;
    bottom: -1rem;
    background: $white;
    height: fit-content;
    &-and {
        left: -2.3rem;
    }
    &-or {
        left: -2.1rem;
    }
    &-low-level {
        left: -2rem;
        bottom: 0;
        top: 0;
    }
}
.custom-paddings {
    padding: $spacing-s $spacing-m;
}


@import '~@/assets/scss/palette';
@import '~@/assets/scss/icons';

$icon-path: '~@/assets/icons/';
$image-path: '~@/assets/images/';

.header {
    margin: 1.5rem 0.875rem 0.875rem;
}

.list {
    max-height: calc(100vh - 16rem);
    overflow-y: auto;
    margin: 1.5rem 0.875rem 0.875rem;
}

.label {
    font-size: 0.875rem;
    color: $gray60;
    width: 200px;
}

.dropdown {
    width: 584px;
}

.button-wrapper {
    display: flex;
    justify-content: flex-end;
}


.inputs-wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    .current-parameter {
        display: flex;
        flex-direction: column;
        width: 20rem;
    }
}

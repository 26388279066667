
@import '~@/assets/scss/palette';
@import '~@/assets/scss/layout';
@import '~@/assets/scss/typographyV2';

.banner {
    position: relative;
    background-color: #d6dcea;
    background-repeat: no-repeat;

    min-height: 15rem;

    margin-top: $spacing-xxxl;
    margin-bottom: 5.25rem;

    padding-left: $spacing-xxl;

    border-radius: 0.5rem;

    display: flex;
    justify-content: flex-start;
    align-items: center;

    .banner-text {
        max-width: 16rem;
    }

    &:after {
        content: '';
        position: absolute;
        bottom: 0;
        right: 0;
        margin-right: $spacing-xxl;
        width: 50%;
        /* height 106% is intended to make picture bigger than the block that holds it as per design*/
        height: 106%;
        background-image: url('~@/assets/images/esim-search-illustration.svg');
        background-size: contain;
        background-repeat: no-repeat;
    }
}

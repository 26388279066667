
@import '~@/assets/scss/palette';
@import '~@/assets/scss/animations';

.multiple-tabs-wrapper {
    width: max-content;
    min-width: 100%;
}

.underline {
    height: 0;
    border-bottom: solid 1px rgba(51, 81, 149, 0.16);
    position: relative;
    top: -0.1rem;
}

.clickable {
    cursor: pointer;
    font-size: 1rem;
    opacity: 0.5;
    color: $blue;
    font-weight: 600;
}

.tab-margin {
    margin-right: 2.5rem;
}

.active-tab {
    font-size: 1rem;
    color: $blue;
    font-weight: 600;
    border-bottom: solid 0.125rem $blue;
    transition: opacity $fast-animation-time ease-in;
    &.error {
        color: $red;
        border-bottom-color: $red;
    }
}
.error {
    color: $red;
    border-bottom-color: $red;
}


@import '~@/assets/scss/palette';
@import '~@/assets/scss/layout';
@import '~@/assets/scss/typographyV2';

.content-container {
    width: 70%;
    margin: 0 15%;
}

.banner {
    position: relative;
    background-color: #d6dcea;
    background-repeat: no-repeat;

    min-height: 15rem;

    margin-top: $spacing-xxxl;
    margin-bottom: 5.25rem;

    padding-left: $spacing-xxl;

    border-radius: 0.5rem;

    display: flex;
    justify-content: flex-start;
    align-items: center;

    .banner-text {
        max-width: 16rem;
    }
}

.lf-all-caps {
    color: $gray-blue;
}

.value-input-container {
    width: 20rem;
}

.container-with-max-heigh {
    display: flex;
    max-height: 4rem;
    align-items: center;
}

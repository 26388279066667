
.kay-value-pairs {
    max-width: 36.5rem;
}

.width {
    width: 100%;
}

.input-position {
    align-self: flex-end;
}

@import 'palette';

.input {
    padding: 0 12px;
    height: 32px;
    line-height: 32px;

    color: $gray60;
    font-size: 14px;

    border: 1px solid $gray5;

    &:focus {
        border-color: $blue;
    }

    &:disabled {
        color: $gray30;
        background-color: transparent;
    }

    &[invalid] {
        border-color: $red;
    }

    &.large {
        width: 320px;
        height: 40px;
        line-height: 38px;
        font-size: 16px;
    }

    &.error {
        border-color: $red;
    }
}

::v-deep .multiselect__placeholder {
    margin-bottom: 0;
}


@import '~@/assets/scss/palette';
@import '~@/assets/scss/typography';

.background-color {
    background-color: $dirty-white;
}

.section-layout {
    margin: 0 1.5rem 1.5rem 1.5rem;
}

.table-header-row {
    //searchBox is dictating height, it is in px, so this should also be in px
    min-height: 36px;
}

.search-box-wrapper {
    position: relative;
    width: 15rem;
    height: 2.5rem;
}

.component-title {
    font-size: 1rem;
    font-weight: 600;
    color: $gray90;
}

.modal-title {
    padding: 2rem 2.5rem 2.1875rem;
}

.modal-buttons {
    padding: 1.4375rem 2rem;
}

.gray-note {
    padding: 0.6875rem 2.5rem;
}

.refund-input {
    padding: 2.125rem 2.5rem;
}

strong {
    color: $navy;
}

.link:hover {
    color: $blue;
    text-decoration: underline;
}

.tags-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.tag-margins {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
}

.table-search-box {
    width: 15rem;
}

.pointer {
    cursor: pointer;
}

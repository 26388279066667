
@import '@/assets/scss/_consistency.scss';
.collapsible-item {
    border: 1px solid $gray-200;
    border-radius: $spacing-xs;
    background: $white;
}
.collapsible-title {
    flex-grow: 1;
}
.rotate-180 {
    transform: rotate(-180deg);
}
.has-border-bottom {
    border-bottom: 1px solid $gray-200;
}


@import 'src/assets/scss/colors';
@import 'src/assets/scss/palette';
@import 'src/assets/scss/typographyV2';
@import 'src/assets/scss/layout';

.new-note-btn {
    width: 6.875rem;
}

.new-note-wrapper {
    display: flex;
    flex-direction: column;

    .new-note {
        padding: 2rem 10rem 4rem;
    }
}

section {
    .content {
        padding: 2rem 0;

        p {
            margin: 0;
            color: $gray90;
        }
    }
}

.custom-footer {
    padding: 1.5rem 9.5rem;
    border-top: solid 0.0625rem rgba($color: $blue, $alpha: 0.15);
}
.background-color {
    background-color: $dirty-white;
}

.section-layout {
    margin: 0 1.5rem 1.5rem 1.5rem;
}

.modal-title {
    font-size: 1.375rem;
    font-weight: 600;
    margin-bottom: 2.1875rem;
}

.modal-row {
    margin-bottom: 0.875rem;
    font-size: 0.875rem;
}

.row-title {
    font-weight: 600;
    flex: 0 0 7.875rem;
}

.row-value {
    flex: 1 1 auto;
}

.status-label {
    font-size: 0.875rem;
    width: 100%;
}

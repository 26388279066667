
@import 'src/assets/scss/colors';
@import 'src/assets/scss/palette';
@import 'src/assets/scss/typographyV2';
@import 'src/assets/scss/layout';
@import '~@/assets/scss/app-table-new-design';

.app-table ::v-deep {
    @include appTableNewDesign;
}

.heading {
    margin: 0;
    margin-bottom: 1rem;
}

.sub-heading {
    margin: $spacing-xs 0;
}

.content {
    padding: 2rem 0;

    p {
        margin: 0;
        color: $gray90;
    }
}


@import '~@/assets/scss/_layout.scss';

.modal-content-wrapper {
    margin: $spacing-l;

    height: 24rem;

    display: flex;
    flex-direction: column;
}

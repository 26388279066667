
@import '~@/assets/scss/palette';
@import '~@/assets/scss/layout';
@import '~@/assets/scss/z-indexes';

.content-block-wrapper {
    position: relative;
    border-radius: 0.5rem;
    padding: $spacing-s $spacing-l $spacing-l;
    background-color: $white;
    margin-top: $spacing-l;

    .zoom-in-button,
    .zoom-out-button {
        position: absolute;
        z-index: $overlap-smth-z-index;
        left: 50%;
        cursor: pointer;
    }

    .zoom-out-button {
        top: 3.25rem;
    }

    .json-wrapper {
        width: 50%;
        height: 55vh;
    }

    .canvas-wrapper {
        width: 50%;
        flex-shrink: 0;
        overflow: auto;

        .plan-definition-canvas {
            position: relative;
            /* Using important to override values applied by draw2d library inline */
            width: fit-content !important;
            height: fit-content !important;
            min-width: 100%;
            min-height: 100%;
        }
    }
}


@import 'src/assets/scss/colors';
@import 'src/assets/scss/palette';
@import 'src/assets/scss/typographyV2';
@import 'src/assets/scss/layout';

.new-note-wrapper {
    display: flex;
    flex-direction: column;

    .new-note {
        padding: 2rem 10rem 4rem;
    }
}

section {
    .content {
        padding: 2rem 0;

        p {
            margin: 0;
            color: $gray90;
        }
    }
}

.custom-footer {
    padding: 1.5rem 9.5rem;
    border-top: solid 0.0625rem rgba($color: $blue, $alpha: 0.15);
}
.background-color {
    background-color: $dirty-white;
}

.section-layout {
    margin: 0 1.5rem 1.5rem 1.5rem;
}

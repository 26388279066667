
@import '~@/assets/scss/_consistency';

.close-icon-wrap {
    vertical-align: center;
    background: $gray-200;
    padding: $spacing-xxs $spacing-xs;
    display: flex;
    align-items: center;
    border-top-right-radius: $spacing-xxs;
    border-bottom-right-radius: $spacing-xxs;
    cursor: pointer;
    &.disabled {
        pointer-events: none;
        background-color: $gray-100;
    }
    &:hover {
        background: $red-200;
    }
}
.tag-element {
    display: inline-flex;
    align-items: center;
    color: $blue-600;
    background: $gray-200;
    padding: $spacing-xxs $spacing-xxs $spacing-xxs $spacing-xs;
    border-top-left-radius: $spacing-xxs;
    border-bottom-left-radius: $spacing-xxs;
    &.overview-mode {
        border-radius: $spacing-xxs;
        padding: $spacing-xxs $spacing-xs;
    }
    &.disabled {
        color: $gray-300;
        background-color: $gray-100;
        pointer-events: none;
        padding: $spacing-xxs $spacing-xs;
    }
}


@import '~@/assets/scss/palette';
@import '~@/assets/scss/icons';

$icon-path: '~@/assets/icons/';

.payout-limits-text {
    color: $gray-blue;
    font-size: 0.75rem;
    font-weight: 700;
    text-transform: uppercase;
}

.heading {
    margin: 0;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.63;
    font-size: 1rem;
    color: $gray90;
}

.text-gray {
    color: $gray30;
    font-weight: 400;
}

@import "colors";

.form-name-input {
  padding: 8px;
  margin-bottom: 16px;
}

.fullwidth-gray-bar {
  display: flex;
  align-items: center;
  height: 48px;
  background-color: $dirty-white;
  padding: 4px 4px 4px 64px;
  font-weight: bold;

  .row {
    align-items: center;
  }

  .select {
    font-weight: initial;
  }

  button {
    font-weight: bold;
  }
}

.flex-align-items-center {
  display:flex;
  align-items: center;
}


@import '~@/assets/scss/animations';
@import '~@/assets/scss/icons';
@import '~@/assets/scss/z-indexes';
@import '~@/assets/scss/consistency-colors';

$icon-path: '~@/assets/icons/';

.loading-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: $loader-z-index;

    &.loading-background {
        background-color: rgba($gray-300, 0.3);
    }

    .loading {
        position: absolute;
        top: 50%;
        left: 50%;
        opacity: 0.5;

        &::before,
        &::after {
            content: '';
            position: absolute;
            z-index: $overlap-smth-z-index;
        }

        &::before {
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
        }

        &::after {
            content: '';
            width: 40px;
            height: 40px;
            position: absolute;
            top: calc(50% - 20px);
            left: calc(50% - 20px);
            background: url($icon-path + $gray-spinner);
            background-size: cover;
            animation: spin 1000ms infinite linear;
            margin-top: 2rem;
        }
    }
}


@import '~@/assets/scss/animations';
@import '~@/assets/scss/consistency-colors';
@import '~@/assets/scss/app-table-new-design';

.app-table ::v-deep {
    @include appTableNewDesign;
}

.vertical-align {
    vertical-align: middle;
}

.spinning {
    animation: spin 1.4s infinite linear;
}


@import '~@/assets/scss/palette';

.editor {
    background-color: $white;
}

.section {
    .heading {
        margin: 0;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.63;
        font-size: 1rem;
        color: $gray90;
    }

    .sub-heading {
        font-size: 0.812rem;
        line-height: 2.15;
        color: $gray60;
    }
}

.approve-on-create {
    padding-top: 0.75rem;

    .label {
        font-size: 0.75rem;
        font-weight: 700;
        color: black;
        flex: 0 1 9rem;
    }

    & > .approve-checkbox {
        margin-left: -0.25rem;
    }
}

.error {
    border: 0.0625rem solid $red;
}


@import '~@/assets/scss/palette';
@import '~@/assets/scss/icons';

$icon-path: '~@/assets/icons/';

.info-icon {
    width: 1rem;
    height: 1rem;
    content: url($icon-path + $info-grey);
}

.tooltip-container {
    height: 1rem;
}

.tooltip-inner-content {
    width: 26.25rem;
}

.editor-wrapper {
    max-width: 880px;
    margin: 0 auto;
}

.input-max-width {
    max-width: 567px;
}

.input-width {
    min-width: 17.6875rem;
    width: 30%;
}

.section-title {
    font-size: 1rem;
    font-weight: bold;

    color: $gray90;

    margin-top: 2rem;
    margin-bottom: 1rem;
}

.discounts-types-dropdown {
    max-width: 120px;
    width: 100%;
}

.input-label {
    font-weight: bold;

    font-size: 0.75rem;

    color: $gray90;
}

.amount-label {
    display: flex;
    align-items: center;
    height: 2.125rem;
}

.value-input {
    max-width: 60px;
}

.value-input-section {
    span {
        font-size: 0.75rem;
        font-weight: bold;
        line-height: 1.75;
        color: $gray-blue;
    }
}

.recurrence-dropdown {
    min-width: 200px;
}

.recurrence-label {
    font-size: 0.75rem;
    font-weight: bold;
    color: $gray-blue;
}

.recurrence-value-input {
    width: 80px;
}

.card-slot-content {
    display: flex;
    align-items: center;
    padding-top: 20px;
    margin-left: 52px;

    .input-wrapper {
        width: 4rem;
        margin: 0 8px;
    }
}

.editor-controls-wrapper {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    height: 5.56rem;
    margin-top: 3rem;
    padding: 1.5rem 11.25rem;
    border-top: 1px solid $blue15;
    background-color: $white;

    .editor-controls-content {
        display: flex;
        justify-content: flex-end;
    }
}


@import '~@/assets/scss/mixins';
@import '~@/assets/scss/consistency-colors';
@import '~@/assets/scss/sidebar';
@import '~@/assets/scss/z-indexes';

.sidebar {
    // TODO: replace once design is finalized
    background: #14203b;
    transition: width $transitionTime;
    width: $sidebarFullWidth;
    font-size: 0.875rem;
    color: $white;
    display: flex;
    flex-direction: column;

    &.compact {
        width: $sidebarCompactWidth;
        overflow-y: visible;
    }
}

.lf-logo-container {
    margin: 24px 16px;

    .lf-logo {
        display: flex;
    }

    .lf-name {
        margin-left: 10px;
    }
}

.sidebar-logo {
    width: 28px;
    height: 28px;
}

.sidebar-main-menu {
    flex-grow: 1;
    overflow-x: hidden;
    overflow-y: auto;
}

.toggler {
    position: relative;
    padding: 24px;
    padding-left: 20px;
    display: flex;
    justify-content: flex-start;
    z-index: $sidebar-stuff-z-index;
    cursor: pointer;

    .toggler-icon {
        transition: transform $transitionTime;

        &.compact {
            transform: rotateY(180deg);
        }
    }
}

.item-group:not(:first-child) {
    padding-top: 2rem;
}

// iframe case exception
.app-in-iframe {
    .sidebar-footer {
        position: absolute;
        bottom: 0;
    }
}


@import '~@/assets/scss/typographyV2';
@import '~@/assets/scss/palette';
@import '~@/assets/scss/layout';

.policy-notification-summary {
    display: flex;
    flex-direction: column;

    .policy-notification-summary-data {
        display: flex;
        align-items: center;
    }
}

.threshold-select-card {
    padding: $spacing-m;
    background-color: rgba($gray30, 0.15);
    border-radius: $spacing-xs;

    .threshold-select {
        display: flex;
        align-items: center;

        .threshold-value {
            width: 6rem;
        }
    }
}

.horizontal-line {
    width: 100%;
    border-bottom: 1px solid $blue15;
}

.tab-header-error {
    color: $red;
}


@import '~@/assets/scss/consistency-spacing';
@import '~@/assets/scss/colors';

.section {
    margin: 0rem 0rem $spacing-m $spacing-xs;
    padding-right: $spacing-xl;
}

.tooltip-explanation-content {
    text-transform: none;
    font-weight: 100;
}

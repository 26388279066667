
@import '~@/assets/scss/_consistency';
@import '~@/assets/scss/layout';
@import '~@/assets/scss/icons';

$icon-path: '~@/assets/icons/';

.form-content {
    flex-grow: 1;
}

.border-bottom {
    border-bottom: solid 0.063rem rgba(51, 81, 149, 0.15);
}

.state-label {
    text-transform: uppercase;
    font-size: 0.625rem;
    font-weight: bold;
    line-height: 2;
    color: $blue-300;
}

.delete-btn {
    cursor: pointer;
    height: 0.9rem;
}

.tooltip-info {
    white-space: pre;
    font-size: $spacing-s;
    font-weight: normal;
    text-transform: none;
    line-height: normal;
    color: $gray-800;
    min-width: 10rem;
}

.draggable-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: $spacing-m;
    border-radius: 5px;
    background-color: $blue-200;
    padding: $spacing-xs $spacing-xl $spacing-xs $spacing-m;
    font-size: $spacing-m;
    font-weight: 600;
    color: $blue-400;

    .loop-multiselect {
        min-width: 20rem;
    }
}

.draggable-handle {
    width: $spacing-m;
    height: $spacing-m;
    margin-right: $spacing-xs;
    background-image: url($icon-path + $dnd-handle);
    cursor: grab;
}


@import '~@/assets/scss/_palette.scss';
@import '~@/assets/scss/_animations.scss';
@import '~@/assets/scss/_typographyV2.scss';

.label {
    color: $gray90;
    margin-bottom: 0.25rem;
}

.card-item-body {
    height: 100%;
    position: relative;
    padding-left: 52px;

    .card-label {
        margin: 0;
    }

    .description {
        text-align: left;
        line-height: 1.25;
        margin-top: 0.125rem;
    }

    &::before {
        content: '';
        width: 16px;
        height: 16px;
        opacity: 0.4;
        border: solid 2px $gray10;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        left: 18px;
        transform: translateY(-50%);
        transition: $medium-animation-time;
    }

    &.active {
        &::before {
            opacity: 1;
            background-color: $blue;
            border-color: rgba($color: $blue, $alpha: 0.4);
            background-clip: padding-box;
        }

        .card-label {
            color: $blue;
        }
    }

    &.small {
        padding-left: 1.75rem;

        &::before {
            left: 4px;
            top: 10px;
        }

        .card-label {
            font-size: 14px;
            text-align: left;
        }

        .description {
            font-size: 10px;
        }
    }
}

.tooltip-info {
    white-space: pre;
    font-size: 0.75rem;
    font-weight: normal;
    text-transform: none;
    line-height: normal;
    color: $gray90;
    min-width: 10rem;
}

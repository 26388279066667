
@import '../../assets/scss/palette';

.label {
    background: transparentize($orange, 0.95);
    border: 1px dashed $orange;
    border-radius: 2px;
}

.text {
    color: $orange;
}


@import '~@/assets/scss/palette';
@import '~@/assets/scss/z-indexes';

.draggable-wrapper {
    position: relative;

    .arrow-right,
    .arrow-left {
        position: absolute;
        z-index: $overlap-smth-z-index;
    }

    .arrow-right-icon,
    .arrow-left-icon {
        cursor: pointer;
        width: 2rem;
        height: 2rem;
        border-radius: 50%;

        &:hover {
            background-color: $blue15;
        }
    }

    .arrow-right-icon {
        padding: 7px 6px 7px 8px;
    }

    .arrow-left-icon {
        padding: 7px 8px 7px 6px;
    }

    .arrow-left,
    .arrow-right {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 100%;

        .left-fade,
        .right-fade {
            position: absolute;
            width: 7.5rem;
            height: 100%;
            z-index: $hide-smth-z-index;
            pointer-events: none;
            background-image: linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
        }
    }

    .arrow-left {
        left: 0;
    }

    .arrow-right {
        right: 0;
    }

    .draggable {
        position: relative;
        cursor: grab;
        scroll-behavior: smooth;

        &:active {
            cursor: grabbing;
            scroll-behavior: auto;
        }
    }

    .draggable-content {
        width: fit-content;
    }
}

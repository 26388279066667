
@import '~@/assets/scss/palette';

.description-highlight {
    padding: 1.5rem 0;
    margin: 0 auto;

    .description-h {
        font-size: 0.812rem;
        font-weight: 600;
        line-height: 2;
        color: $gray90;
    }

    .description-info {
        padding: 0.75rem 1.25rem;
        background-color: $dirty-white;
        font-size: 0.812rem;
        font-weight: normal;
        color: $gray60;
    }
}

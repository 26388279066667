
@import '~@/assets/scss/palette';
@import '~@/assets/scss/mixins';

.transactions-filter-picker {
    display: flex;
    max-width: calc(100vw - 330px);

    @include tablet {
        flex-wrap: wrap;
    }
}

.time-range-type-picker {
    width: 205px;
}

.date-picker ::v-deep .datepicker-wrapper {
    min-width: 9rem;
    width: 9rem;
}

.transactions-label {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 0.875rem;
    color: $gray60;
}


@import '@/assets/scss/base';
@import '@/assets/scss/icons';
@import '@/assets/scss/palette';

$icons-path: '@/assets/icons/';

.ba-container {
    margin: 50px;
    font-size: 14px;
    color: $gray30;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border: 1px solid $gray10;
    height: 80%;
}

.ba-sidebar {
    min-width: 25%;
    //background-color: $steel;
    //color: $white;
    // border-radius: 15px 0px 0px 15px;
    border-right: 1px solid $steel;
    padding: 15px 0px 15px 0px;
}

.ba-sidebar-summary {
    height: 100px;
    padding-left: 20px;
    flex-grow: 1;
    color: $navy;
    //border-bottom: 1px solid $blue40;
    //background-color: $steel;
    justify-content: left;
    align-items: center;
}

.ba-sidebar-button {
    display: flex;
    cursor: pointer;
    height: 40px;
    padding-left: 20px;
    flex-grow: 1;
    border-top: 1px solid $gray4;
    opacity: 1;
    background-color: $white;
    justify-content: left;
    align-items: center;
    // background-color: $gray4;
}

.ba-sidebar-button:last-child {
    border-bottom: 1px solid $gray4;
}

.ba-sidebar-button:hover {
    background-color: $gray10;
    color: $white;
    //opacity: 0.75;
    transition: background-color 0.2s ease-out, color 0.2s ease-out;
    -moz-transition: background-color 0.2s ease-out, color 0.2s ease-out;
    -webkit-transition: background-color 0.2s ease-out, color 0.2s ease-out;
    -o-transition: background-color 0.2s ease-out, color 0.2s ease-out;
}
.ba-content {
    //background-color: $gray5;
    padding: 30px;
    // border-radius: 0px 15px 15px 0px;
    flex-grow: 1;
}

.content-table {
    // max-width: 50%;
}

.content-header {
    color: $navy;
}

.content-table .table th {
    text-align: right;
    width: 50%;
}

@import './palette';

.section {
    padding-bottom: 2rem;
}

.section-title {
    font-size: 18px;
    color: $gray90;
    font-weight: 600;
    padding-bottom: 20px;
}

.section div:last-child {
    padding-bottom: 0;
}

.sub-section {
    padding-bottom: 16px;
}

.sub-section-title {
    font-size: 0.75rem;
    font-weight: 600;
    color: $gray90;
    line-height: 1.625rem;
    margin-bottom: 0.25rem;
}

.explanation {
    line-height: 1.25rem;
    color: $gray60;
    font-size: 0.75rem;
    font-weight: 500;

    &.error{
        color: $red;
    }
}

.inline-text {
    font-size: 0.75rem;
    text-transform: uppercase;
    font-weight: bold;
    color: $gray-blue;
    margin-right: 0.75rem;
}

.min-width {
    min-width: fit-content;
}

.group-checkbox {
    margin-left: 0.75rem;
    align-self: center;
}

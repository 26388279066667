
@import '~@/assets/scss/_palette.scss';
@import '~@/assets/scss/_consistency';
@import '~@/assets/scss/_palette.scss';
@import '~@/assets/scss/_layout.scss';

.label {
    display: flex;
    align-items: center;

    // TODO: Replace with typography font class
    font-size: 14px;
    font-weight: 600;
    color: $gray90;
    line-height: 26px;
    margin-bottom: 4px;

    &.small {
        font-size: 0.75rem;
    }
}

.label-text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.label-wrapper {
    display: flex;
    justify-content: space-between;
}

.input-optional {
    font-weight: 600;
    font-size: 0.75rem;
    font-style: italic;
    align-items: center;
    color: $gray60;
}

.border-none {
    ::v-deep .multiselect__tags {
        border: none !important;
    }
}

.transparent-background {
    ::v-deep .multiselect__single {
        background-color: transparent !important;
    }

    ::v-deep .multiselect__tags {
        background-color: transparent !important;
    }

    ::v-deep .multiselect__input {
        background-color: transparent !important;
    }
}

.transparent-background.multiselect--disabled {
    background-color: transparent !important;
}

.blue-arrow {
    ::v-deep .multiselect__select {
        &:before {
            border-color: $gray-blue transparent transparent !important;
        }
    }
}

.error-message {
    padding-top: $spacing-xs;
    font-size: 0.75rem;
    color: $red;
}

::v-deep .multiselect__content-wrapper {
    margin-top: 0.25rem;
    border-radius: 0.25rem;
    border: none;
    box-shadow: 0 0.25rem 0.5rem 0 rgba(51, 81, 149, 0.3);
}

::v-deep .multiselect {
    min-height: 32px;
}

::v-deep .multiselect__tags {
    min-height: 32px;
}

.tooltip-info {
    white-space: pre;
    font-size: 0.75rem;
    font-weight: normal;
    text-transform: none;
    line-height: normal;
    color: $gray90;
    min-width: 10rem;
}

.verified {
    &:after {
        content: '';
        position: absolute;
        top: $spacing-xs;
        right: $spacing-xs;
        width: 1rem;
        height: 1rem;
        background: url('../../../assets/icons/input-verified.svg');
    }

    ::v-deep .multiselect__single {
        background-color: transparent !important;
    }
}
::v-deep .multiselect__option--highlight::after {
    font-weight: bold;
    line-height: 2rem;
    font-family: 'Open Sans', serif;
    background-color: transparent;
    color: $blue-400;
}


@import '~@/assets/scss/palette';
@import '~@/assets/scss/typographyV2';
@import '~@/assets/scss/consistency-colors';

.layoutJSON {
    background: #ddd;
    border: 1px solid black;
    margin-top: 10px;
    padding: 10px;
}

.button-wrapper {
    padding: 10px;
}

.header-btn {
    margin-right: $spacing-s;
}

.columns {
    -moz-columns: 120px;
    -webkit-columns: 120px;
    columns: 120px;
}

.vue-grid-layout {
    background: #eee;
}

.vue-grid-item .resizing {
    opacity: 0.9;
}

.vue-grid-item .static {
    background: #cce;
}

.static-card {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    border-radius: 0.5rem;
    background-color: #d8d8d8;
    padding: 1.5rem 2rem;
    height: 400px;
}

.app-card {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    border-radius: 0.5rem;
    background-color: $white;
    padding: 1.5rem 2rem;
    height: 100%;
}

.divider {
    margin-top: 5px;
    margin-bottom: 5px;
    height: 1px;
    background-color: black;
}

.break {
    flex-basis: 100%;
    height: 0;
}

.icon {
    margin-bottom: 0.25rem;
}

.static-title {
    font-size: 1.375rem;
    font-weight: 600;
    color: 'black';
    margin-bottom: 0.25rem;
}

.static-inner-card {
    margin-right: 50px;
    width: 500px;
}

.tile-title {
    font-size: 1.375rem;
    font-weight: 600;
    color: $gray90;
    margin-bottom: 0.25rem;
}

.tile-content {
    color: $black;
}

.layout-input {
    width: 25%;
    margin-right: 1em;
}

.layout-select {
    width: 25%;
    margin-right: 1em;
}

.add-section-content {
    background: $gray-100;
    padding: $spacing-s;
}

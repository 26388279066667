
.content {
    height: 100%;
}
.mapping {
    display: flex;
    align-items: flex-end;
}

.map-role-input {
    flex-grow: 1;
}

.map-role-select {
    width: 25%;
    margin-right: 1em;
}


@import '~@/assets/scss/editor-layout-v2';
@import '~@/assets/scss/typographyV2';
@import '~@/assets/scss/palette';
@import '~@/assets/scss/icons';
@import '~@/assets/scss/animations';

// TODO: delete stuff like this
$icon-path: '~@/assets/icons/';

.w-60 {
    width: 60%;
}

.end-line-content {
    height: 1rem;
}

.property-editor-modal-wrapper {
    padding: 2rem 2rem 1.5rem 2.5rem;
}

.dnd-properties-wrapper {
    padding-left: 2rem;
}

.dnd-group-wrapper,
.dnd-property-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    background-color: $blue15;
    padding: 0.5rem 1.5rem 0.5rem 1rem;
    font-size: $text-sm;
    font-weight: $medium-font-weight;
    color: $blue;

    &:hover .dnd-item-right-part {
        transform: translateX(0);
    }
}

.dnd-group-wrapper-light {
    background-color: $blue5;
}

.dnd-item-left-part,
.dnd-item-right-part {
    display: flex;
    align-items: flex-start;
    align-items: center;
    line-height: 1.86;
}

.dnd-item-right-part {
    transform: translateX(180%);
    transition: transform $medium-animation-time;
}

.group-dnd-handle,
.property-dnd-handle {
    width: 1rem;
    height: 1rem;
    margin-right: 0.5rem;
    background-image: url($icon-path + $dnd-handle);
    cursor: grab;
}

.sidebar-dnd {
    overflow-x: hidden;
}

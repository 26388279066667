
@import '~@/assets/scss/_palette.scss';
.invalid-input {
    position: relative;
    top: 0.8125rem;
    margin-top: 0.625rem;
    margin-bottom: 0.625rem;
}
.time-frame-label {
    font-weight: bold;
    font-size: 0.75rem;
    margin-right: 0.5rem;
    text-transform: uppercase;
    color: $steel;
}


@import '~@/assets/scss/_consistency';

.form-content {
    padding: $spacing-m $spacing-xxxl;
}

.error-message {
    color: $red;
}

#app .reason ::v-deep .multiselect__tags {
    padding-left: $spacing-xs;
}

.form-content ::v-deep textarea {
    width: 100%;
    padding-inline: $spacing-xs;
    border-radius: 0.5rem;

    &::placeholder {
        font-style: italic;
        opacity: 1;
        font-size: inherit;
    }
}

.confirmation-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: $spacing-m;
    border-radius: 0.5rem;

    p {
        margin-right: $spacing-m;
        font-size: 1rem;
    }

    .button-group {
        display: flex;
        gap: $spacing-xs;
    }
}


@import '~@/assets/scss/base';
@import '~@/assets/scss/palette';

.search-box-wrapper {
    position: relative;
    width: 15rem;
    height: 2.5rem;
}

.auto-height {
    height: auto !important;
}

.table-search-box {
    width: 15rem;
}

.order-wrap {
    margin-bottom: 1.75rem;
}

.modal-row {
    margin-bottom: 0.875rem;
}

.row-title {
    font-size: 0.75rem;
    font-weight: 600;
    width: 7.875rem;
}

.row-value {
    font-size: 0.875rem;
    width: 100%;
}

.plan-change {
    height: 22.25rem;
}
.plan-change {
    height: 22.25rem;
}

.plan-change-cancel {
    height: 13rem;
}

.plan-change-blackout {
    height: 12.813rem;
}
.plan-change-title {
    margin-top: 1.813rem;
    margin-left: 2.5rem;
}

.plan-change-multiselect {
    margin-top: 1.75rem;
    margin-left: 2.5rem;
    margin-right: 9rem;
}

.plan-change-buttons {
    margin-top: 2.813rem;
    margin-right: 2rem;
    display: flex;
    justify-content: flex-end;
}

.plan-change-text {
    font-size: 0.875rem;
    margin-top: 1.688rem;
    margin-left: 2.5rem;
    margin-right: 2.375rem;
}
.plan-change-text-bold {
    font-weight: bold;
}


@import '~@/assets/scss/palette';
@import '~@/assets/scss/layout';
@import '~@/assets/scss/typography';
@import '~@/assets/scss/consistency-typography';

#localization-save-button {
    position: fixed;
    right: 4%;
    bottom: 4%;
}

.edit-homepage {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 2.5rem;
    min-width: min-content;
    padding: $spacing-xs $spacing-xxl;
    border: 0;
    border-radius: 3.125rem;
    cursor: pointer;
    // necessary for proper icon size since it inherits fs
    text-decoration: none;
    font-size: 1rem;
    line-height: 2.5rem;
    color: $white;
    background-color: $blue-400;
    box-shadow: 0 4px 8px 0 rgba($blue-400, 0.3);
    transition: 0.2s;

    &:hover {
        background-color: $blue-500;
    }

    &:active {
        background-color: $blue-600;
    }
}


@import '~@/assets/scss/layout';
@import '~@/assets/scss/_typographyV2';
@import '~@/assets/scss/palette';

.language-picker {
    width: 7.3125rem;
    margin-left: $spacing-xl;
}

.tooltip-position {
    position: absolute;
    bottom: -5px;
}

ul li::before {
    content: '\2022'; /* Add content: \2022 is the CSS Code/unicode for a bullet */
    color: $blue;
    display: inline-block;
    width: 1em;
}


@import '~@/assets/scss/consistency-colors';
@import '~@/assets/scss/typographyV2';

.additional-info {
    color: $blue;
}

.title {
    color: $blue;
    font-size: $text-sm;

    .amount {
        font-size: $text-md;
    }
}


@import '~@/assets/scss/_consistency';

.form-content {
    padding: $spacing-m $spacing-xxxl;
}

.error-message {
    color: $red;
}


@import '~@/assets/scss/palette';

.graph-dropdown {
    min-width: 500px;
}

.graph-select-row {
    background-color: $white;

    > div {
        margin-right: 2rem;
    }
}

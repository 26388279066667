
@import '~@/assets/scss/palette';
@import '~@/assets/scss/typographyV2';
@import '~@/assets/scss/layout';

.control-bar {
    margin-left: auto;

    .new-note-btn {
        width: 6.875rem;
    }

    .search-box-wrapper {
        position: relative;
        width: 2.9rem;
        height: 2.5rem;

        &.opened {
            width: 15rem;
        }
    }
}

.notes-modal-form {
    .notes-modal-header {
        margin: $spacing-xxl $spacing-xxxl 0;
    }
    .notes-modal-textarea {
        width: 36rem;
        margin-top: $spacing-xxxl;
        margin-left: $spacing-xxxl;
    }
    .modal-footer {
        margin-top: 4rem;
        padding: $spacing-l $spacing-xxl;
        border-top: solid 0.0625rem rgba($color: $blue, $alpha: 0.15);
    }
}

.modal-title {
    font-size: 1.375rem;
    font-weight: 600;
    color: $gray90;
    margin-bottom: 2.1875rem;
}

.modal-row {
    margin-bottom: 0.875rem;
}

.row-title {
    font-size: 0.75rem;
    font-weight: 600;
    flex: 0 0 7.875rem;
    align-self: baseline;
}

.row-value {
    flex: 1 1 auto;
    min-width: 0;
    padding-right: 0.5rem;
    font-size: 0.875rem;
    word-wrap: break-word;
}

.table-search-box {
    width: 15rem;
}

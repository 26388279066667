
@import '~@/assets/scss/palette';
@import '~@/assets/scss/typographyV2';
@import '~@/assets/scss/layout';

.cards-container {
    display: flex;
    justify-content: flex-start;
}

.no-active-appointments-placeholder {
    width: 17.1875rem;
    height: 5rem;
    margin-bottom: 0;
    border-radius: 0.5rem;
    box-shadow: 0 0.125rem 0.375rem 0 rgb(51 81 149 / 30%);
    background-color: $white;

    display: flex;
    justify-content: center;
    align-items: center;

    color: $gray-blue;
}

.appointment-section-content {
    padding-bottom: $spacing-xxxl;
}

.appointments-section-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
    padding-right: 1rem;
}

.appointment-modal-form {
    .appointment-modal-header {
        margin: $spacing-xxl $spacing-xxxl 0;
    }

    .appointments-change-appointment-section {
        margin-left: $spacing-xxxl;
    }
    .appointment-label {
        margin: $spacing-s $spacing-xxxl $spacing-xxs;
    }

    .appointment-date-picker {
        width: 18rem;
        margin-left: $spacing-xxxl;
    }

    .appointment-dropdown {
        width: 18rem;
        margin: $spacing-s $spacing-xxxl;
    }

    .appointment-note-textarea {
        width: 36rem;
        margin-left: $spacing-xxxl;

        .additional-label {
            font-style: italic;
            color: $gray60;
        }
    }

    .appointment-notify-toggle {
        margin: $spacing-s $spacing-xxxl 0;
    }

    .modal-footer {
        margin-top: 4rem;
        padding: $spacing-l $spacing-xxl;
        border-top: solid 0.0625rem rgba($color: $blue, $alpha: 0.15);
    }
}


@import 'src/assets/scss/palette';
@import 'src/assets/scss/typography';
@import '~@/assets/scss/mixins';

.background-color {
    background-color: $dirty-white;
}

.section-layout {
    margin: 0 1.5rem 1.5rem 1.5rem;
}

.table-header-row {
    //searchBox is dictating height, it is in px, so this should also be in px
    min-height: 36px;
}

.search-box-wrapper {
    position: relative;
    width: 15rem;
    height: 2.5rem;
}

.component-title {
    font-size: 1rem;
    font-weight: 600;
    color: $gray90;
}

.modal-title {
    padding: 2rem 2.5rem 2.1875rem;
}

.modal-buttons {
    padding: 1.4375rem 2rem;
}

.gray-note {
    padding: 0.6875rem 2.5rem;
}

.refund-input {
    padding: 2.125rem 2.5rem;
}

strong {
    color: $navy;
}

.link:hover {
    color: $blue;
    text-decoration: underline;
}

.tags-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.tag-margins {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
}

.table-search-box {
    width: 15rem;
}

.plan-change-blackout {
    height: 12.813rem;

    .plan-change-title {
        margin-top: 1.813rem;
        margin-left: 2.5rem;
    }

    .plan-change-text {
        font-size: 0.875rem;
        margin-top: 1.688rem;
        margin-left: 2.5rem;
        margin-right: 2.375rem;
    }

    .plan-change-buttons {
        margin-top: 2.813rem;
        margin-right: 2rem;
        display: flex;
        justify-content: flex-end;
    }
}

.table-wrapper {
    padding-bottom: 16px;
    min-height: 100px;
    height: var(--table-wrapper-height);
}

.table-container {
    margin: 1rem 0rem 0rem 2.5rem;
}

.all-caps-text {
    margin-right: 0rem;
}

.table-cell {
    line-height: 40px;
}
.section-balances {
    display: flex;
    justify-content: space-between;

    @include tablet {
        display: block;
    }
}


@import '~@/assets/scss/palette';

.tree-node {
    margin: 0.625rem 0 0 0;
    border: 0.0625rem solid $gray5;
    border-radius: 0.5rem;

    &__operations {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__op-select {
        width: 21.875rem;
    }

    &__btns {
        display: flex;
    }

    &__btn {
        margin-left: 0.625rem;
    }

    &__info {
        padding: 1.25rem 0 0 0;
    }

    &__info-key {
        width: 6.25rem;
    }

    &__info-element {
        margin-top: 0.3125rem;
        display: flex;
        align-items: center;
    }

    &__args {
        margin-top: 0.9375rem;
    }

    &__nested-label {
        margin-top: 0.625rem;
        font-weight: 700;
    }

    &__error {
        border-color: $red;
    }
}

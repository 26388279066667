
@import '~@/assets/scss/editor-layout-v2';
@import '~@/assets/scss/typographyV2';
@import '~@/assets/scss/palette';
@import '~@/assets/scss/icons';
@import '~@/assets/scss/animations';
@import '~@/assets/scss/z-indexes';

.cs-form-wrapper {
    width: 80%;
}

.remove-condition {
    font-size: 0.75rem;
    font-weight: 600;
    line-height: 1.86;
    color: $blue;
    cursor: pointer;
}

.condition-wrapper {
    margin-top: 0.75rem;
    padding-top: 0.75rem;
    border-top: solid 1px $blue15;
    border-bottom: solid 1px $blue15;
}

.condition-label {
    text-transform: uppercase;
    font-size: 0.625rem;
    font-weight: bold;
    line-height: 2;
    color: $steel;
    margin-right: 0.75rem;
}

.condition-text {
    font-size: 0.625rem;
    line-height: 2.17;
    font-weight: 600;
    color: $gray90;
}

.conditionError {
    border: 1px solid $red;
    border-radius: 1rem;
    padding: 1rem;
}

.version-item {
    font-size: 0.875rem;
    padding: 1rem 1.5rem;
    cursor: pointer;
    position: relative;

    &:hover {
        background-color: $blue5;

        .version-details {
            display: block;
        }
    }

    &.active {
        color: $blue;
        background-color: $blue15;
    }

    .icon {
        position: relative;
        top: 3px;
    }
}

.version-details {
    position: absolute;
    top: 2rem;
    transform: translateY(-10%);
    display: none;
    width: 21.25rem;
    height: auto;
    color: $gray60;
    transition: $fast-animation-time;
    z-index: $overlap-smth-z-index;

    .info {
        background-color: $gray5;
        padding: 0.75rem 1.25rem;
        font-size: $text-sm;

        .property-name {
            font-weight: $medium-font-weight;
        }
    }
}

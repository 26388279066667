
@import '~@/assets/scss/palette';

.all-caps-text {
    margin-bottom: 1.125rem;
}

.modal-title {
    font-size: 1.375rem;
    font-weight: 600;
    color: $gray90;
    margin-bottom: 2.1875rem;
}

.modal-row {
    margin-bottom: 0.875rem;
}

.row-title {
    font-size: 0.75rem;
    font-weight: 600;
    width: 10rem;
}

.row-value {
    font-size: 0.875rem;
    width: 100%;
}

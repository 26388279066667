
@import '~@/assets/scss/_colors';
@import '~@/assets/scss/_palette';
@import '~@/assets/scss/_icons';
@import '~@/assets/scss/_animations';

$icon-path: '~@/assets/icons/';

.editor {
    background-color: $white;
    display: flex;
    flex-flow: column;
    height: 100%;
}

.editor-container {
    padding: 1.875rem 1.5rem 0 1.5rem;
}

.editor-controls {
    display: flex;
    justify-content: flex-end;
}

.section-title {
    font-size: 1.125rem;
    color: $gray90;
    font-weight: 600;
}

.sub-section-title {
    font-size: 0.75rem;
    font-weight: 600;
    color: $gray90;
    line-height: 1.625rem;
    margin-bottom: 0.25rem;
}

.explanation {
    line-height: 1.25rem;
    color: $gray60;
    font-size: 0.75rem;
    font-weight: 500;
}

.min-width {
    min-width: fit-content;
}

.max-width {
    max-width: fit-content;
}

.clickable-label {
    width: fit-content;
    line-height: 1.625rem;
    font-size: 0.875rem;
    color: $blue;
    font-weight: 600;
    cursor: pointer;

    &.plus {
        margin-left: 0.75rem;
    }

    &.plus::before {
        content: url($icon-path + $icon-plus-blue);
        margin-right: 0.25rem;
    }

    &.plus.disabled::before {
        content: url($icon-path + $icon-plus-blue-disabled);
    }

    &.disabled {
        cursor: default;
        color: $gray-blue;
    }

    .listed-ranges-font {
        font-size: 0.75rem;
    }
}

.label {
    color: $gray-blue;
    font-size: 0.75rem;
    font-weight: 700;
}

.subsection-title {
    line-height: 1.625rem;
    font-size: 0.75rem;
    color: $gray90;
    font-weight: 600;
}

.section-border {
    border-bottom: solid 1px rgba(51, 81, 149, 0.15);
    border-top: solid 1px rgba(51, 81, 149, 0.15);
}

.width-fit-content {
    width: fit-content;
}

.holiday-rule {
    height: 2.5rem;
    border-radius: 8px;

    &.holiday-enabled {
        background-color: rgba($color: $blue, $alpha: 0.15);
    }
}

.holiday-label {
    height: inherit;
}

.trash-icon {
    cursor: pointer;
    align-self: center;
    height: 1.25rem;
    width: 1.25rem;
}

.date-min-width {
    min-width: 14.3rem;
}

.daily-section-content {
    @media (max-width: 1275px) {
        flex-direction: column;
    }
}

.daily-section-creating {
    flex-direction: column;

    @media (min-width: 1275px) {
        width: 60%;
    }
}

.daily-section-listing {
    max-width: 36.25rem;

    @media (max-width: 1275px) {
        margin-top: 1.5rem;
    }
    @media (min-width: 1275px) {
        width: 40%;
    }
}

.day-card {
    cursor: pointer;
    padding: 0.5rem 0.75rem 0.5rem;
    border: solid 1px rgba(51, 81, 149, 0.15);
    border-radius: 0.5rem;
    height: 2rem;

    &.chosen-card {
        background: $blue15;
    }

    &.selected-card {
        background: $blue;
    }

    &.invalid {
        border-color: $red;
    }

    .day-card-label {
        margin-left: 0.75rem;
        line-height: 1.625rem;
        font-size: 0.75rem;
        color: $blue;
        font-weight: 600;

        &.selected-label {
            color: white;
        }
    }
}

.time-range-min-width {
    min-width: 21.75rem;
}

.time-max-width {
    max-width: 7.5rem;
}

.listed-day-wrapper {
    background: $blue15;
    border-radius: 0.5rem;
    width: 100%;
    min-height: 2.5rem;
}

.hide-element {
    display: none;
}

.apply-to-all {
    margin-left: 12.5rem;
}

.timeframe-row {
    flex-direction: row;
    display: flex;
    align-items: center;
}

.multiselect-font {
    ::v-deep .multiselect__single {
        line-height: 1.625rem !important;
    }

    ::v-deep .multiselect__input {
        line-height: 1.625rem !important;
        font-weight: 700 !important;
    }
}
.all-day-toggle {
    min-width: 6rem;
}


@import '~@/assets/scss/_consistency.scss';

.multiselect-custom-option {
    max-width: 22rem;
    overflow: hidden;

    .option-name-row {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: space-between;

        h5 {
            flex-grow: 1;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}

/* -------------------------------- 
Typography
-------------------------------- */
@import 'layout';
@import 'consistency-spacing';
@import 'palette';

$font-primary: 'Open Sans';
$font-secondary: sans-serif;

/* set base values */
$text-base-size: 0.75rem;

/* type scale */
$text-xs: 0.75rem;
$text-sm: 0.875rem;
$text-md: 1rem;
$text-lg: 1.375rem;
$text-xxl: 3.375rem;

/* line-height */
$label-line-height: 2.17;
$heading-line-height: 1.64;
$body-line-height: 1.5;
$normal-line-height: normal;

/* font-weight */
$normal-font-weight: normal;
$medium-font-weight: 600;
$bold-font-weight: bold;

body {
    /* TODO once we make new colors design system it will be nice to link the default color here */
    font-size: $text-base-size;
    font-family: $font-primary;
}

/* text size */
h1, .text--xxl {
    font-size: $text-lg;
}

h2, .text--xl {
    font-size: $text-lg;
}

h3, .text--lg {
    font-size: $text-md;
}

h4, .text--md {
    font-size: $text-md;
}

.text--sm {
    font-size: $text-sm;
}

.text--xs {
    font-size: $text-xs;
}

/* text size with style */

.lf-error-message {
    font-size: $text-xs;
    color: $red;
}

.lf-title {
    font-size: $text-lg;
    font-weight: $medium-font-weight;
    line-height: $heading-line-height;
    margin-bottom: $spacing-l;
    color: $gray90;
}

.lf-subtitle {
    font-size: $text-md;
    font-weight: $medium-font-weight;
    line-height: $normal-line-height;
    margin-bottom: $spacing-l;
    color: $gray90;
}

.lf-subtitle-blue {
    font-family: $font-primary;
    font-size: $text-md;
    font-weight: $normal-font-weight;
    line-height: $normal-line-height;
    color: $blue;
}

.lf-table-title {
    font-size: $text-sm;
    font-weight: $medium-font-weight;
    line-height: $body-line-height;
    color: $gray90;
}

.lf-primary-text {
    font-size: $text-sm;
    font-weight: $normal-font-weight;
    line-height: $body-line-height;
    color: $gray90;
}

.lf-secondary-text {
    font-size: $text-xs;
    font-weight: $normal-font-weight;
    line-height: $heading-line-height;
    color: $gray60;
}

.lf-placeholder {
    font-size: $text-sm;
    font-weight: $normal-font-weight;
    line-height: $body-line-height;
    font-style: italic;
    color: $gray30;
}

.lf-labels {
    font-size: $text-xs;
    font-weight: $medium-font-weight;
    line-height: $label-line-height;
}

.lf-all-caps {
    font-size: $text-xs;
    font-weight: $bold-font-weight;
    line-height: $heading-line-height;
    text-transform: uppercase;
    color: $blue;
}

b, strong {
    font-weight: bold;
}

/* utility classes */
.truncate {
    // truncate text if it exceeds parent
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}


@import '~@/assets/scss/palette';

.condition-title {
    font-size: 0.75rem;
    font-weight: 600;

    margin-bottom: 1rem;
    margin-right: 1rem;

    color: $gray90;

    text-transform: capitalize;
}

.schedule {
    display: flex;
    flex-wrap: wrap;
}

.days-container,
.time-frames {
    flex: 1;
}

.days {
    display: flex;
    flex-wrap: wrap;
}

.day {
    width: 50px;
    height: 32px;

    border-radius: 0.5rem;
    // just to make rgba color work on the border
    background-clip: padding-box;
    border: solid 1px $blue15;

    background-color: $white;

    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 0.75rem;
    font-weight: 600;

    color: $blue;

    margin: 0.25rem;

    &:first-child {
        margin-left: 0;
    }

    &:last-child {
        margin-right: 0;
    }

    &.active {
        background: $blue15;
    }
}

.time-frame-label {
    font-weight: bold;
    font-size: 0.75rem;

    margin-right: 0.5rem;

    text-transform: uppercase;

    color: $steel;
}

.trash-placeholder {
    width: 16px;
    height: 16px;
    color: transparent;
    content: '';
}

.time-slot-value {
    width: 100px;
}

.cursor-pointer {
    cursor: pointer;
}

.add-timeslot {
    font-size: 0.75rem;

    display: flex;
    align-items: center;

    margin-top: 1rem;
}

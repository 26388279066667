
@import '~@/assets/scss/palette';
@import '~@/assets/scss/icons';
@import '~@/assets/scss/animations';
@import '~@/assets/scss/z-indexes';

$icon-path: '~@/assets/icons/';

.icon {
    height: 32px;

    &::before {
        content: url($icon-path + $camera);
    }
}

.row {
    height: 48px;
    padding-left: 50px;
    position: relative;

    &:hover {
        background: $blue5;
    }

    &.selected {
        background: $blue15;
    }

    &.root {
        &::before {
            content: url($icon-path + $arrow-up-gray);
            position: absolute;
            left: 17px;
            top: 50%;
            transform: translateY(-50%) rotate(180deg);
            transition: transform $fast-animation-time;
        }

        &.opened {
            .label {
                padding-left: 24px !important;
            }
            &:before {
                transform: translateY(-50%);
            }
        }
    }
}

.hover-row-buttons {
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    z-index: $overlap-smth-z-index;
    height: 100%;
    background-color: $dirty-white;
}

.content {
    max-height: 48px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.label {
    padding-left: 16px;
}

.empty-root {
    svg {
        transform: none;
    }
}

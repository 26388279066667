
.modal-row {
    margin-bottom: 0.875rem;

    .row-title {
        font-size: 0.75rem;
        font-weight: 600;
        width: 18rem;
    }

    .row-value {
        font-size: 0.875rem;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}


@import '~@/assets/scss/mixins';
@import '~@/assets/scss/palette';
@import '~@/assets/scss/z-indexes';

@keyframes pulse {
    0% {
        opacity: 100%;
    }
    50% {
        opacity: 30%;
    }
    100% {
        opacity: 100%;
    }
}

.file-container {
    height: 5rem;
    margin-bottom: 1rem;
    width: 33rem;
}

.file-info {
    font-size: 0.75rem;
    width: 100%;

    .basic-info {
        height: 1.625rem;
        margin-bottom: 0.5rem;
        margin-top: 0.25rem;
    }
}

.file-text {
    color: $gray90;
    line-height: 2.17;
    height: 1.625rem;
    font-size: 0.75rem;
    font-weight: 600;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 12rem;
    overflow-x: hidden;

    &.light {
        font-weight: normal;
        color: $gray30;
    }

    &.red {
        color: $red;
    }

    &.green {
        color: $green;
    }
}

.file-loading {
    height: 0.5rem;
    width: 100%;
    position: relative;
    margin-bottom: 0.25rem;
    border-radius: 0.5rem;

    &-green,
    &-gray,
    &-red {
        height: 100%;
        border-radius: 0.5rem;
        position: absolute;
        top: 0;
        left: 0;
    }

    &-green {
        background: $green;
        z-index: $overlap-smth-z-index;

        &.pulse {
            animation: pulse 3s infinite;
            animation-fill-mode: forwards;
        }
    }

    &-red {
        background: $red;
        z-index: $overlap-smth-z-index;
    }

    &-gray {
        background: $dirty-white;
        width: 100%;
    }
}

.file-subtext {
    color: $gray30;
    line-height: 1.75;
    height: 1.25rem;

    &.error {
        color: $red;

        &.link {
            cursor: pointer;
        }
    }
}

.file-icon {
    width: 1rem;
    height: 1rem;
    margin: 0 0 0 0.5rem;

    &.hidden {
        visibility: hidden;
    }

    &.close-icon {
        cursor: pointer;
    }
}

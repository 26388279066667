
@import '~@/assets/scss/palette';
@import '~@/assets/scss/consistency';
@import '~@/assets/scss/mixins';

.heading-md {
    // remove after all component titles have consistency-colors
    color: $gray90;
}

.grant-permission {
    background: $white;
    padding: $spacing-m $spacing-xl 0;
    border-radius: 0.5rem;
}

.grant-permission-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include tablet {
        display: block;
    }
}

.grant-permission-row {
    display: flex;
    align-items: center;
    margin-right: 4.125rem;

    @include tablet {
        display: block;
    }
}

.grant-permission-item {
    width: 100%;

    &.role-item {
        margin-bottom: 1.875rem;
    }

    &.role-type-item {
        height: $spacing-xxl;
    }

    &.id-item {
        margin-bottom: 1.875rem !important;

        &.error {
            margin-bottom: 0.375rem;
        }
    }

    @include tablet {
        margin-bottom: 0.25rem;
    }
}

.grant-permission-small-item {
    max-width: 12.875rem;
}


@import '~@/assets/scss/mixins';
@import '~@/assets/scss/animations';
@import '~@/assets/scss/consistency';
@import '~@/assets/scss/z-indexes';

.elipsis {
    @include lineClamp();
}

.elipsis-2 {
    @include lineClamp(2);
}

.file {
    padding: $spacing-m;
    border: 1px solid $gray-150;
    border-radius: $spacing-s;
}

.file-image {
    width: 2rem;
    height: 2rem;
    object-fit: cover;
}

.file-actions {
    display: flex;
    gap: $spacing-m;
    color: $blue-300;
}

.file-actions ::v-deep .icon {
    padding: $spacing-xxs;
    cursor: pointer;
    transition: $fast-animation-time;

    &:hover {
        color: $blue-400 !important;
    }
}

// File progressbar
.file-loading {
    position: relative;
    width: calc(100% - #{$spacing-xxxl});
    height: $spacing-xxs;
    border-radius: $spacing-xxs;
    margin-block: $spacing-xs;
    background-color: $gray-200;
    overflow: hidden;
}

.file-loading-bar {
    position: absolute;
    inset: 0;
    z-index: $overlap-smth-z-index;
    width: 0;
    height: 100%;
    border-radius: inherit;
    background-color: $blue-400;
    transition: width $slow-animation-time;

    &.error {
        background-color: $red-400;
    }
}

.error-btn {
    border: 0;
    background: none;
}

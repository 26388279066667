
@import '~@/assets/scss/palette';
@import '~@/assets/scss/sidebar';
@import '~@/assets/scss/z-indexes';
@import '~@/assets/scss/layout';
@import '~@/assets/scss/consistency-spacing';
@import '~@/assets/scss/consistency-typography';

.page-content {
    // class in AuthorizedContainer.vue that wraps all pages content
    .page-header {
        position: fixed;
        top: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        width: calc(100% - #{$sidebarFullWidth});
        padding: $spacing-m $spacing-xxl;
        box-shadow: $box-shadow-default;
        background-color: $white;
        line-height: 1;
        z-index: $page-layout-item-z-index;
        min-height: 4.25rem;
        height: $header-height;

        &-wrap {
            min-height: $header-height;
        }
    }

    &.compact-sidebar {
        .page-header {
            width: calc(100% - #{$sidebarCompactWidth});
        }
    }

    &.disable-sidebar {
        .page-header {
            width: 100%;
        }
    }
}

.header-searchbox {
    width: 19rem;
}


@import '~@/assets/scss/animations';
@import '~@/assets/scss/consistency';

.pointer-events-none {
    &,
    ::v-deep .icon-button {
        pointer-events: none;
    }
}

.section-layout {
    transition: $slow-animation-time;

    .date-picker-position {
        margin-top: 1.8rem;
    }
}

.sidebar-title {
    font-size: 0.75rem;
    line-height: 1.25rem;
    text-transform: uppercase;
    font-weight: 700;
    color: $blue-300;
}

.block {
    margin-top: $spacing-xl;
    padding-right: $spacing-l;
}

::v-deep .hover-row-buttons .button-wrapper {
    padding-right: $spacing-m;
}

.loader {
    margin: $spacing-s;
}


@import '~@/assets/scss/palette';
@import '~@/assets/scss/_typography';

.display-mode {
    font-size: 0.85rem;
}

.address-title {
    display: flex;
    align-items: center;

    text-transform: uppercase;
    color: $gray-blue;
    font-size: 0.75rem;
    font-weight: bold;
    line-height: 2.17;
    letter-spacing: normal;
}

.edit-links {
    text-decoration: underline;
    color: $gray-blue;

    cursor: pointer;

    display: flex;
}

.buttons {
    display: flex;

    margin-top: 0.75rem;
    margin-bottom: 1rem;
}

.view-label {
    display: inline-block;
    width: 10rem;
    margin: 0;
}

.view-value {
    display: inline-block;
    margin: 0;
}

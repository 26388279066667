
@import '~@/assets/scss/animations';

::v-deep .content-modal {
    min-height: 30rem;
}

.spinning {
    animation: spin 1.4s infinite linear;
}


@import '~@/assets/scss/consistency-spacing';
@import '~@/assets/scss/consistency-colors';
@import '~@/assets/scss/consistency-typography';

.lf-table-title {
    color: $blue-400;
}

.sidebar-data {
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: baseline;
    gap: 0.875rem $spacing-m;
}

dt {
    font-weight: 600;
    white-space: nowrap;
}

dd {
    margin-bottom: 0;
    font-size: 0.875rem;
}


@import '~@/assets/scss/palette';
@import '../../../assets/scss/palette';

.label {
    display: block;
    font-size: 14px;
    font-weight: 600;
    color: $gray60;
    margin-bottom: 4px;

    &.lightColor {
        color: #e8eaeb;
    }
}

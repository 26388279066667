
@import '~@/assets/scss/palette';
@import '~@/assets/scss/icons';

.section-title {
    font-size: 1rem;
    font-weight: 600;
    color: $gray90;
}

.account-editor-wrapper {
    &.overflow-hidden {
        ::v-deep .content {
            overflow: hidden !important;
        }
    }
}

.section-margin {
    margin-bottom: 2.8125rem;
}

.address-title {
    text-transform: uppercase;
    color: $gray-blue;
    font-size: 0.75rem;
    font-weight: bold;
    line-height: 2.17;
    letter-spacing: normal;
}

.address-input-slot {
    width: 90%;
}


@import '~@/assets/scss/filter-table';
@import '~@/assets/scss/palette';

.row-value {
    font-size: 0.875rem;
    width: 100%;
}

.billing-transaction-history::v-deep .table-scroll-wrapper {
    overflow: visible !important;
}

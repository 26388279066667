
@import '~@/assets/scss/colors';
@import '~@/assets/scss/palette';
@import '~@/assets/scss/layout';
@import '~@/assets/scss/icons';

$icon-path: '~@/assets/icons/';
.double_input {
    display: flex;
    justify-content: flex-start;
    &:hover {
        .delete-btn {
            opacity: 1;
        }
    }
}
.input_item {
    margin-right: 3rem;
    min-width: 14rem;
    width: 24%;
    &.full-width {
        width: 100%;
    }
}
.delete-btn {
    cursor: pointer;
    top: 3rem;
    height: 1rem;
    transform: translateY(-50%);
    opacity: 0;
}

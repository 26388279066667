
@import '~@/assets/scss/consistency-typography';
@import '~@/assets/scss/consistency-colors';

.um-hierarchy-row {
    display: flex;
    align-items: center;

    .entity-type-label {
        min-width: 5rem;
        max-width: 5rem;
    }

    .id-label {
        display: inline-block;
        flex-shrink: 2;
        text-overflow: ellipsis;
        overflow: visible;
        white-space: nowrap;
        width: 100%;
    }

    .status-label {
        margin-right: 0.25rem;
        min-width: 4rem;
        max-width: 4rem;
    }

    .msisdn-label {
        margin-right: 0.25rem;
        min-width: 5.5rem;
        max-width: 5.5rem;
    }

    .role-label {
        display: inline-block;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        min-width: 4rem;
        max-width: 4rem;
    }

    &.active {
        background-color: $blue-200;

        .heading-sm {
            color: $blue;
        }
    }
}

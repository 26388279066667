
@import '~@/assets/scss/consistency-spacing';
@import '~@/assets/scss/consistency-colors';
@import '~@/assets/scss/consistency-typography';
@import '~@/assets/scss/typographyV2';

.field {
    display: flex;
    white-space: pre-line;

    &:not(:last-child) {
        margin-bottom: $spacing-xs;
    }
}

.key,
.value {
    font-size: $text-xs;
    line-height: $text-md;
}

.key {
    margin-right: $spacing-s;
    font-weight: 700;
    color: $text-color;
    min-width: 30%;
    width: 30%;
}

.value {
    word-break: break-all;

    &:not(.has-click) {
        color: $gray-600;
    }

    &::v-deep .dropdown-title {
        font-size: $text-xs !important;
        color: $gray-600;
    }
}

.has-click {
    cursor: pointer;
    text-decoration: underline;
}

.tooltip-info {
    font-size: 0.75rem;
    font-weight: normal;
    text-transform: none;
    color: $text-color;
    min-width: 10rem;
}

// viasat demo
.field-highlighted {
    .key,
    .value {
        color: $white;
    }
}

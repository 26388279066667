
@import '~@/assets/scss/mixins';
@import '~@/assets/scss/sidebar';
@import '~@/assets/scss/z-indexes';
@import '~@/assets/scss/layout';
@import '~@/assets/scss/consistency';

$header-height: 5rem;

.page-header {
    position: fixed;
    top: 0;
    z-index: $page-layout-item-z-index;
    display: flex;
    align-items: center;
    gap: $spacing-m;
    width: calc(100% - #{$sidebarFullWidth});
    height: $header-height;
    padding: $spacing-l $spacing-xxl;
    background-color: $white;

    // class in AuthorizedContainer.vue that wraps all pages content
    .page-content.compact-sidebar & {
        width: calc(100% - #{$sidebarCompactWidth});
    }
}

.elipsis {
    @include lineClamp();
}

.header-searchbox {
    width: 18.75rem;

    &.expanded {
        border-radius: 0.5rem;
    }

    &.expanded ::v-deep input {
        position: static;
        min-width: 9rem;
        border-radius: 0.5rem;
        padding: $spacing-s $spacing-xs $spacing-s $spacing-m;
        border-color: gray-150;
    }
}


@import '~@/assets/scss/palette';
@import '~@/assets/scss/_colors';
@import '~@/assets/scss/_icons';
@import '~@/assets/scss/_animations';

$icon-path: '~@/assets/icons/';

.table {
    margin-bottom: 0;

    &-header {
        font-weight: bold;
        padding-left: 50px;
    }

    &-content {
        height: calc(100vh - 170px);
        overflow-y: scroll;
        overflow-x: hidden;
        font-size: 14px;
    }

    &-title {
        height: 48px;
        font-size: 16px;
        align-items: center;
    }

    &-button {
        max-width: 200px;
    }
}

.description {
    &-title {
        font-size: 13px;
        font-weight: 600;
        color: $gray5;
        margin-bottom: 8px;
    }

    &-content {
        padding: 10px 12px;
        background: $white;
        border-radius: 4px;
        font-size: 14px;
        color: $gray60;
    }
}

.label-danger {
    margin: 0 16px 16px;
}

.root {
    border-bottom: 1px solid $dirty-white;
}

.empty-table-row {
    text-align: center;
    background-color: $white;
    border-bottom: 1px solid $dirty-white;
    padding-top: 3rem;
    padding-bottom: 3rem;

    .empty-table-row-text {
        opacity: 0.5;
        font-size: 1rem;
        color: $blue;
        width: fit-content;
        font-weight: 600;
    }

    .empty-table-row-icon {
        margin-right: 2rem;
        width: 100px;
        height: 100px;
    }
}

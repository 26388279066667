
@import '~@/assets/scss/consistency-colors';
@import '~@/assets/scss/consistency-typography';
@import '~@/assets/scss/consistency-spacing';
@import '~@/assets/scss/consistency-colors';

.section-dropdown-content-wrapper {
    margin: 0 $spacing-xxs;
}

.layout-tile-wrapper {
    background: $gray-100;

    .layout-tile {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        flex-wrap: wrap;
        border-radius: 0.25rem;
        background-color: white;
        padding: $spacing-m;
        height: 100%;

        .layout-tile-content {
            width: 100%;
        }

        .overflow {
            overflow-y: auto;
        }
    }
}

.layout-tile-wrapper-border {
    border-bottom: $spacing-xxs $blue-500 solid;
}

.edit-tile-wrapper-border {
    border-bottom: $spacing-xxs $blue-500 dashed;
}

.section-input,
.section-select {
    width: 20rem;
    margin-right: $spacing-m;
}

.section-btn {
    margin-right: $spacing-m;
}

.grid-lines::before {
    content: '';
    background-size: calc(calc(100% - 12px) / 12) 120px;
    background-image: linear-gradient(to right, $gray-200, 1px, transparent 1px),
        linear-gradient(to bottom, $gray-200, 1px, transparent 1px);
    height: calc(100% - (12px));
    width: calc(100% - (12px));
    position: absolute;
    background-repeat: repeat;
    margin: $spacing-s;
}

// Spacing defitions from styleguide (add link once designs finalized)
// TODO: clear out xs, s, l and xxxl once we get to get rid of them in the components

$spacing-xxs: 0.25rem; // 4px
$spacing-xs: 0.5rem;   // 8px
$spacing-s: 0.75rem;   // 12px
$spacing-m: 1rem;      // 16px
$spacing-l: 1.25rem;   // 20px
$spacing-xl: 1.5rem;   // 24px
$spacing-xxl: 2rem;    // 32px
$spacing-xxxl: 2.5rem; // 40px

@import '~@/assets/scss/palette';

.app-progress-bar {
    height: 26px;
    width: 100%;

    &.with-title {
        height: auto;
        overflow: hidden;

        .bar {
            margin-bottom: 8px;
        }

        .info {
            justify-content: space-between;
        }
    }

    .bar {
        height: 12px;
        border-radius: 8px;
        border: solid 2px $white;
        background-color: $gray5;

        .value {
            height: 100%;
            border-radius: 8px;
            border: solid 2px transparent;
            background-color: $blue;

            &.unavailable {
                border: none;
            }
        }
    }

    .info {
        justify-content: flex-end;

        .title {
            font-size: 13px;
            line-height: 15px;
            color: $gray30;
        }

        .counter {
            font-size: 12px;
            line-height: 15px;
            text-align: right;
            color: $gray30;
        }
    }
}

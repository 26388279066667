
@import '~@/assets/scss/layout';

.modal-content {
    padding: $spacing-xxl;

    h3 {
        margin-top: $spacing-xxl;
        font-weight: 800;
    }

    li {
        display: flex;
        justify-content: space-between;
    }
    .header {
        margin-bottom: $spacing-xxl;
    }

    .footer {
        padding-top: $spacing-xxl;
        border-top: 1px solid $blue15;
    }
}


@import '~@/assets/scss/consistency-spacing';
.form-row {
    display: flex;
    flex-wrap: nowrap;
    gap: $spacing-m;

    & > * {
        flex-grow: 1;
    }
}

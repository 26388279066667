
@import '~@/assets/scss/consistency';
@import '~@/assets/scss/editor-layout-v2';
@import '~@/assets/scss/typographyV2';
@import '~@/assets/scss/z-indexes';
@import '~@/assets/scss/palette';

.w-60 {
    width: 60%;
}

.w-80 {
    width: 80%;
}

.end-line-content {
    height: 1rem;
}
.labels-and-buttons-bar > * {
    margin-left: 1rem;
}
.state-label {
    height: 1.5rem;
}

.table-input {
    width: 5rem;
}

.table-multiselect {
    max-height: 2rem;
}

.link:hover {
    color: $blue;
    text-decoration: underline;
}

.pricing-input {
    max-width: 18rem;
}

.unit-picker {
    // align unit picker
    margin-right: -1rem;

    ::v-deep.multiselect-picker {
        width: 9rem;
    }
}

.modal-content-wrapper {
    margin: $spacing-l;

    height: 100%;

    display: flex;
    flex-direction: column;
}

.content-controls {
    position: sticky;
    z-index: $overlap-smth-z-index;
    bottom: 0;
    left: 0;
    width: 100%;
    margin-top: 2rem;
    display: flex;
    justify-content: flex-end;
    height: 5.56rem;
    padding: 1.5rem 2.5rem;
    border-top: 1px solid $blue15;
    background-color: $white;
}

.inline-warning {
    color: $yellow-800;
    padding: 0.5rem;
    background-color: $yellow-100;
    border: 0.063rem solid $yellow-400;
    border-radius: 0.25rem;
}

.version-item {
    font-size: 0.875rem;
    padding: 1rem 1.5rem;
    cursor: pointer;

    &:hover {
        background-color: $blue5;
    }

    &.active {
        color: $blue;
        background-color: $blue15;
    }

    .icon {
        position: relative;
        top: 3px;
    }
}

.entity-note {
    margin-left: 0.5rem;
}


@import '~@/assets/scss/_palette';
@import '~@/assets/scss/_colors';
@import '~@/assets/scss/_consistency';

.entity-overview {
    box-shadow: 0 0.063rem 0.125rem 0 $box-shadow-color;
    min-height: 25rem;
    height: 100%;
    padding: $spacing-l $spacing-xl $spacing-l $spacing-xl;

    .header-bar {
        background-color: $white;
        font-size: 0.875rem;
        font-weight: 600;
        color: $gray90;
        margin-bottom: 1rem;
        & > * {
            line-height: 2.5rem;
        }

        .header-title {
            font-size: 0.75rem;
            line-height: 1.25rem;
            text-transform: uppercase;
            font-weight: 700;
            color: $gray-blue;
        }
    }

    .languages-wrapper {
        text-align: right;
    }

    .language {
        width: 1.125rem;
        height: 1.125rem;
        padding: 0 0.625rem;
        font-size: 0.812rem;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-transform: uppercase;
        color: $gray30;
        cursor: pointer;

        &.active {
            color: $blue;
        }

        &:hover {
            background-color: $dirty-white;
            color: $blue;
        }
    }

    .entity-view {
        height: calc(100% - 3.5rem);

        .section-tab {
            background-color: $white;
            border: 0.063rem solid $dirty-white;
            border-right: none;
            /* Adding transparent border to make both columns equal height */
            border-top: 3px solid transparent;

            height: 2.5rem;

            font-size: 0.8125rem;
            font-weight: 600;
            text-transform: uppercase;
            color: $gray30;
            padding-left: 1rem;

            &.active {
                background-color: $white;
                border-top: 0.188rem solid $blue;
                border-bottom: 0.063rem solid transparent;
                color: $blue;
            }

            &:first-child {
                border-left: none;
            }

            &:last-child {
                border-right: none;
            }

            &:hover:not(.active) {
                background-color: $dirty-white;
                color: $blue;
                cursor: pointer;
            }
        }

        .entity-content {
            display: flex;
            flex-direction: column;
            height: calc(100% - 3rem);

            .section-content {
                flex-grow: 1;
                overflow-y: auto;
                max-height: calc(100vh - 15rem);
            }
        }
    }
}

.cursor-pointer {
    cursor: pointer;
}


@import '~@/assets/scss/consistency-typography';
@import '~@/assets/scss/consistency-colors';
@import '~@/assets/scss/consistency-spacing';

.entity-card {
    position: relative;
    width: 22rem;
    min-height: 6.25rem;
    padding: $spacing-m;
    margin: $spacing-xs;
    border-radius: 0.5rem;
    box-shadow: 0 0.125rem 0.375rem 0 rgb(51 81 149 / 30%);
    background-color: $white;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    .entity-card-title {
        display: flex;
        width: 100%;
        margin-bottom: $spacing-xxs;
    }

    .entity-card-content {
        width: 100%;

        .entity-card-field {
            display: flex;
            width: 100%;
            height: 2rem;
            align-items: center;

            .entity-card-label {
                width: 30%;
                min-width: 30%;
            }

            .entity-card-value {
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .entity-card-copy {
                width: 2rem;
                height: 2rem;
            }
        }
    }
}

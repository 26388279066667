
@import '~@/assets/scss/consistency-colors';
@import '~@/assets/scss/consistency-spacing';
@import '~@/assets/scss/consistency-typography';
@import '~@/assets/scss/animations';

button {
    height: 2.5rem;
    line-height: 2.5rem;
    padding: $spacing-xs $spacing-xxl;
    border-radius: 3.125rem;
    min-width: min-content;
    border: none;
    color: $white;
    background-color: $blue;
    cursor: pointer;
    box-shadow: 0 4px 8px 0 rgba($blue-400, 0.3);
    // necessary for proper icon size since it inherits fs
    font-size: 1rem;

    &.icon-left-button {
        padding-left: $spacing-xl;
        padding-right: $spacing-xxl;
        .icon {
            margin-right: $spacing-xs;
        }
    }

    &.icon-right-button {
        padding-left: $spacing-xxl;
        padding-right: $spacing-xl;
        .icon {
            margin-left: $spacing-xs;
        }
    }

    &.primary-button {
        background-color: $blue-400;
        color: $white;

        &:hover {
            background-color: $blue-500;
        }

        &:active {
            background-color: $blue-600;
        }
    }

    &.secondary-button {
        background-color: $white;
        color: $blue-400;

        &:hover {
            background-color: $gray-50;
        }

        &:active {
            background-color: $gray-200;
        }
    }

    &.termination-button {
        background-color: #fff;
        color: $red-400;

        &:hover {
            background-color: $gray-50;
        }

        &:active {
            background-color: $red-100;
        }
    }

    &.disabled {
        background-color: $gray-100;
        color: $gray-300;
        pointer-events: none;
    }

    &.tertiary-button {
        background-color: transparent;
        color: $blue-400;
        box-shadow: none;
        padding-left: 0;
        padding-right: 0;

        &.small-button {
            height: 1rem;
            font-size: 0.75rem;
        }

        &:hover {
            color: $blue-500;
        }

        &:active {
            background-color: transparent;
            color: $blue-600;
        }

        &.disabled {
            background-color: transparent;
            color: $gray-300;
            pointer-events: none;
        }
    }

    &.alert-success {
        background-color: $white;
        color: $green-800;
        box-shadow: 0px 4px 8px 0px rgba($green-400, 0.3);
    }

    &.alert-danger {
        background-color: $white;
        color: $red-800;
        box-shadow: 0px 4px 8px 0px rgba($red-400, 0.3);
    }

    &.alert-info {
        background-color: $white;
        color: $blue-600;
        box-shadow: 0px 4px 8px 0px rgba($blue-400, 0.3);
    }

    &.alert-warning {
        background-color: $white;
        color: $yellow-800;
        box-shadow: 0px 4px 8px 0px rgba($yellow-400, 0.3);
    }

    // TODO: alert

    // TODO: reuse/cleanup
    &:hover {
        cursor: pointer;
    }

    &:active {
        cursor: pointer;
    }
}
.spinning {
    animation: spin 1.4s infinite linear;
}
.opacity-0 {
    opacity: 0;
}

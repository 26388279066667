
@import '~@/assets/scss/animations';
@import '~@/assets/scss/consistency-spacing';

::v-deep {
    .header-modal {
        padding: $spacing-xxl $spacing-xxl $spacing-m;
    }
    .header-modal.border-bottom {
        border-bottom: 0 !important;
    }
    .content-modal,
    .footer-modal {
        padding: $spacing-xxl;
    }
    .header-modal .close-btn {
        top: 1.75rem;
        right: 1.75rem;
    }
}

.example {
    margin: -#{$spacing-xxl}; // Reset .content-modal padding when table is present
}

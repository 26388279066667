
@import '~@/assets/scss/typographyV2';
@import '~@/assets/scss/palette';

.card-data {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0.6rem;

    div {
        margin-left: 0.2rem;
    }
}

.custom-condition-wrapper {
    margin-top: 1.5rem;
    padding-bottom: 0.5rem;
    border-bottom: solid 1px $blue15;

    .condition-label {
        line-height: 2;
        color: $steel;
        margin-right: 0.75rem;
    }

    .condition-text {
        line-height: 2.17;
        color: $gray90;
    }
}

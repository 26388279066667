
@import '~@/assets/scss/palette';
@import '~@/assets/scss/typography';

.balances-table {
    .product-row {
        font-size: 0.875rem;
        padding: 0.75rem 1.5rem;
        background: $white;
    }

    .balance-table-header {
        font-size: 0.75rem;
        font-weight: 600;
        text-transform: uppercase;
        color: $gray-blue;
        padding: 0.1875rem 0 0.1875rem 1.5rem;
        background: $white;
        border: none;
        border-bottom: solid 0.0625rem $gray5;
    }

    .service {
        max-height: 1.5rem;
    }

    .no-balances {
        padding: 1rem;
        font-size: 1rem;
        justify-content: center;
        background: $white;
        border-bottom: solid 0.0625rem $gray5;
    }
}

.search-box-wrapper {
    position: relative;
    width: 15rem;
    height: 2.5rem;
}

.table-search-box {
    width: 15rem;
}

@import '../typography';

// Custom SCSS variables to override variables in bootstraps scss files.
$grid-gutter-width: 8px;
$font-family-base: $font-family-base;

// making -5 prefix in bootstrap spacing classes stand for 2rem instead of 3rem
$spacer: 1rem !default;
$spacers: () !default;
$spacers: map-merge(
                (
                        5: ($spacer * 2)
                ),
                $spacers
);

// Import Vanilla bootstrap and apply values that we defined above
@import '~bootstrap/scss/bootstrap';

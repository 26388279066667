
@import '~@/assets/scss/_consistency';

.form-content {
    padding: $spacing-m $spacing-xxxl;
    display: flex;
    flex-direction: column;
    gap: $spacing-m;
    max-width: 30rem;
    min-height: 20rem;
    margin-inline: auto;
}


@import '~@/assets/scss/base';
@import '~@/assets/scss/forms';
@import '~@/assets/scss/palette';
@import '~@/assets/scss/inputs';
@import '~@/assets/scss/animations';
@import '~@/assets/scss/editor-layout-v2';
@import '~@/assets/scss/typographyV2';
@import '~@/assets/scss/z-indexes';

.section-content {
    font-size: 0.75rem;
    font-weight: 600;
    margin-bottom: 1rem;
    margin-right: 1rem;
    color: $gray90;
}

.modal-content-wrapper {
    margin: $spacing-l;

    height: 100%;

    display: flex;
    flex-direction: column;
}

.content-controls {
    position: sticky;
    z-index: $overlap-smth-z-index;
    bottom: 0;
    left: 0;
    width: 100%;
    margin-top: 2rem;
    display: flex;
    justify-content: flex-end;
    height: 5.56rem;
    padding: 1.5rem 2.5rem;
    border-top: 1px solid $blue15;
    background-color: $white;
}

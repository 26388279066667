
@import '~@/assets/scss/palette';
@import '~@/assets/scss/icons';
@import '~@/assets/scss/typography';
@import '~@/assets/scss/z-indexes';

// AppDialog - fix for datepicker popup not being visible
.wrapper {
    z-index: $tooltip-z-index / 10;
}

.edit-profile-dialog-container {
    padding-bottom: 5rem;
}

.section-margin {
    margin-bottom: 2rem;
}

.gray-small-text {
    font-size: 0.75rem;
    line-height: 1.67;
    color: $gray60;
}

.section-title {
    font-size: 1rem;
    font-weight: 600;
    color: $gray90;
}

.section-margin {
    margin-bottom: 2.8125rem;
}

.toggle-label {
    min-width: 3rem;
    text-transform: capitalize;
}

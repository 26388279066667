
@import '~@/assets/scss/consistency';
@import '~@/assets/scss/mixins';
@import '~@/assets/scss/z-indexes';

.page-content-wrapper {
    display: flex;
    flex-direction: row;
    height: calc(100% - 10rem);

    .actions-sidebar {
        border-right: 0.063rem solid $gray-200;
        padding: 1rem $spacing-xl;
        min-width: 22rem;
        width: 22rem;

        &:nth-child(2) {
            min-width: 15rem;
            width: 15rem;
        }

        .execution-ids-textarea {
            ::v-deep .content {
                height: calc(100vh - 14rem);
            }
        }

        &.scrollable {
            overflow-y: auto;
            overflow-x: unset;
        }
    }

    .action-info {
        width: 100%;
        padding: 0.75rem 1.5rem;
        overflow-y: scroll;

        &.scrollable {
            overflow-y: auto;
            overflow-x: unset;
        }
    }
}

.controls-wrapper {
    position: sticky;
    z-index: $page-layout-item-z-index;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    height: 5.56rem;
    padding: $spacing-xl 0;
    border-top: 0.063rem solid $blue15;
    background-color: $white;
    overflow: visible;

    transform: translateZ(0);

    .controls-content {
        display: flex;
        justify-content: flex-end;
        width: 70%;
    }
}

.error {
    border-color: $red;
}

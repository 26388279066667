
@import '~@/assets/scss/palette';
@import '~@/assets/scss/layout';
@import '~@/assets/scss/typographyV2';
@import '~@/assets/scss/filter-table';
@import '~@/assets/scss/mixins';

.section-layout {
    padding: 0 1.5rem 1.5rem 1.5rem;
}

.container-page {
    background-color: $dirty-white;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.background-color {
    background-color: $dirty-white;
}

.editor-controls-wrapper {
    padding: 0 1.5rem 1.5rem 1.5rem;
}

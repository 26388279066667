
@import '~@/assets/scss/animations';
@import '~@/assets/scss/consistency';

.notice {
    color: $gray-300;
}

ul li:not(:last-child) {
    padding-bottom: $spacing-xxl;
    border-bottom: 1px solid $gray-100;
}

dl {
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: baseline;
    gap: 0.875rem $spacing-m;
}

@import 'palette';
@import 'consistency-spacing';
// classes for building page layouts described here]

// height of default building blocks with margins included
$header-height: 72px;
$stub-height: 70px;
$controls-height: 68px; // no margins for this one

.default-page-content-wrapper {
    min-height: calc(100% - #{$header-height});
}

.page-with-controls-content-wrapper {
    min-height: calc(100% - #{$header-height + $controls-height});
}

.page-with-stub-content-wrapper {
    height: calc(100% - #{$header-height + $stub-height});
}

.page-with-stub-and-controls-content-wrapper {
    min-height: calc(100% - #{$header-height + $stub-height + $controls-height});
}

.page-stub {
    // ToDo: refactor .white-card-bg to exclude height: 100% out of it.
    // !important will be removed after this is done. ATM it prevents layouts breaking.
    height: 2rem !important;
    font-size: 16px;
    align-items: center;
    padding: 0 16px;

    &.stub-without-padding {
        padding: 0;
    }

    .title-buttons {
        button {
            max-width: 240px;
            margin-right: 16px;
        }
    }

    button {
        min-width: 190px;
    }
}

// PAGE CONTROLS (BUTTONS ON THE BOTTOM OF THE PAGE)
.page-controls {
    display: flex;
    align-items: center;
    height: $controls-height;
    padding: 4px 32px 0;
}

// fresh design guidelines editor helper classes

// ToDo: add support for 13 inch non-retina, smaller screens

.editor-section {
    padding-bottom: 2.25rem;

    &.last-editor-section {
        padding-bottom: 1rem;
    }

    .editor-section-heading {
        padding-bottom: 1.25rem;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.63;
        font-size: 1rem;
        color: $gray90;
    }
}

.editor-input-largest {
    width: 60%;
}

.editor-input-large {
    width: 30%;
}

.editor-input-small {
    width: 15%;
}

.editor-input-smallest {
    width: 7.5%;
}


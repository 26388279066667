
@import '~@/assets/scss/palette';
@import '~@/assets/scss/icons';
@import '~@/assets/scss/mixins';
@import '~@/assets/scss/layout';
@import '~@/assets/scss/typographyV2';
@import '~@/assets/scss/z-indexes';

@import '~@/assets/scss/consistency';

.orchestration-page-container {
    height: initial !important;
    min-height: 100%;
}

.page-content-wrapper {
    padding: 0 $spacing-l $spacing-l $spacing-l;

    .control-bar {
        position: relative;
        display: flex;
        align-items: center;
        margin-bottom: $spacing-s;

        .table-search-box {
            width: 15rem;
            margin-left: auto;
        }
    }

    .execution-history-sidebar {
        top: 3.5rem;
        width: 40rem;
        height: calc(100vh - 3.5rem);

        .clone-btn {
            cursor: pointer;
        }
    }
}

.background-color {
    background-color: $dirty-white;
}

.section-layout {
    margin-bottom: $spacing-xxl;
}

.canvas-block-wrapper {
    position: relative;
    display: flex;
    border-radius: $spacing-xs;
    padding: $spacing-s $spacing-l $spacing-l;
    background-color: $white;
    margin-top: $spacing-l;
    height: 55vh;

    .zoom-in-button,
    .zoom-out-button {
        position: absolute;
        z-index: $overlap-smth-z-index;
        cursor: pointer;
    }

    .zoom-out-button {
        top: 3.25rem;
    }

    .canvas-wrapper {
        width: 100%;
        flex-shrink: 0;
        overflow: auto;

        .workflow-canvas {
            position: relative;
            /* Using important to override values applied by draw2d library inline */
            width: fit-content !important;
            height: fit-content !important;
            min-width: 100%;
            min-height: 100%;
        }
    }
}

.content-block-wrapper {
    border-radius: $spacing-xs;
    padding: $spacing-s $spacing-l $spacing-l;
    background-color: $white;
    margin-top: $spacing-l;

    &.editor-height {
        height: 60vh;
    }

    .one-info-block {
        width: 50%;

        .link {
            color: $gray90;
            font-weight: 600;
            font-size: 0.875rem;
            &:hover {
                color: $blue;
                text-decoration: underline;
                cursor: pointer;
            }
        }
    }
}

::v-deep .modal-dialog-custom {
    overflow: visible;
}


@import '~@/assets/scss/consistency';
@import '~@/assets/scss/layout';

.cardinality {
    width: 5rem;
    min-width: 4rem;
}

.delete-btn {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(100%, -50%);
    cursor: pointer;
}

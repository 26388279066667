
@import '~@/assets/scss/palette';
@import '~@/assets/scss/base';

.auto-height {
    height: auto !important;
}

.order-wrap {
    margin-bottom: 1.75rem;
    min-height: 5rem;
}

.modal-row {
    margin-bottom: 0.875rem;
}

.row-title {
    font-size: 0.75rem;
    font-weight: 600;
    width: 7.875rem;
}

.row-value {
    font-size: 0.875rem;
    width: 100%;
}


@import '~@/assets/scss/typographyV2';
@import '~@/assets/scss/layout';

.control-bar {
    margin-left: auto;
    padding-bottom: 0.5rem;
    .new-benefit-btn {
        padding: 0.75rem;
    }

    .search-box-wrapper {
        position: relative;
        width: 2.9rem;
        height: 2.5rem;

        &.opened {
            width: 15rem;
        }
    }
}

.table-search-box {
    width: 15rem;
}

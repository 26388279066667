
@import '~@/assets/scss/palette';
@import '~@/assets/scss/forms';
@import '~@/assets/scss/editor-layout-v2';
@import '~@/assets/scss/typographyV2';
@import '~@/assets/scss/z-indexes';
@import '~@/assets/scss/mixins';
@import '~@/assets/scss/animations';
@import '~@/assets/scss/_consistency-spacing.scss';

.wrapper {
    width: 80%;
}

.rule-wrapper {
    margin-top: $spacing-s;
    padding-top: $spacing-s;
    border-top: solid 1px $blue15;
    border-bottom: solid 1px $blue15;
}

.rule-label {
    color: $steel;
    margin-right: $spacing-s;
}

.remove-rule {
    font-size: $spacing-s;
    font-weight: 600;
    line-height: 1.86;
    color: $blue;
    cursor: pointer;
}

.small-gray-text {
    margin-bottom: 0.938rem;
    font-size: 0.813rem;
    color: $gray5;
}

.dnd {
    &-item {
        position: relative;
        display: flex;
        align-items: center;
        width: 100%;
        min-height: 2.5rem;
        padding: 0 3.5rem 0 1rem;
        border-radius: 0.125rem;
        border: solid 0.063rem $dirty-white;
        margin-bottom: $spacing-xxs;
        color: $gray90;

        &:hover {
            box-shadow: 0 0.125rem 0.25rem 0 $blue30;

            .name-wrapper {
                color: $gray60;
                font-weight: bold;
            }

            .handle {
                display: flex;
            }
        }
        .name-wrapper {
            cursor: pointer;
        }

        .number-wrapper {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 0;
            height: 0;
            border: 0.688 solid $gray5;
            border-radius: 1.375rem;
            margin-right: $spacing-m;

            .number {
                position: absolute;
                height: 1.125rem;
                background-color: transparent;
                color: $gray60;
            }

            &.empty {
                width: 1.375rem;
                height: 1.375rem;
                border: none;

                .number {
                    height: 0.5rem;
                    width: 0.5rem;
                    border: 0.25rem solid $gray5;
                    border-radius: 50%;
                }
            }
        }
    }
}

.version-item {
    font-size: 0.875rem;
    padding: 1rem 1.5rem;
    cursor: pointer;
    position: relative;

    &:hover {
        background-color: $blue5;

        .version-details {
            display: block;
        }
    }

    &.active {
        color: $blue;
        background-color: $blue15;
    }
    .icon {
        position: relative;
        top: 3px;
    }
}
.version-details {
    position: absolute;
    top: 2rem;
    transform: translateY(-10%);
    display: none;
    width: 21.25rem;
    height: auto;
    color: $gray60;
    transition: $fast-animation-time;
    z-index: $overlap-smth-z-index;

    .info {
        background-color: $gray5;
        padding: 0.75rem 1.25rem;
        font-size: $text-sm;

        .property-name {
            font-weight: $medium-font-weight;
        }
    }
}

.tags-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}


.service-balance-tile {
    display: flex;
    flex-direction: column;

    .product-usage {
        display: flex;
        align-items: center;

        .name {
            flex-shrink: 0;
            width: 6rem;
            height: 1rem;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

            &.sidebar-name {
                width: 12rem;
            }
        }

        .usage-progress {
            width: 100%;

            p {
                margin: 0;
            }
        }

        .status {
            flex-shrink: 0;
            width: 80px;
            text-align: center;
        }
    }

    .see-more-btn {
        align-self: center;
    }
}

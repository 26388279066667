
@import '~@/assets/scss/typographyV2';
@import '~@/assets/scss/layout';

.control-bar {
    margin-left: auto;

    .table-search-box {
        width: 15rem;
    }

    .new-note-btn {
        width: 6.875rem;
    }
}

.modal-title {
    font-size: 1.375rem;
    font-weight: 600;
    color: $gray90;
    margin-bottom: 2.1875rem;
}

.modal-row {
    margin-bottom: 0.875rem;

    .row-title {
        font-size: 0.75rem;
        font-weight: 600;
        flex: 0 0 7.875rem;
        align-self: baseline;
    }

    .row-value {
        flex: 1 1 auto;
        min-width: 0;
        padding-right: 0.5rem;
        font-size: 0.875rem;
        word-wrap: break-word;
    }
}

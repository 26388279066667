
@import '~@/assets/scss/palette';
@import '~@/assets/scss/icons';
@import '~@/assets/scss/layout';
@import '~@/assets/scss/z-indexes';

.dropdown-menu-wrapper {
    position: relative;

    .dropdown-menu {
        display: block;
        z-index: $header-dropdowns-z-index;
        position: absolute;
        right: 0;
        top: 3rem;
        min-width: fit-content;
        width: 100%;
        border: none;
        box-shadow: 0 0.0625rem 0.125rem 0 $gray30;
        max-height: 15rem;
        overflow-x: hidden;

        // scroll bar styling only works in webkit
        &::-webkit-scrollbar {
            width: 0.625rem;
            background-color: $dirty-white;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 0.3125rem;
            background-color: $gray30;
        }

        .dropdown-item {
            padding: 0 2rem 0 1rem;
            height: 2.5rem;
            width: 100%;
            line-height: 2.5rem;
            background: right 0.375rem center no-repeat;
            overflow: hidden;
            text-overflow: ellipsis;
            cursor: pointer;
            font-size: 0.875rem;
            color: $navy;
            &:hover {
                background-color: $gray5;
            }
        }
    }
}


@import '~@/assets/scss/_consistency';

.error-message {
    color: $red;
}

.form-content ::v-deep textarea {
    width: 100%;
    padding-inline: $spacing-xs;
    border-radius: 0.5rem;

    &::placeholder {
        font-style: italic;
        opacity: 1;
        font-size: inherit;
    }
}

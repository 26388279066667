
@import '~@/assets/scss/palette';
@import '~@/assets/scss/icons';
@import '~@/assets/scss/z-indexes';

$icon-path: '~@/assets/icons/';
.stepper {
    &__list {
        background: $white;
        margin: 0;
    }
    &__item {
        padding: 1.125rem 32px;
        background: $white;
        cursor: pointer;
        user-select: none;
        flex: 1;
        &:not(:last-of-type) {
            &:before,
            &:after {
                content: '';
                display: block;
                width: 0;
                height: 0;
                border-top: 1.75rem solid transparent;
                border-bottom: 1.75rem solid transparent;
                border-right: 0.375rem solid transparent;
                border-left: 0.625rem solid #eceef1;
                position: absolute;
                right: -1rem;
                top: 0;
                z-index: $overlap-smth-z-index;
            }
            &:after {
                border-top-width: 1.68rem;
                border-bottom-width: 1.68rem;
                border-right-width: 0.5rem;
                border-left: 0.5rem solid $white;
                top: 0.07rem;
            }
        }
        &.is-active {
            background: $blue;
            &:before {
                border-color: transparent transparent transparent $blue;
            }
            &:after {
                display: none;
            }
        }
    }
    &__item-text {
        color: $blue;
        &.is-active {
            color: $white;
        }
    }
    &__item-index {
        display: inline-block;
        margin-right: 2px;
    }
    &__item-icon {
        width: 20px;
        height: 20px;
        display: inline-block;
        margin-right: 0.5rem;
        border-radius: 50%;
        &_complete {
            background: url($icon-path + $check-mark-white) center center no-repeat, $green;
            background-size: 10px;
        }
        &_edit {
            background: url($icon-path + $edit-icon-blue) center center no-repeat, $white;
            background-size: 10px;
        }
    }
}


@import '~@/assets/scss/mixins';
@import '~@/assets/scss/consistency-spacing';

.grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: $spacing-m;

    @include mobile {
        display: block;
    }
}

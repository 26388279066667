
@import '~@/assets/scss/_palette.scss';
@import '~@/assets/scss/_layout.scss';
@import '~@/assets/scss/_typographyV2.scss';

label {
    display: block;
}

.label-wrapper {
    display: flex;
    justify-content: space-between;
}

.label {
    font-size: $text-xs;
    color: $gray90;
    line-height: $label-line-height;
    font-weight: $medium-font-weight;
}

.label-text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.content {
    display: flex;

    textarea {
        width: 100%;
        border-radius: 0.5rem;
        padding: 0.3125rem 0.5rem;
        border: 1px solid $gray5;
        outline: none;
        line-height: $heading-line-height;
        font-size: $text-sm;
        color: $gray90;

        &:focus {
            border-color: $blue;
            color: $gray90;
        }

        &:disabled {
            background-color: $input-background-disabled;
            border: 1px solid $gray10;
            color: $gray30;
        }

        &.error {
            border-color: $red;
        }

        &.error-focused {
            border: 1px solid $red;
        }
    }
}

.character-count {
    text-align: right;
    margin-top: $spacing-xxs;
}

.error-message {
    margin-top: $spacing-xxs;
    font-size: $text-xs;
    line-height: $heading-line-height;
    height: 1.25rem;
    color: $red;
}

.textarea-optional {
    font-weight: $medium-font-weight;
    line-height: $label-line-height;
    font-size: $text-xs;
    font-style: italic;
    color: $gray60;
}

::placeholder {
    font-style: italic;
    color: $gray30;
}

.resize-none {
    resize: none;
}

.tooltip-info {
    white-space: pre;
    font-size: 0.75rem;
    font-weight: normal;
    text-transform: none;
    line-height: normal;
    color: $gray90;
    min-width: 10rem;
}

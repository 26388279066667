
@import '~@/assets/scss/_palette.scss';
@import '~@/assets/scss/palette';

@import '~@/assets/scss/icons';

$icon-path: '~@/assets/icons/';

.blue-arrow-down {
    width: 1rem;
    height: 1rem;
    content: url($icon-path + $blue-arrow-down);
}

.blue-arrow-up {
    width: 1rem;
    height: 1rem;
    content: url($icon-path + $blue-arrow-up);
}

.arrow-position-fix {
    margin-right: 0.5rem;
}

.explanation {
    font-size: 0.75rem;
    color: $gray60;
    margin-top: 0.5rem;
}
.half-of-input-width {
    min-width: 17.6875rem;
    width: 30%;

    &.full-width {
        width: 100%;
    }
}
.transitioning-area {
    background-image: linear-gradient(to bottom, transparent, $white);

    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 45px;
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.tags-container {
    position: relative;
}

.seeMoreButton {
    font-size: 0.75rem;
    text-align: center;
    color: $blue;
    cursor: pointer;
    font-weight: 600;
}

.expanded-container {
    height: 100%;
}

.collapsed-container {
    height: 40px;
    overflow: hidden;
}

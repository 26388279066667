
@import '~@/assets/scss/_consistency';

.form-content {
    gap: $spacing-l;

    .fccLabel {
        max-width: 20rem;

        &.labelValue {
            h5 {
                color: $gray-300;
            }
        }

        &.justifiedPrice {
            display: flex;
            justify-content: space-between;
            align-items: baseline;
        }

        &.list {
            .fccListItem {
                justify-content: space-between;
                padding: $spacing-xs 0;

                &.withBorder {
                    border-bottom: 1px solid $gray-200;
                }
            }
        }

        &.descriptions,
        &.contact {
            display: flex;
            flex-flow: column nowrap;
            gap: $spacing-xxs;
        }
    }
}


@import 'src/assets/scss/consistency';

.table-wrapper {
    padding-bottom: $spacing-m;
    min-height: 100px;
    height: var(--table-wrapper-height);
}

.table-cell {
    line-height: $spacing-xxxl;
}

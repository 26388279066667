
@import '../../assets/scss/palette';
@import '../../assets/scss/layout';
@import '../../assets/scss/icons';

$icon-path: '../../assets/icons/';

.app-link-wrapper {
    width: auto;
    height: 1.625rem;

    .app-link {
        position: relative;
        cursor: pointer;
        color: $blue;
        font-weight: 600;
        font-size: 0.875rem;
        line-height: 1.625rem;
        padding-left: $spacing-l;

        .link-icon {
            &:before {
                content: '';
                position: absolute;
                left: 0;
                top: 0.125rem;
                width: 1rem;
                height: 1rem;
                background: url($icon-path + $link-icon);
                margin-right: $spacing-xs;
            }
        }
    }
}

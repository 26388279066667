
@import '~@/assets/scss/mixins';
@import '~@/assets/scss/consistency-spacing';

.grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: $spacing-m;

    &.fixed-column-width {
        grid-template-columns: repeat(3, calc((100% - (2 * #{$spacing-m})) / 3));
    }

    @include mobile {
        display: block;
    }
}


@import '~@/assets/scss/editor-layout-v2';
@import '~@/assets/scss/typographyV2';
@import '~@/assets/scss/palette';
@import '~@/assets/scss/icons';
@import '~@/assets/scss/mixins';
@import '~@/assets/scss/animations';

$icon-path: '~@/assets/icons/';

.text-label {
    font-size: 0.875rem;
    line-height: 1.625rem;
    color: $gray60;
}

.link {
    @include linkStyle(underline);
}

.description-block {
    textarea {
        width: 100%;
    }
}

.product-type-label {
    text-align: right;
}

.row.save-button {
    justify-content: flex-end;
    margin: 1rem 2rem;
}

.buttons-row {
    margin: 0.5rem;
}

.small-gray-text {
    margin-bottom: 0.938rem;
    font-size: 0.813rem;
    color: $gray5;
}

.gray-bar {
    background-color: $white;
    padding: 0.25rem;
    font-weight: bold;
    margin-bottom: 1.125rem;

    .select {
        font-weight: initial;
    }

    button {
        font-weight: bold;
    }
}

.product-options {
    .product-option {
        margin: 0.938rem 0;
        align-items: center;

        .unit-select {
            width: 6.25rem;
        }
    }

    .threshold-config {
        border: 0.063rem solid $dirty-white;
        background-color: $white;
    }

    .gray-bar {
        background-color: $white;
    }

    .sku {
        border: 0.063rem solid $dirty-white;
        background-color: $white;
    }
}

.icon-wrap {
    height: 2rem;
    width: 2rem;
    position: relative;
    cursor: pointer;
    opacity: 0;
    transition: $fast-animation-time;

    &::before,
    &::after {
        position: absolute;
        display: block;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    &::before {
        content: '';
        height: 0;
        width: 0;
        background: $gray10;
        border-radius: 50%;
        transition: $fast-animation-time;
        background: $gray10;
    }

    &::after {
        content: url($icon-path + $eye-icon-hover);
        height: 1.5rem;
        width: 1.5rem;
    }

    &.cross-eye::after {
        content: url($icon-path + $eye-crossed);
    }

    .icon-text {
        border-radius: 0.25rem;
        opacity: 0;
        transition: $fast-animation-time;
        background: rgba($color: $gray60, $alpha: 0.15);
        padding: 0.063rem 0.813rem 0.813rem 0.188rem;
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translate(-50%, 0.063rem);
    }

    &:hover {
        .icon-text {
            opacity: 1;
        }

        &::before {
            height: 2rem;
            width: 2rem;
        }
    }
}

.dnd {
    &-item {
        position: relative;
        display: flex;
        align-items: center;
        width: 100%;
        min-height: 2.5rem;
        padding: 0 3.5rem 0 1rem;
        border-radius: 0.125rem;
        border: solid 0.063rem $dirty-white;
        margin-bottom: 0.25rem;
        color: $gray90;

        &:hover {
            box-shadow: 0 0.125rem 0.25rem 0 $blue30;

            .name-wrapper {
                color: $gray60;
                font-weight: bold;
            }

            .handle {
                display: flex;
            }

            .icon-wrap {
                opacity: 1;
            }

            .hidden-label {
                opacity: 0;
            }
        }

        .charging-spec-details {
            position: absolute;
            left: 1.875rem;
            top: 0;
            transform: translateY(-100%);
            display: none;
            width: 21.25rem;
            height: auto;
            border-radius: 0.25rem;
            color: $gray60;
            transition: 0.2s ease-in-out;

            .name {
                padding-left: 0.25rem;
                margin-bottom: 0.75rem;
                font-size: 0.875rem;
            }

            .info {
                background-color: $gray5;
                padding: 0.75rem 1.25rem;
                font-size: 0.813rem;

                .property-name {
                    font-weight: bold;
                }
            }
        }

        .name-wrapper:hover ~ .charging-spec-details {
            display: block;
        }

        .handle:hover ~ .charging-spec-details {
            display: none;
        }

        .name-wrapper {
            cursor: pointer;
            font-size: 0.875rem;
        }

        .number-wrapper {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 0;
            height: 0;
            border: 0.688 solid $gray5;
            border-radius: 1.375rem;
            margin-right: 1rem;

            .number {
                position: absolute;
                height: 1.125rem;
                background-color: transparent;
                color: $gray60;
                font-size: 0.813rem;
                font-weight: bold;
            }

            &.empty {
                width: 1.375rem;
                height: 1.375rem;
                border: none;

                .number {
                    height: 0.5rem;
                    width: 0.5rem;
                    border: 0.25rem solid $gray5;
                    border-radius: 50%;
                }
            }
        }
    }
}

.editor {
    background-color: $white;
    display: flex;
    flex-flow: column;
    height: 100%;
}

.main {
    padding: 1.875rem 3.125rem;
}

.error-layout {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 0;

    .label {
        flex: 0 1 12.5rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}

.has-text-under {
    padding: 0.5rem 0 0 0;
}

.multiselect {
    min-height: 0;
    flex: 0 1 36.5rem;
}

.multiselect-small {
    min-height: 0;
    flex: 0 1 18rem;
}

.approve-on-create {
    padding-top: 0.75rem;

    & > .approve-checkbox {
        margin-left: -0.25rem;
    }
}

.optional-label {
    margin-left: auto;
    padding-left: 1rem;
    font-size: 0.75rem;
    font-style: italic;
    align-self: flex-start;
    margin-top: 0.5rem;
    color: $gray60;
}

.footer {
    display: flex;
    justify-content: space-between;
    margin: 0.625rem 0.5rem 1.75rem 0.5xv;
}

.page-height {
    flex: 1 0 0;
}

.fixed-on-top {
    align-self: flex-start;
    margin-top: 0.25rem;
}

.right-side {
    position: sticky;
    top: 5rem;
    max-height: calc(100vh - 5.625rem);
    overflow-y: scroll;
    box-shadow: 0 0.063rem 0.125rem 0 $box-shadow-color;
    margin-top: 0.688rem;
}

.draggable-container {
    height: auto;
    min-height: 50%;
}

.hidden-label {
    display: none;
    font-size: 0.875rem;
    color: $gray90;
    font-style: italic;
}

.charging-spec-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80%;

    &.hidden-chargingSpecification {
        .hidden-label {
            display: inline;
        }
    }
}

.delete-btn {
    cursor: pointer;
    position: absolute;
    top: 1rem;
    right: 1.625rem;
    transform: translateY(-50%);
    opacity: 0;
}

.tooltip-text-width {
    width: max-content;
}

.error-font {
    color: $red;
    font-size: 0.688rem;
}

.card-slot-content {
    display: flex;
    align-items: center;
    padding-top: 1.25rem;
    margin-left: 3.25rem;

    & > p {
        font-size: 0.75rem;
        font-weight: bold;
        color: $gray-blue;
        text-transform: uppercase;
    }

    .input-wrapper {
        width: 4rem;
        margin: 0 0.5rem;
    }
}

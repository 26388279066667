
@import '~@/assets/scss/palette.scss';
@import '~@/assets/scss/layout.scss';

.unit-picker-wrapper {
    display: flex;
    align-items: center;

    .unit-picker-label {
        font-size: 0.75rem;
        text-transform: uppercase;
        font-weight: bold;
        color: $gray-blue;
        margin-right: 0.75rem;
    }

    .input-picker {
        width: 9rem;
    }

    .multiselect-picker {
        min-width: var(--multiselect-picker-width);
    }

    &.editor-design {
        flex-direction: column;
        align-items: flex-start;

        .unit-picker-label {
            color: $gray90;
            text-transform: initial;
            font-weight: 600;
            line-height: 1.625rem;
            padding-bottom: $spacing-xxs;
        }

        .multiselect-picker {
            min-width: 6rem;
        }

        .error-message {
            padding-top: $spacing-xxs;
            font-size: 0.75rem;
            line-height: 1.25rem;
            color: $red;
        }
    }
}


@import '~@/assets/scss/editor-layout-v2';
@import '~@/assets/scss/typographyV2';
@import '~@/assets/scss/palette';
@import '~@/assets/scss/icons';
@import '~@/assets/scss/z-indexes';

.flex-container {
    display: flex;
    flex-direction: row;

    &:hover {
        .delete-btn {
            opacity: 1;
        }
    }
}

.input-block {
    position: relative;
    margin-right: 1rem;
    margin-left: 1rem;

    .input-title {
        width: 12.5rem;
        font-weight: bold;
        margin-bottom: 0.625rem;
    }

    .input-content {
        width: 12.5rem;
    }
}

.delete-btn {
    top: 1.8rem;
    opacity: 0;
}

.status-one {
    margin-left: 2rem;
    margin-top: -0.2rem;
}

.show-currency {
    margin-left: 0rem;
}


@import '~@/assets/scss/consistency-typography';
@import '~@/assets/scss/mixins';

.field-wrapper {
    height: 1.5rem;
    line-height: 1.5rem;
}

.custom-value-content {
    display: flex;
    justify-content: flex-end;
}

.field-value-text {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: end;
}

.custom-value-content-wrapper {
    height: 100%;
}

.link {
    @include linkStyle(underline);
    cursor: pointer;
}

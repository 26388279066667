
@import '~@/assets/scss/mixins';
@import '~@/assets/scss/consistency';

.app-card {
    gap: $spacing-s;
    padding: $spacing-xs $spacing-xs $spacing-xs 0;
}

.editable-counter-icon {
    position: absolute;
    transform: translateX($spacing-m);
}

.elipsis {
    @include lineClamp();
}

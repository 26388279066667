
@import '~@/assets/scss/palette';

.section {
    .heading {
        margin: 0;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.63;
        font-size: 1rem;
        color: $gray90;
    }

    .sub-heading {
        font-size: 0.812rem;
        line-height: 2.15;
        color: $gray60;
    }

    .section-content {
        padding: 0.75rem 3rem;
    }
}

.approve-on-create {
    .label {
        font-size: 0.75rem;
        font-weight: 700;
        color: black;
        flex: 0 1 9rem;
    }

    & > .approve-checkbox {
        margin-left: -0.25rem;
    }
}

.input-label {
    font-weight: 600;
}

.referral-checkbox {
    &::v-deep {
        span {
            font-size: 0.75rem;
        }
    }
}

.text-gray {
    color: $gray30;
    font-weight: 400;
}

.description-textarea {
    &::v-deep {
        .label-text {
            line-height: 1.125rem;
        }

        textarea {
            margin-top: 0.5rem;
        }
    }
}

.payout-limits-text {
    color: $gray-blue;
    font-size: 0.75rem;
    font-weight: 700;
    text-transform: uppercase;
}

.condition-error-message {
    position: absolute;
    bottom: -1.5rem;
    left: 0;
    color: $red;
}

.cohort-expression {
    padding: 0.625rem;
    border: 0.0625rem solid $gray5;
    border-radius: 0.5rem;
    max-width: 45rem;
}

.conditions {
    margin: 0.625rem 0 0 0;

    &__btns {
        display: flex;
    }

    &__btn {
        margin-left: 0.625rem;
    }
}


@import '~@/assets/scss/_consistency.scss';

.card-container {
    margin: 1.5rem 0;
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    background-color: rgba($color: $blue-500, $alpha: 0.15);

    .card-title {
        color: $blue-500;
        font-size: 0.75rem;
        font-weight: 600;
    }
}


@import '~@/assets/scss/icons';
@import '~@/assets/scss/palette';
@import '~@/assets/scss/layout';
@import '~@/assets/scss/consistency';

$icon-path: '~@/assets/icons/';

.searchbox {
    height: $spacing-xxxl;
    line-height: $spacing-xxxl;
    position: relative;
    background-color: transparent;
    border: none;
    transition: all 0.5s ease-in-out;

    &:hover,
    &:focus-within,
    &.expanded {
        background-color: white;
        border-radius: 20px;
        transition: all 0.5s ease-in-out;
        &.smoothBorder {
            border-radius: 5px;
        }
        input {
            width: 100%;
            border-radius: 20px;
            transition: all 0.5s ease-in-out;
            &.smoothBorder {
                border-radius: 5px;
            }
        }
    }

    &.expanded input {
        border: 1px solid $gray-200;
    }

    &:hover,
    &:focus-within,
    &.expanded:hover,
    &.expanded:focus-within {
        input {
            border: solid 1px $blue;
        }
    }

    input {
        width: 0;
        position: absolute;
        right: 0;
        min-width: 36px;
        padding-left: 12px;
        padding-right: 28px;
        border: 0px solid $gray5;
        border-radius: 20px;
        background: transparent;

        font-size: 14px;
        transition: all 0.5s ease-in-out;

        &::placeholder {
            color: $gray5;
        }
        &.smoothBorder {
            border-radius: 5px;
        }
    }

    .right-icons {
        position: absolute;
        top: 1px;
        right: 1px;

        &:hover {
            cursor: pointer;
        }
    }

    .search-icon {
        @extend .right-icons;
    }

    .close-icon {
        @extend .right-icons;
    }
}

// This is the only file which holds z-indexes values for the whole app
// If you need a new value please put it here and use variable in the component

// in order to hide smth under smth else :)
$hide-smth-z-index: -1;

// this one is necessary sometimes, I haven't figured out how to name it nicely yet
$zero-z-index: 0;

// in order to overlap smth small (not general layout related)
$overlap-smth-z-index: 1;

// some comment should be here
$two-z-index: 2;

// icon button z-index (overlaps table rows), but not any of the layout items like sidebar/header
$icon-button-z-index: 5;

// loader/spinner z-index (overalap buttons, don't overlap additional sidebar)
$loader-z-index: 7;

// for the additional (right one) sidebar
$additional-sidebar-z-index: 10;

// any page layout items z-index
$page-layout-item-z-index: 20;

// for sub menus, tenant switcher to overlap page content when navigation (left one) sidebar is collapsed
$sidebar-stuff-z-index: 30;

// for header dropdown in order to overlap all content on the page if necessary
$header-dropdowns-z-index: 40;

// value which should be used for all modals
$modal-z-index: 10000;

// tooltip is slightly bigger than modal to overlap it in case if they overlap
$tooltip-z-index: 10010;

// value for notification (alert) that should overlap everything
$alert-z-index: 100000;


@import '~@/assets/scss/consistency';
@import '~@/assets/scss/layout';
@import '~@/assets/scss/icons';

$icon-path: '~@/assets/icons/';

.modal-content-wrapper {
    padding: $spacing-xxl $spacing-xxl 0 $spacing-xxl;

    display: flex;
    flex-direction: column;

    .form-content {
        flex-grow: 1;
    }
}


@import '~@/assets/scss/filter-table';
@import '~@/assets/scss/palette';
@import '~@/assets/scss/icons';
@import '~@/assets/scss/mixins';
@import '~@/assets/scss/layout';
@import '~@/assets/scss/typographyV2';
@import '~@/assets/scss/z-indexes';

.json-wrap {
    border: 0.063rem solid $gray5;
    border-radius: 0.5rem;
    overflow: hidden;
    height: 35rem;
    width: calc(100% - 0.5rem);
}

.invalid {
    border-color: $red;
}

.label {
    font-size: $text-xs;
    color: $gray90;
    line-height: $label-line-height;
    font-weight: $medium-font-weight;
    margin-bottom: 0.25rem;
}


@import '~@/assets/scss/palette';

.page-number {
    font-size: 0.8125rem;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 2rem;
    width: 2rem;
    color: $gray90;

    cursor: pointer;

    &.active-page {
        font-weight: 600;
        border-radius: 50%;
        background: $blue;
        color: $white;
    }
}

.entities-per-page-picker-wrapper {
    overflow: visible;
    width: 8rem;
}

::v-deep .multiselect__tags,
::v-deep .multiselect__single {
    background: transparent;
}


@import '~@/assets/scss/consistency';

.table-search-box {
    width: 15rem;
}

.filters-tags {
    padding: 0 2.5rem;
}

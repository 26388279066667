
@import '~@/assets/scss/palette';
@import '../../../assets/scss/palette';
@import '../../../assets/scss/icons';

$icon-path: '../../../assets/icons/';

label {
    color: $gray90;
    font-size: 14px;

    cursor: pointer;

    &.new-design {
        color: $blue;
    }

    span.icon {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    input[type='checkbox'] {
        -webkit-appearance: none;
        appearance: none;
        height: 0;
        width: 0;

        & + span.icon:before {
            margin-top: 6px;
            cursor: pointer;
        }

        &.check {
            & + span.icon:before {
                content: url($icon-path + $checkbox-off);
            }

            &:hover + span.icon:before {
                content: url($icon-path + $checkbox-off-hover);
            }

            &:focus + span.icon:before {
                content: url($icon-path + $checkbox-off-focus);
            }

            &:checked {
                & + span.icon:before {
                    content: url($icon-path + $checkbox-on);
                }
            }
        }

        &.no-margin-check {
            & + span.icon:before {
                height: 1rem;
                cursor: pointer;
                margin-top: 0;
            }

            & + span.icon:before {
                content: url($icon-path + $no-margin-checkbox-off);
            }

            &:hover + span.icon:before {
                content: url($icon-path + $no-margin-checkbox-off-hover);
            }

            &:focus + span.icon:before {
                content: url($icon-path + $no-margin-checkbox-off-focus);
            }

            &:checked {
                & + span.icon:before {
                    content: url($icon-path + $no-margin-checkbox-on);
                }
            }
        }

        &.eye {
            & + span.icon:before {
                content: url($icon-path + $eye-icon);
            }

            &:hover + span.icon:before {
                content: url($icon-path + $eye-icon-hover);
            }

            &:checked + span.icon:before {
                content: url($icon-path + $eye-selected);
            }

            &:checked:hover + span.icon:before {
                content: url($icon-path + $eye-selected-hover);
            }
        }
    }

    &.small {
        font-size: 0.75rem;

        input.check + span.icon:before {
            margin-top: 0;
            height: 16px;
            width: 16px;
        }
    }

    &.disabled {
        color: $gray60;
        input[type='checkbox'] {
            &.check {
                & + span.icon:before {
                    content: url($icon-path + $checkbox-disable-off);
                }

                &:checked {
                    & + span.icon:before {
                        content: url($icon-path + $checkbox-disable-on);
                    }
                }
            }

            &.eye {
                opacity: 0.5;
                pointer-events: none;
            }
        }
    }

    &.error {
        input[type='checkbox'] {
            &.check {
                & + span.icon:before {
                    content: url($icon-path + $checkbox-error-off);
                }

                &:checked {
                    & + span.icon:before {
                        content: url($icon-path + $checkbox-error-on);
                    }
                }
            }
        }
    }
}

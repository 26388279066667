
.underline-blue {
    position: relative;
    border-bottom: solid 0.0625rem rgba(51, 81, 149, 0.15);
}

.pointer {
    position: absolute;
    bottom: -5px;
    left: 1.875rem;
    border-top: solid 0.0625rem rgba(51, 81, 149, 0.15);
    border-left: solid 0.0625rem rgba(51, 81, 149, 0.15);
    border-radius: 0.125rem 0 100% 0;
    height: 0.625rem;
    width: 0.625rem;
    transform: rotate(45deg);
}

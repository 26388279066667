
@import '~@/assets/scss/_palette.scss';

.grow {
    flex-grow: 1;
}

.zone-title {
    font-size: 0.75rem;
    font-weight: 600;
    line-height: 2.17;
    color: $gray90;
    margin-bottom: 0.25rem;
}

.condition-options {
    min-width: 80px;
    width: max-content;
}

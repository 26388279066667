
@import '~@/assets/scss/_palette';
@import '~@/assets/scss/_layout';
@import '~@/assets/scss/_sidebar.scss';
@import '~@/assets/scss/_animations.scss';
@import '~@/assets/scss/z-indexes';

.editor-wrapper {
    position: relative;
    padding: 0;
    height: 100vh;
}

.editor-content-wrapper {
    display: flex;
    justify-content: center;
    height: calc(100% - 158px);

    &.sidebar-position-left {
        flex-direction: row-reverse;
    }

    &.stepper-enabled {
        padding-top: calc(50px + 1.5rem);
    }

    &.no-sidebar-layout {
        display: block;

        .form-content-wrapper {
            width: 100%;
            padding-left: 15%;
            padding-right: 15%;
        }
    }
}

.editor-controls-wrapper {
    position: sticky;
    z-index: $page-layout-item-z-index;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    height: 5.56rem;
    padding: 1.5rem 0;
    border-top: 1px solid $blue15;
    background-color: $white;

    transform: translateZ(0);

    &.sidebar-enabled {
        justify-content: flex-start;

        .editor-controls-content {
            padding-right: $spacing-l;
        }
    }

    .editor-controls-content {
        display: flex;
        justify-content: flex-end;
        overflow: visible;
    }
}

.form-content-wrapper,
.editor-controls-content {
    width: 70%;
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
}

.form-content-wrapper {
    display: flex;
    justify-content: center;
    padding-top: 2.2rem;
    margin-bottom: 1.5rem;

    &.sidebar-enabled {
        padding-left: $spacing-l;
        padding-right: $spacing-l;
    }

    .form-content {
        width: 100%;
    }
}

.sidebar-content {
    height: 100%;
    overflow-y: auto;
    box-shadow: $box-shadow-default;
    order: 1;
}
.stepper-wrap {
    padding: 0.5rem 0;
    top: 4.25rem;
    position: fixed;
    background: #eceef1;
    width: calc(100% - #{$sidebarFullWidth});
    transition: width $medium-animation-time;
    z-index: $page-layout-item-z-index;
}
// global class which indicates that sidebar is collapsed
.page-content.compact-sidebar {
    .stepper-wrap {
        width: calc(100% - #{$sidebarCompactWidth});
    }
}

.user-guide-icon-wrapper {
    position: absolute;
    bottom: 1.5rem;
    right: 1.5rem;
    z-index: $tooltip-z-index;
}


.service-details {
    background-color: var(--color-background-light);
    border: 1px solid var(--color-border);
    border-radius: 4px;
}

.service-details .section-title {
    font-weight: 600;
    color: var(--color-text-primary);
}

.service-details .json-display {
    margin: 0;
    padding: 8px;
    background-color: var(--color-background);
    border-radius: 4px;
    font-family: monospace;
    font-size: 13px;
    white-space: pre-wrap;
    word-break: break-all;
    color: var(--color-text-primary);
}

.monaco-container {
    position: relative;
    margin-bottom: 1rem;
    width: 100%;
}

.monaco-container.invalid {
    border: 1px solid var(--color-error);
    border-radius: 4px;
}

.monaco-container .label {
    font-size: 14px;
    font-weight: 500;
    color: var(--color-text-primary);
    margin-bottom: 0.5rem;
}

.monaco-container .monaco-editor-container {
    border: 1px solid var(--color-border);
    border-radius: 4px;
    width: 100% !important;
}

.editor-input-largest.monaco-container {
    max-width: none;
    width: 100%;
}


@import '~@/assets/scss/palette';
@import '../../../assets/scss/outlines';
@import '../../../assets/scss/animations';

.section-title {
    background-color: $white;
    font-size: 0.75rem;
    font-weight: bold;
    line-height: 2.17;
    color: $gray-blue;
    padding: 1rem 1rem 0.5rem 0;
    justify-content: space-between;
    text-transform: uppercase;
    &.section-title-capitalized {
        text-transform: capitalize;
        font-size: 0.875rem;
    }
    &.section-title-basic {
        margin: 0;
        border-bottom: solid 1px #f1f1f1;
    }
}

.arrow {
    transition: $fast-animation-time ease-in-out;

    &.collapsed {
        transform: rotate(180deg);
    }
}

.section-properties {
    min-height: 1.25rem;
}

.section-property {
    margin: 0 1.5rem 0 0;
    padding: 3px 0;
    font-size: 0.75rem;
    font-weight: 600;
    line-height: 1.67;
    color: $gray90;

    &.section-property-basic {
        align-items: top;
        margin: 0 8px;

        .property-value-name {
            line-height: 2;
        }
    }
    &.section-property-big-gap {
        margin-top: 2rem;
        &:first-of-type {
            margin-top: 1.5rem;
        }
    }
    &.section-property-error {
        color: $red;
    }

    .value {
        font-size: 0.875rem;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.43;
        color: $gray90;
        word-break: break-all;

        &.link {
            cursor: pointer;
        }
        &.disabled {
            cursor: default;
            a {
                color: $gray30;
                text-decoration: none;
                cursor: default;
            }
        }
        &:not(.disabled) {
            a {
                color: $blue;

                &:hover {
                    color: $blue;
                    text-decoration: underline;
                }
            }
        }
        &.error {
            color: $red;
        }
    }
}

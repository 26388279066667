
@import '~@/assets/scss/_colors';
@import '~@/assets/scss/_palette';
@import '~@/assets/scss/_editor-layout-v2';

.text-gray {
    color: $gray30;
    font-weight: 400;
}

.editor {
    background-color: $white;
    display: flex;
    flex-flow: column;
    height: 100%;
}

.editor-container {
    padding: 2.25rem 1.5rem 0 1.5rem;
}

.section-title {
    font-size: 1.125rem;
    color: $gray90;
    font-weight: 600;
    padding-bottom: 1.25rem;
}

.sub-section-desc {
    font-size: 0.75rem;
    line-height: 1.67;
    color: $gray60;
}

.cohort-expression {
    padding: 0.625rem;
    border: 0.0625rem solid $gray5;
    border-radius: 0.5rem;
    max-width: 45rem;
}

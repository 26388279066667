
@import '~@/assets/scss/consistency-spacing';
@import '~@/assets/scss/consistency-typography';
@import '~@/assets/scss/palette';
@import '~@/assets/scss/z-indexes';
@import '~@/assets/scss/editor-layout-v2';

.section-message {
    font-size: 0.75rem;
    margin-bottom: 0.75rem;
    color: $gray60;
}

.service-type-cards {
    padding: 1rem;
}

/* Solid border */
hr.solid {
    border-top: solid 1px $blue15;
    margin-top: 0.75rem;
}

.inputfile {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: $hide-smth-z-index;
}

.upload-button {
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 1.86;
    color: $blue;
    cursor: pointer;
}

.inline-text {
    font-size: 0.75rem;
    text-transform: uppercase;
    font-weight: bold;
    color: $gray-blue;
    margin-right: 0.75rem;
}

.inline-text-right {
    font-size: 0.75rem;
    text-transform: uppercase;
    font-weight: bold;
    color: $gray-blue;
    margin-right: 0.75rem;
    margin-left: 0.75rem;
}

.explanation {
    line-height: 1.25rem;
    color: $gray60;
    font-size: 0.75rem;
    font-weight: 500;
}

.icon-card {
    background: $blue15;
    height: 2.5rem;
    width: 2.5rem;
    margin-bottom: 0.75rem;
    border-radius: 50%;
}

.editor-link {
    text-decoration: underline;
    cursor: pointer;
}

.tooltip-text {
    color: $gray90;
    min-width: 12.5rem;
    opacity: 1;
}

.sub-section-desc {
    font-size: 0.75rem;
    line-height: 1.67;
    color: $gray60;
}

.sub-section-title {
    font-size: 0.75rem;
    font-weight: 600;
    color: $gray90;
    margin-bottom: 0.3125rem;
}

.namespace-button {
    margin-left: 1.5rem;
}

.voucher-code-wrapper {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
}


@import '~@/assets/scss/consistency-spacing';
@import '~@/assets/scss/consistency-typography';

.validation-method {
    gap: $spacing-xs;
}

button {
    background: none;
    border: 0;
    text-decoration: none !important;

    &[disabled] {
        pointer-events: none;
        color: $gray-300;
    }
}

@import "palette";
// TODO: This section is commented out until we move all of the code from using weights to font families.
// Essentially Open Sans with weight 600 would be replaced with Open Sans Bold font.
// This will result in better font rendering.

//@font-face {
//    font-family: 'Open Sans';
//    src: url('~@/assets/fonts/Open_Sans/OpenSans-Regular.ttf') format('truetype');
//}
//
//@font-face {
//    font-family: 'Open Sans Bold';
//    src: url('~@/assets/fonts/Open_Sans/OpenSans-SemiBold.ttf') format('truetype');
//}
//
//@font-face {
//    font-family: 'Open Sans Light';
//    src: url('~@/assets/fonts/Open_Sans/OpenSans-Light.ttf') format('truetype');
//}
//
//@font-face {
//    font-family: 'Open Sans Italic';
//    src: url('~@/assets/fonts/Open_Sans/OpenSans-Italic.ttf') format('truetype');
//}


@font-face {
    font-family: 'Fira Mono';
    src: url('~@/assets/fonts/Fira_Mono/FiraMono-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Fira Mono Medium';
    src: url('~@/assets/fonts/Fira_Mono/FiraMono-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Fira Mono Bold';
    src: url('~@/assets/fonts/Fira_Mono/FiraMono-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

$font-family-base: 'Open Sans', sans-serif;
$monospace-font: 'Fira Mono', monospace;
$monospace-font-bold: 'Fira Mono Medium', monospace;

$id-field-font: $monospace-font;


// Classes related to typography

pre {
    font-family: $font-family-base;
}

.id-field {
    font-family: $id-field-font;
    font-size: 14px;
}

.small-gray-note {
    font-size: 0.75rem;
    margin-bottom: 0.75rem;
    color: $gray60;
    font-weight: 400;
}

.gray-note {
    font-size: 0.875rem;
    color: $gray60;
    font-weight: 400;
}

.small-red-note {
    font-size: 0.75rem;
    color: $red;
    font-weight: 400;
    line-height: 1.625;
}

.subtitle {
    font-size: 1.125rem;
    font-weight: 600;
    color: $gray90;
}

.table-title {
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 1.5;
    color: $gray90;
}

.primary-text {
    font-size: 0.875rem;
    line-height: 1.5;
    color: $gray90;
}

.all-caps-text {
    font-size: 0.75rem;
    text-transform: uppercase;
    font-weight: bold;
    color: $gray-blue;
    margin-right: 0.75rem;
}

.blue-label {
    color: $blue60;
    background-color: $blue5;
    padding: 0.125rem 0.875rem;
    border-radius: 0.25rem;
}

.small-gray-date-label {
    font-size: 0.75rem;
    line-height: 1.25rem;
    color: $gray60;
}

.component-title-um {
    font-size: 1rem;
    font-weight: 600;
    color: $gray90;
}

.modal-title {
    font-size: 1.375rem;
    font-weight: 600;
    color: $gray90;
}

.section-toggle-label {
    font-size: 0.75rem;
    line-height: 2.17;
    font-weight: 600;
}

.form-subtitle {
    font-size: .75rem;
    font-weight: 600;
    line-height: 1.625rem;
}

.sidebar-text {
    line-height: 1.86;
    font-size: 0.875rem;
    font-weight: normal;
}


@import '~@/assets/scss/palette';
@import '~@/assets/scss/mixins';
@import '~@/assets/scss/z-indexes';

.inputfile {
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: $hide-smth-z-index;
}

.upload-container {
    border-radius: 0.5rem;
    border: dashed 0.0625rem $gray5;
    padding: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;

    &.upload-error {
        opacity: 0.5;
        pointer-events: none;
    }

    &.drag-on {
        background: rgba(51, 81, 149, 0.05);
    }
}

.upload-img {
    margin-bottom: 0.75rem;

    &.small-icon {
        align-self: center;
        width: 7rem;
    }
}

.upload-text {
    font-size: 0.875rem;
    font-weight: 600;
    color: $gray90;
    margin-bottom: 0.25rem;
}

.upload-link {
    color: $blue;
    z-index: $overlap-smth-z-index;
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }
}

.file-list {
    .preview-image-small {
        object-fit: cover;
        border-radius: 0.25rem;
        width: 4.75rem;
        height: 3.625rem;
    }

    .preview-image-big {
        display: none;
    }

    .preview-image-wrap {
        position: relative;
        margin-right: 1.5rem;

        &:hover .preview-image-big {
            display: block;
            max-width: 50vw;
            max-height: 50vh;
            border-radius: 0.5rem;
            z-index: $overlap-smth-z-index;
            position: absolute;
            bottom: 0;
            right: -0.25rem;
            transform: translateX(100%);
        }
    }
}

.file-info {
    font-size: 0.75rem;
    width: 100%;
}

.file-name {
    font-weight: 600;
    line-height: 2.17;
    margin-bottom: 0.25rem;
}

.file-status {
    margin-bottom: 0.25rem;
}

.file-loading {
    height: 0.5rem;
    width: 100%;
    margin-right: 1.5rem;
    position: relative;

    &-green,
    &-gray {
        height: 100%;
        border-radius: 0.5rem;
        position: absolute;
        top: 0;
        left: 0;
    }

    &-green {
        background: $green;
        z-index: $overlap-smth-z-index;
    }

    &-gray {
        background: $dirty-white;
        width: 100%;
    }
}

.file-subtext {
    color: $gray30;
    line-height: 1.75;
}

.icon-close,
.icon-success {
    width: 0.75rem;
    height: 0.75rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.icon-success {
    display: none;
}

.file-icon {
    min-width: 1rem;
    width: 1rem;
    height: 1rem;
    position: relative;
    cursor: pointer;
    background: #a8aab7;
    border-radius: 50%;

    &.success {
        background: none;
        cursor: unset;

        .icon-close {
            display: none;
        }

        .icon-success {
            display: block;
        }
    }
}

.small-gray-note {
    text-align: center;
}

.files-wrap {
    display: flex;
    flex-wrap: wrap;
}

.file {
    width: 100%;
}

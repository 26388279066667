
@import '~@/assets/scss/consistency';

.tags-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.tag-margins {
    margin-right: $spacing-xs;
    margin-bottom: $spacing-xs;
}


@import '~@/assets/scss/layout';

.main {
    padding: $spacing-xl;
}
.mapping-button {
    margin-top: $spacing-m;
}

table {
    table-layout: fixed;
    margin-top: $spacing-m;
}

th,
td {
    padding: $spacing-xs;
}


@import '~@/assets/scss/consistency';

.state-history-content {
    border: 1px solid $gray-200;
    padding: $spacing-m !important;
}

.approve-reject-container {
    border-width: 1px;
    border-style: solid;
    border-color: $gray-200;
    border-image: initial;
    border-radius: 0.5rem;
}

.title {
    padding: 0.25rem 1rem;
    line-height: 40px;
    border-bottom: 1px solid $gray-200;
}

.body {
    padding: 1rem 1.5rem;
}


@import '~@/assets/scss/_colors';
@import '~@/assets/scss/_palette';
@import '~@/assets/scss/_editor-layout-v2';
@import '~@/assets/scss/_typographyV2';

.fit-content {
    min-width: fit-content;
}

.label {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 0.75rem;
    color: $gray90;
    line-height: 26px;
    font-weight: 600;
    margin-bottom: 0.5rem;

    &.disabled {
        color: $gray30;
    }
}

.inline-text {
    font-size: 0.75rem;
    text-transform: uppercase;
    font-weight: bold;
    color: $gray-blue;
    margin-right: 0.75rem;
}

.prize-winning-rules-row-container {
    margin-bottom: 0.5rem;
}

.prize-winning-rules-row-container > * {
    margin-right: 0.75rem;
}

.optionalPosition {
    position: absolute;
    left: 78%;
    top: -20px;
}

.optional-label {
    font-size: 0.75rem;
    font-style: italic;
    color: $gray60;
}

.optional-key-value-max-width {
    max-width: 36.5rem;
}

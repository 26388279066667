
@import '~@/assets/scss/base.scss';
@import '~@/assets/scss/_icons.scss';
@import '~@/assets/scss/_palette.scss';
@import '~@/assets/scss/_typographyV2.scss';

$icons-path: '~@/assets/icons/';

.pointer-none {
    pointer-events: none;
}

.pointer-all {
    pointer-events: all;
}

.cards-container {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    width: 100%;

    .card-content {
        position: relative;
        max-height: 146px;
        flex: 1 1 100%;
        overflow: hidden;
        overflow-wrap: break-word;
        background-color: $white;
        text-align: center;
        color: $blue;
        // in order to use rgba color on the border
        background-clip: padding-box;
        border: solid 1px $blue15;
        border-radius: 8px;
        margin-right: 12px;
        margin-bottom: 12px;
        font-size: 16px;
        font-weight: 600;
        cursor: pointer;

        .icon-card {
            background: $blue15;
            height: 40px;
            width: 40px;
            margin-bottom: 12px;
            border-radius: 50%;
        }

        .header {
            padding: 2px;
            font-size: 10px;
            font-weight: bold;
            text-transform: uppercase;
            color: $steel;
            background-color: $blue15;
        }

        &.disabled {
            cursor: not-allowed;
            color: $blue;

            // CREATING OPACITY WITH AFTER BLOCK FOR DISABLE INHERITANCE OPACITY
            // FOR TOOLTIP CONTENT IN CARD BLOCK
            &::after {
                content: '';
                display: block;
                width: 100%;
                height: 100%;
                background: $white;
                opacity: 0.5;
                position: absolute;
                top: 0;
                left: 0;
            }
        }

        &.flex-shrink-grouped-cards {
            flex: 1 0 50%;
        }

        &.selected {
            background-color: $blue15;

            .header {
                background-color: transparent;
            }

            .icon-card {
                background: $blue;
                fill: $white;
                border-radius: 50%;

                g {
                    fill: $blue;

                    #icon {
                        fill: $white;
                    }

                    #icon-stroke {
                        stroke: $white;
                    }
                }
            }
        }

        &.small {
            max-height: 80px;
            min-height: 40px;
        }

        &.error-border {
            border-color: $red;
        }

        .group-cards {
            min-height: 126px;
            max-height: 146px;
            flex: 2;
            overflow: hidden;
            overflow-wrap: break-word;
            text-align: center;
            color: $blue;
            margin-bottom: 12px;
            font-size: 16px;
            font-weight: 600;
            cursor: pointer;

            &.selected {
                background-color: $blue15;
            }

            &.small {
                max-height: 80px;
                min-height: 60px;
            }

            &:last-child {
                border-left: solid 1px $blue15;
            }

            &.disabled {
                cursor: not-allowed;
                color: $blue;

                &::after {
                    content: '';
                    display: block;
                    width: 100%;
                    height: 100%;
                    background: $white;
                    opacity: 0.5;
                    position: absolute;
                    top: 0;
                    left: 0;
                }
            }
        }

        &:last-child {
            margin-right: 0;
        }
    }
}

// Adding negative margin to compensate for bottom margin from CardComponent
.lf-error-message {
    margin-top: -4px;
}
